import { EntityName } from '@store/backend-entity';

import { DefaultAction, Models, UserRole } from '../../enums';

const Actions = {
  ...DefaultAction,
};

export const catalogRecordPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof Actions>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    { actions: [Actions.read, Actions.create, Actions.update], subject: Models.CatalogRecord },
    { actions: [Actions.read], subject: Models.CatalogRecords },
  ],

  [UserRole.Operator]: [
    { actions: [Actions.read, Actions.create, Actions.update], subject: Models.CatalogRecord },
    { actions: [Actions.read], subject: Models.CatalogRecords },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    { actions: [Actions.read, Actions.create, Actions.update], subject: Models.CatalogRecord },
    { actions: [Actions.read], subject: Models.CatalogRecords },
  ],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [
    { actions: [Actions.read, Actions.create, Actions.update], subject: Models.CatalogRecord },
    { actions: [Actions.read], subject: Models.CatalogRecords },
  ],
};
