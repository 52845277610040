import { APP_INITIALIZER, NgModule } from '@angular/core';

import { NavigationHistoryService } from './navigation-history.service';

@NgModule({
  providers: [
    NavigationHistoryService,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [NavigationHistoryService],
      multi: true,
    },
  ],
})
export class NavigationModule {}
