import { SimEntity } from '../sims';
import { VehicleEntity } from '../vehicles';

export enum EDeviceModel {
  Misos = 'MISOS',
}

export enum DeviceType {
  Pro = 'PRO',
  Lite = 'LITE',
}

export enum DeviceStatus {
  Active = 'active',
  Deactivated = 'deactivated',
}

export enum DeviceProcess {
  Activation = 'activation',
  Deactivation = 'deactivation',
}

export type DeviceEntity = {
  /** Идентификатор */
  readonly deviceId: string;
  /** Идентификатор (VIN) авто */
  vehicleVin: string | null;
  /** Серийный номер блока */
  serial: string | null;
  /** Тип блока */
  type: DeviceType;
  /** Статус активации */
  status: DeviceStatus;
  /** Процесс активации */
  process: DeviceProcess | null;
  /** Дата изменения процесса */
  processUpdatedAt: Date | null;
  /** Модель блока */
  model: EDeviceModel;
  /** Признак апгрейда   */
  upgrade: boolean;
  /** Версия прошивки */
  firmware: number | null;
  /** Длительность автозапуска, м */
  autostartDuration: number | null;
  /** Возможность управлением климата */
  climateControl: boolean;
  /** imei */
  imei: string;
  /** Идентификатор основной sim */
  mainSimId: string | null;
  /** Идентификатор первой sim */
  simId1: string | null;
  /** Идентификатор второй sim */
  simId2: string | null;
  /** Дата первой активации */
  firstActivation: Date | null;
  /** Дата последней активации */
  lastActivation: Date | null;
  /** Дата деактивации */
  lastDeactivation: Date | null;
  /** Счетчик активаций */
  deactivationCount: number;
  /** Счетчик деактиваций */
  activationCount: number;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;

  /** Первая sim */
  sim1?: SimEntity;
  /** Вторая sim */
  sim2?: SimEntity;
  /** Сущность авто */
  vehicle?: VehicleEntity;
};

export type DeviceEntityRelationKey = keyof Pick<DeviceEntity, 'sim1' | 'sim2' | 'vehicle'>;
export type DeviceEntityRelations = DeviceEntityRelationKey[] | 'all';
