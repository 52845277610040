import { DefaultAction } from './default-action.enum';

export enum UniqueFacilityActions {
  activate = 'activate',
  deactivate = 'deactivate',
}

export const FacilityAction = {
  ...DefaultAction,
  ...UniqueFacilityActions,
};
