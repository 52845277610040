import { EntityName } from '@store/backend-entity';

import { DefaultAction, Models, UserRole } from '../../enums';

const Actions = {
  ...DefaultAction,
};

export const eventsHistoryPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof Actions>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    {
      actions: [Actions.read],
      subject: Models.EventsHistory,
      fields: Object.values(EntityName),
    },
  ],

  [UserRole.Operator]: [
    {
      actions: [Actions.read],
      subject: Models.EventsHistory,
      fields: Object.values(EntityName).filter((entityName) => entityName !== EntityName.User),
    },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    {
      actions: [Actions.read],
      subject: Models.EventsHistory,
      fields: Object.values(EntityName).filter((entityName) => entityName !== EntityName.User),
    },
  ],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [
    {
      actions: [Actions.read],
      subject: Models.EventsHistory,
      fields: Object.values(EntityName).filter((entityName) => entityName !== EntityName.User),
    },
  ],
};
