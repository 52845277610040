import { EntityName } from '@store/backend-entity';

import { Models, UserRole } from '../enums';
import {
  adminPermissions,
  deviceOperatorPermissions,
  operatorPermissions,
  operatorWithoutPBAccessPermissions,
  readOperatorPermissions,
} from './role-permissions';

import { Actions } from './actions.config';

export const caslPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof Actions>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: adminPermissions,
  [UserRole.Operator]: operatorPermissions,
  [UserRole.OperatorWithoutPBAccess]: operatorWithoutPBAccessPermissions,
  [UserRole.DeviceOperator]: deviceOperatorPermissions,
  [UserRole.OperatorReadAccess]: readOperatorPermissions,
};
