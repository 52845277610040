import { InstallDeviceTicketStatus } from '../tickets';

export enum DealerTicketInstallationDeviceType {
  MisosLite = 'Misos Lite',
  MisosPro = 'Misos Pro',
  MisosUpgrade = 'Misos Upgrade',
}

export type TicketInstallationEntity = {
  ticketId: string;
  /** Марка */
  brand: string;
  /** Модель */
  model: string;
  /** VIN код авто */
  vin: string;
  /** Год выпуска */
  yearOfProduction: number;
  /** Тип устройства */
  deviceType: DealerTicketInstallationDeviceType;
  /** Серийный номер устройства */
  deviceSerialNumber: string;
  /** Номер обратной связи */
  feedbackNumber: string;
  /** Комментарий оператора */
  comment?: string;
  /** Комментарий дилера */
  dealerComment: string;
  /** Телефон пользователя */
  phone?: string | null;
  /** фамилия пользователя */
  surname?: string | null;
  /** имя пользователя */
  firstName?: string | null;
  /** отчество пользователя */
  patronymic?: string | null;
  /** Название диллера */
  dealerName: string;
  /** Пробег, км */
  mileage: number;
  images: string[];
  /** SAP код дилера */
  sapCode: string;
  status: InstallDeviceTicketStatus;
  updatedAt: Date;
  createdAt: Date;
  partnerId: string | null;
};
