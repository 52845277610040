import { DefaultAction } from './default-action.enum';

export enum UniqueFineActions {
  synchronizeFinePhotos = 'synchronizeFinePhotos',
  synchronizeCarFines = 'synchronizeCarFines',
}

export const FineAction = {
  ...DefaultAction,
  ...UniqueFineActions,
};
