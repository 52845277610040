export interface SendFirmwareToFtpInterface {
  /** Фильт по imei и simId */
  fileInfo: string;
  /** id устройства*/
  deviceId: string;
  /** Данные по прошивке */
  c5: string;
}

export interface SendFirmwareToFtpResponse {
  status: 'success' | 'error';
  message?: string;
  firmwareName?: string;
  folder?: string;
}
