import { RumkCardActivatedOutboxEntity } from './rumk-card-activated-outbox.entity';
import { RumkCardCreatedOutboxEntity } from './rumk-card-created-outbox.entity';
import { RumkCardUpdatedOutboxEntity } from './rumk-card-updated-outbox.entity';

export type RumkCardOutboxEntities =
  | RumkCardCreatedOutboxEntity
  | RumkCardUpdatedOutboxEntity
  | RumkCardActivatedOutboxEntity;

export * from './rumk-card-updated-outbox.entity';
export * from './rumk-card-created-outbox.entity';
export * from './rumk-card-activated-outbox.entity';
export * from './rumk-card-outbox-event-type.enum';
