import { ServiceName } from './service-name.enum';

export enum EntityName {
  User = 'User',
  Card = 'Card',
  Merchant = 'Merchant',
  Order = 'Order',
  Customer = 'Customer',
  ExternalCustomer = 'ExternalCustomer',
  Device = 'Device',
  Ownership = 'Ownership',
  Partner = 'Partner',
  Sim = 'Sim',
  Invoice = 'Invoice',
  Offer = 'Offer',
  Subscription = 'Subscription',
  SubscriptionPayment = 'SubscriptionPayment',
  Vehicle = 'Vehicle',
  Tariff = 'Tariff',
  Facility = 'Facility',
  FacilityType = 'FacilityType',
  Ticket = 'Ticket',
  CatalogRecord = 'CatalogRecord',
  Brand = 'Brand',
  Model = 'Model',
  Generation = 'Generation',
  GenerationColor = 'GenerationColor',
  Provider = 'Provider',
  ExternalCustomerDevice = 'ExternalCustomerDevice',
  RumkOrder = 'RumkOrder',
  RumkCard = 'RumkCard',
}

export const ENTITY_NAME_TO_SERVICE_NAME: Record<EntityName, ServiceName> = {
  [EntityName.User]: ServiceName.Users,
  [EntityName.Card]: ServiceName.Payment,
  [EntityName.Merchant]: ServiceName.Payment,
  [EntityName.Order]: ServiceName.Payment,
  [EntityName.Customer]: ServiceName.ControlCenter,
  [EntityName.ExternalCustomer]: ServiceName.ControlCenter,
  [EntityName.Device]: ServiceName.ControlCenter,
  [EntityName.Ownership]: ServiceName.ControlCenter,
  [EntityName.Partner]: ServiceName.ControlCenter,
  [EntityName.Sim]: ServiceName.ControlCenter,
  [EntityName.Invoice]: ServiceName.ControlCenter,
  [EntityName.Offer]: ServiceName.ControlCenter,
  [EntityName.Subscription]: ServiceName.ControlCenter,
  [EntityName.SubscriptionPayment]: ServiceName.ControlCenter,
  [EntityName.Vehicle]: ServiceName.ControlCenter,
  [EntityName.Tariff]: ServiceName.ControlCenter,
  [EntityName.Facility]: ServiceName.Facilities,
  [EntityName.FacilityType]: ServiceName.Facilities,
  [EntityName.Ticket]: ServiceName.Tickets,
  [EntityName.CatalogRecord]: ServiceName.Catalog,
  [EntityName.Brand]: ServiceName.Catalog,
  [EntityName.Model]: ServiceName.Catalog,
  [EntityName.Generation]: ServiceName.Catalog,
  [EntityName.GenerationColor]: ServiceName.Catalog,
  [EntityName.Provider]: ServiceName.Facilities,
  [EntityName.ExternalCustomerDevice]: ServiceName.ControlCenter,
  [EntityName.RumkOrder]: ServiceName.Rumk,
  [EntityName.RumkCard]: ServiceName.Rumk,
};
