import { StoryPageType } from './story-page.entity';

export interface ActionStory {
  type: StoryPageType;
}

export interface VoteAction extends ActionStory {
  type: StoryPageType.Vote;
  like: boolean;
}

export interface ButtonClickAction extends ActionStory {
  type: StoryPageType.CommonWithButton;
  click: boolean;
}

export interface QuizAction extends ActionStory {
  type: StoryPageType.Quiz;
  variants: number[];
}

export type ActionStoryPage = QuizAction | ButtonClickAction | VoteAction;

export type StoryViewEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Идентификатор истории */
  readonly storyPageId: string;
  /** Идентификатор клиента */
  readonly customerId: string;
  /** Просмотрена ли история */
  viewed: boolean;
  /** Статистика действий */
  action: ActionStoryPage | null;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
};

export type StoryViewEntityRelations = [] | 'all';
