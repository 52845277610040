import { BatchResponse, Notification } from 'firebase-admin/lib/messaging/messaging-api';

import { NotificationCode } from './notification.entity';

export enum FirebaseErrorCode {
  MessagingRegistrationTokenNotRegistered = 'messaging/registration-token-not-registered',
}

export enum PushLogErrorCode {
  Token = 'token',
  Unknown = 'unknown',
}

export interface PushLogEntity {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly notificationId: string;

  /** Токены, для которых были отправлены уведомления */
  registrationTokens: string[];

  /** ИД клиента */
  clientId: string;

  /** Содержимое уведомления */
  payload: Notification;

  /** Результаты отправки уведомлений */
  result: BatchResponse;

  /** Код отправки пуша */
  code: NotificationCode;

  /** Дополнительная информация */
  additionalData?: Record<string, string>;

  /** Признак доставки уведомления */
  delivered: boolean;

  deviceId: string;
}
