export enum EDeviceTestTransmission {
  Automatic = 'Automatic',
  Manual = 'Manual',
}

export enum EDeviceTestVehicleBrand {
  Solaris = 'Solaris',
}

export enum EDeviceTestVehicleModel {
  HS = 'HS',
  HC = 'HC',
  KRS = 'KRS',
  KRX = 'KRX',
}

export enum EDeviceTestIgnitionMethod {
  Key = 'Key',
  Button = 'Button',
}

export type DeviceTestEntity = {
  /** Идентификатор */
  deviceId: string | null;

  /** Идентификатор (VIN) авто */
  readonly vehicleVin: string;

  /** Серийный номер блока */
  serial: string | null;

  /** Протестирован ли блок */
  tested: boolean;

  /** Дата проверки */
  testedAt: Date | null;

  /** Авто создано */
  vehicleCreated: boolean;

  /** Дата создания авто */
  vehicleCreatedAt: Date | null;

  /** Код комплектации */
  equipmentCode: string | null;

  /** Способ зажигания */
  ignitionMethod: EDeviceTestIgnitionMethod | null;

  /** Установлена ли настройка комплектации */
  settingsSet: boolean;

  /** Дата создания */
  readonly createdAt: Date;

  /** Дата обновления */
  updatedAt: Date;

  /** Модель */
  readonly model: EDeviceTestVehicleModel | null;

  /** Марка */
  readonly brand: EDeviceTestVehicleBrand | null;

  /** КПП */
  readonly transmission: EDeviceTestTransmission | null;
};
