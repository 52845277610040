import { ColorEntity } from './color.entity';
import { GenerationEntity } from './generation.entity';

import { ExternalIds } from './interfaces';

export type ConfigurationEntity = {
  /** Идентификатор конфигурации */
  readonly id: string;
  /** Идентификатор поколения */
  readonly generationId: string;
  /** Внешние идентификаторы источников */
  externalIds: ExternalIds;
  /** Идентификатор цвета */
  colorId: string | null;
  /** Количество дверей */
  doorsCount: number | null;
  /** Тип кузова */
  bodyType: string | null;
  /** Примечание к конфигурации */
  note: string | null;
  readonly createdAt: Date;
  updatedAt: Date;
  generation?: GenerationEntity;
  color?: ColorEntity;
};

export type ConfigurationEntityRelationKey = keyof Pick<ConfigurationEntity, 'generation' | 'color'>;
export type ConfigurationEntityRelations = ConfigurationEntityRelationKey[] | 'all';
