export class ConfigEntity {
  id!: number;

  configGroup!: string;

  configType!: string;

  configIdent!: string;

  configValue!: Record<string, unknown>;
}
