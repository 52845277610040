import { I18n } from '@store/backend-common';

export enum ECommandHistoryStatus {
  /** Отправлена в сервис взаимодействия с устройствами */
  Queued = 'queued',
  /** Команда отправлена на устройство */
  Sent = 'sent',
  /** Команда принята к выполнению, только для команд управления */
  Accepted = 'accepted',
  /** Команда выполнена */
  Executed = 'executed',
  /** Команда не могла быть выполнена, только для команд управления */
  Error = 'error',
  /** Таймаут отправки (устройство не было подключено) */
  SendTimeout = 'send_timeout',
  /** Таймаут ответа, команда была отправлена, но ответа не последовало */
  ResponseTimeout = 'response_timeout',
  /**
   * Таймаут выполнена, команда была принята, но ответа с результатом выполнения не последовало,
   * только для команд управления
   */
  ExecutionTimeout = 'execution_timeout',
}

export interface CommandLogsInfo {
  total: number;
  rows: CommandLog[];
  page: number;
  onPage: number;
}

export interface CommandLog {
  id: string;
  deviceId: string;
  status: ECommandHistoryStatus;
  commandType: string;
  rawCommand: string | null;
  rawResponse: string | null;
  sentAt: Date | null;
  responseAt: Date | null;
  service: string | null;
  actorId: string | null;
  byUser: boolean;
  createdAt: Date;
  updatedAt: Date;
  retriesLeft: number;
  error: string | null;
  /** Локализация Ошибки */
  errorLocalization: I18n | null;
  /** Локализация успеха */
  successLocalization?: I18n | null;
  /** Локализация статуса */
  statusLocalization?: I18n | null;
}
