export enum RumkService {
  MechanicConsultation = 'MechanicConsultation',
  LawyerConsultation = 'LawyerConsultation',
  Evacuation = 'Evacuation',
  StartEngine = 'StartEngine',
  ReplaceDamagedWheels = 'ReplaceDamagedWheels',
  DeliveryFuel = 'DeliveryFuel',
  OpeningDoors = 'OpeningDoors',
}

export type OrderRumkEntity = {
  readonly id: string;
  readonly cardId: string;
  readonly vin: string;
  lat: number | null;
  lon: number | null;
  address: string | null;
  phone: string;
  clientName: string | null;
  carName: string | null;
  carNumber: string | null;
  service: RumkService;
  facilityOrderId: string | null;
  paidAt: Date | null;
  isRated: boolean;
  isDone: boolean;
  readonly createdAt: Date;
  updatedAt: Date;
};
