import { EntityName } from '@store/backend-entity';

import { DeviceTestAction, Models, UserRole } from '../../enums';

export const deviceTestPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof DeviceTestAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    {
      actions: [DeviceTestAction.read],
      subject: Models.DeviceTests,
    },
  ],

  [UserRole.Operator]: [
    {
      actions: [DeviceTestAction.read],
      subject: Models.DeviceTests,
    },
  ],
  [UserRole.OperatorWithoutPBAccess]: [
    {
      actions: [DeviceTestAction.read],
      subject: Models.DeviceTests,
    },
  ],

  [UserRole.DeviceOperator]: [
    {
      actions: [DeviceTestAction.read],
      subject: Models.DeviceTests,
    },
  ],

  [UserRole.OperatorReadAccess]: [],
};
