export enum UploadHistoryAction {
  UploadFile = 'UploadFile',
}

export enum UploadHistoryStatus {
  Success = 'Success',
  Failed = 'Failed',
}

export type UploadHistoryEntity = {
  readonly id: string;
  originalFile: string;
  action: UploadHistoryAction;
  status: UploadHistoryStatus;
  userId: string;
  readonly createdAt: Date;
  updatedAt: Date;
};
