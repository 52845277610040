import { CardType } from '../../payment';
import { CityEntity } from './city.entity';
//eslint-disable-next-line import/no-cycle
import { ExternalCustomerEntity } from './external-customer.entity';

export enum CustomerStatus {
  Created = 'Created',
  Active = 'Active',
  Blocked = 'Blocked',
  Deleted = 'Deleted',
}

export type CustomerCard = {
  id: string;
  cardMask: string | null;
  expireDate: Date | null;
  isMain: boolean;
  type: CardType;
};

export type CustomerEntity = {
  readonly id: string;
  phone: string | null;
  firstName: string | null;
  lastName: string | null;
  patronymic: string | null;
  email: string | null;
  externalCustomers: ExternalCustomerEntity[];
  cityId?: string | null;
  city?: CityEntity;
  status: CustomerStatus;
  cards: CustomerCard[];
  avatar: string | null;
  readonly createdAt: Date;
  updatedAt: Date;
};

export type CustomerEntityRelationKey = keyof Pick<CustomerEntity, 'externalCustomers' | 'city'>;
export type CustomerEntityRelations = CustomerEntityRelationKey[] | 'all';
