import { EDeviceEvent } from '@store/amqp-contracts';

import { DeviceEntity, VehicleEntity } from '../../../services';
import { BaseOutboxEntity } from '../../base-outbox.entity';
import { ControlCenterVehicleOutboxEventType } from './control-center-vehicle-outbox-event-type.enum';

export type ControlCenterVehicleDeviceEventProcessedOutboxEntity = BaseOutboxEntity<
  ControlCenterVehicleOutboxEventType.VehicleDeviceEventProcessed,
  Pick<VehicleEntity, 'vin'> &
    Pick<DeviceEntity, 'deviceId'> & {
      type: EDeviceEvent;
      createdAt: Date;
      lat: number;
      lon: number;
    }
>;
