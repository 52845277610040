/* eslint-disable @typescript-eslint/no-redeclare */
import { Injectable } from '@angular/core';
import { AuthApiService } from '@app/core/api/auth/auth-api.service';
import { ProfileModel } from '@app/core/graphql-global-types';
import { AbilityBuilder, createMongoAbility, MongoAbility } from '@casl/ability';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { UserRole } from '@store/backend-entity';
import { caslPermissions } from '@store/casl';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { AuthProfileStateActions } from './auth-profile.state.actions';
import { AuthTokenState, AuthTokenStateModel } from './auth-token.state';

export type AppAbility = MongoAbility; // TODO: типизировать когда-нибудь
export const AppAbility = createMongoAbility<AppAbility>();

interface AuthProfileStateModel {
  profile: ProfileModel | null;
}

const EMPTY_STATE: AuthProfileStateModel = {
  profile: null,
};

@State<AuthProfileStateModel>({
  name: 'profile',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class AuthProfileState {
  constructor(
    private readonly authApiService: AuthApiService,
    private readonly store: Store,
    private ability: AppAbility,
  ) {}

  @Selector()
  public static profile(state: AuthProfileStateModel): ProfileModel | null {
    return state.profile;
  }

  @Selector([AuthProfileState.profile])
  public static userEmail(_: AuthProfileStateModel, profile: ProfileModel | null): string | null {
    return profile?.email || null;
  }

  @Action(AuthProfileStateActions.Load, { cancelUncompleted: true })
  public loadProfile({ patchState }: StateContext<AuthProfileStateModel>): Observable<ProfileModel | null> {
    const tokenState = this.store.selectSnapshot<AuthTokenStateModel>(AuthTokenState);

    const operation$: Observable<ProfileModel | null> = tokenState.accessToken
      ? this.authApiService.me().pipe(
          map((response) => response.data.me),
          catchError(() => of(null)),
        )
      : of(null);

    return operation$.pipe(tap((profile) => patchState({ profile })));
  }

  @Action(AuthProfileStateActions.InitPermissions)
  public initPermissions({ getState }: StateContext<AuthProfileStateModel>): void {
    const state = getState();

    const role = state.profile?.role;

    if (role) {
      const builder = new AbilityBuilder(createMongoAbility);
      const beRole = UserRole;
      const permissions = caslPermissions[beRole[role]];

      if (permissions) {
        permissions.forEach((permission) => {
          builder.can(permission.actions, permission.subject, permission.fields);
        });
      }

      const res = builder.build();
      this.ability.update(res.rules);
    }
  }
}
