export interface CheckDeviceForUpdateResponse {
  status: 'success' | 'error';
  message?: string;
  c5?: string;
  firmwareResult?: {
    status: 'success' | 'error';
    message?: string;
    response?: {
      cnt: number;
      ducats: string;
      items: DeviceFirmwareInterface[];
    };
  };
  deviceId?: string;
  currentStatus?: CurrentStatusInterface;
}
export interface CurrentStatusInterface {
  id: number;
  deviceId: string;
  dateOperation: number;
  status: UpdateDeviceStatus;
  description: string;
  c5: string;
  firmware: DeviceFirmwareInterface;
}

export interface DeviceFirmwareInterface {
  hdw: string;
  opt: string;
  date: string;
  file: string;
  soft: string;
  price: string;
  version: string;
  fileInfo: string;
  description: string;
  thisUserOnly: number;
}

export type UpdateDeviceStatus = 'prepared' | 'updating' | 'success' | 'error' | 'timeout' | 'cancel';
