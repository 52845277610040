import { TicketsTicketCreatedOutboxEntity } from './tickets-ticket-created-outbox.entity';
import { TicketsTicketStatusChangedOutboxEntity } from './tickets-ticket-status-changed-outbox.entity';
import { TicketsTicketUpdatedOutboxEntity } from './tickets-ticket-updated-outbox.entity';

export type TicketsTicketOutboxEntities =
  | TicketsTicketCreatedOutboxEntity
  | TicketsTicketStatusChangedOutboxEntity
  | TicketsTicketUpdatedOutboxEntity;

export * from './tickets-ticket-outbox-event-type.enum';
export * from './tickets-ticket-created-outbox.entity';
export * from './tickets-ticket-status-changed-outbox.entity';
export * from './tickets-ticket-updated-outbox.entity';
