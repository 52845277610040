export enum ControlCenterDeviceOutboxEventType {
  DeviceCreated = 'ControlCenterDeviceCreated',
  DeviceUpdated = 'ControlCenterDeviceUpdated',
  DeviceUpgrade = 'ControlCenterDeviceUpgrade',
  DeviceUpgradeUpdated = 'ControlCenterDeviceUpgradeUpdated',
  DeviceDeleted = 'ControlCenterDeviceDeleted',
  DeviceCommandSent = 'ControlCenterDeviceCommandSent',
  DeviceCustomCommandSent = 'ControlCenterDeviceCustomCommandSent',
  DeviceActivationStarted = 'ControlCenterDeviceActivationStarted',
  DeviceDeactivationStarted = 'ControlCenterDeviceDeactivationStarted',
  DeviceActivated = 'ControlCenterDeviceDeviceActivated',
  DeviceDeactivated = 'ControlCenterDeviceDeviceDeactivated',
  DeviceActivationFailed = 'ControlCenterDeviceDeviceActivationFailed',
  DeviceDeactivationFailed = 'ControlCenterDeviceDeviceDeactivationFailed',
  DeviceSimInstalled = 'ControlCenterDeviceSimInstalled',
  DeviceSynced = 'ControlCenterDeviceSynced',
  DeviceUpgraded = 'ControlCenterDeviceUpgraded',
}
