import { Actor } from '@store/amqp-contracts';

import { CustomerEntity, DeviceEntity, VehicleEntity } from '../control-center';
import { TicketStatus, TicketType } from './enums';
import { TicketCommentEntity } from './ticket-comment.entity';

export enum DeletePersonalDataReasonEnum {
  NoNeededFunctionality = 'NO_NEEDED_FUNCTIONALITY',
  DidNotMeetExpectations = 'DID_NOT_MEET_EXPECTATIONS',
  UnwillingToPay = 'UNWILLING_TO_PAY',
  Other = 'OTHER',
}

export type DeletePersonalDataReason = {
  reason: DeletePersonalDataReasonEnum;
  comment?: string;
};

export type DeletePersonalDataTicketPayload = {
  customerId: string;
  reason: DeletePersonalDataReason;
  email: string;
  document: string | null;
  documentDate: Date | null;
  isDocumentSent: boolean;
  isPersonalDataDeleted: boolean;
  isDocumentDelivered: boolean;
  documentOperatorId: string | null;
  type: TicketType;
  personalDataDeletionDate: Date | null;
};

export type AddVehicleTicketPayload = {
  customer: Pick<CustomerEntity, 'id' | 'firstName' | 'lastName' | 'email' | 'phone'>;
  vehicle: Pick<VehicleEntity, 'vin'> & {
    sts: string;
    license: string | null;
    model: string | null;
    brand: string | null;
    yearOfProduction: number | null;
  };
  ownershipStartDate: Date;
  images: string[];
  type: TicketType;
  reviewComment?: string;
  hasOwnership?: boolean;
};

export enum EInstallDeviceTicketType {
  Pro = 'PRO',
  Lite = 'LITE',
  Upgrade = 'UPGRADE',
}

export type InstallDeviceTicketPayload = {
  vehicle:
    | (Pick<VehicleEntity, 'vin' | 'brand' | 'model'> & {
        yearOfProduction: number;
        mileage: number;
        partnerId: string | null;
      })
    | null;
  currentDevice: Pick<DeviceEntity, 'deviceId' | 'serial' | 'model' | 'type'> | null;
  device: Pick<DeviceEntity, 'model' | 'type' | 'serial'>;
  installationType: EInstallDeviceTicketType;
  dealer: {
    sapCode: string | null;
    dealerName: string;
    dealerComment: string;
  };
  contactPhone: string;
  images: string[];
  ticketId: string;
  reviewComment?: string;
  type: TicketType;
  isInstalled?: boolean;
};

export type TicketPayload = DeletePersonalDataTicketPayload | AddVehicleTicketPayload | InstallDeviceTicketPayload;

export type TicketEntity = {
  readonly id: string;
  partnerId: string | null;
  readonly type: TicketType;
  readonly actor: Actor;
  active: boolean;
  status: TicketStatus;
  statusUpdatedDate: Date | null;
  payload: TicketPayload;
  readonly createdAt: Date;
  updatedAt: Date;

  comments?: TicketCommentEntity[];
};

export type TicketEntityRelationKey = keyof Pick<TicketEntity, 'comments'>;
export type TicketEntityRelations = TicketEntityRelationKey[] | 'all';
