import { EntityName } from '@store/backend-entity';

import { CkadAction, Models, UserRole } from '../../enums';

export const ckadPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof CkadAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    { actions: [CkadAction.synchronizeCarCkads], subject: Models.Ckad },
    { actions: [CkadAction.read], subject: Models.Ckads },
  ],

  [UserRole.Operator]: [
    { actions: [CkadAction.synchronizeCarCkads], subject: Models.Ckad },
    { actions: [CkadAction.read], subject: Models.Ckads },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    { actions: [CkadAction.synchronizeCarCkads], subject: Models.Ckad },
    { actions: [CkadAction.read], subject: Models.Ckads },
  ],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [{ actions: [CkadAction.read], subject: Models.Ckads }],
};
