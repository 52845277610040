import { FacilitiesFacilityOrderCanceledOutboxEntity } from './facilities-facility-order-canceled-outbox.entity';
import { FacilitiesFacilityOrderClosedOutboxEntity } from './facilities-facility-order-closed-outbox.entity';
import { FacilitiesFacilityOrderCompletedOutboxEntity } from './facilities-facility-order-completed-outbox.entity';
import { FacilitiesFacilityOrderCreatedOutboxEntity } from './facilities-facility-order-created-outbox.entity';
import { FacilitiesFacilityOrderPaidOutboxEntity } from './facilities-facility-order-paid-outbox.entity';
import { FacilitiesFacilityOrderUpdatedOutboxEntity } from './facilities-facility-order-updated-outbox.entity';

export type FacilitiesFacilityOrderOutboxEntities =
  | FacilitiesFacilityOrderCreatedOutboxEntity
  | FacilitiesFacilityOrderUpdatedOutboxEntity
  | FacilitiesFacilityOrderPaidOutboxEntity
  | FacilitiesFacilityOrderCompletedOutboxEntity
  | FacilitiesFacilityOrderClosedOutboxEntity
  | FacilitiesFacilityOrderCanceledOutboxEntity;

export * from './facilities-facility-order-outbox-event-type.enum';
export * from './facilities-facility-order-created-outbox.entity';
export * from './facilities-facility-order-updated-outbox.entity';
export * from './facilities-facility-order-paid-outbox.entity';
export * from './facilities-facility-order-completed-outbox.entity';
export * from './facilities-facility-order-closed-outbox.entity';
export * from './facilities-facility-order-canceled-outbox.entity';
