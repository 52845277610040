import { EntityName } from '@store/backend-entity';

import { DefaultAction, Models, UserRole } from '../../enums';

const Actions = {
  ...DefaultAction,
};

export const notificationPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof Actions>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    { actions: [Actions.read], subject: Models.Notification },
    { actions: [Actions.read], subject: Models.Notifications },
  ],

  [UserRole.Operator]: [
    { actions: [Actions.read], subject: Models.Notification },
    { actions: [Actions.read], subject: Models.Notifications },
  ],

  [UserRole.OperatorWithoutPBAccess]: [],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [
    { actions: [Actions.read], subject: Models.Notification },
    { actions: [Actions.read], subject: Models.Notifications },
  ],
};
