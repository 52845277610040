import { I18n } from '@store/backend-common';

import { GlossaryType } from './enums';

export type GlossaryEntity = {
  /** Идентификатор справочника */
  readonly id: string;
  /** Тип справочника */
  glossaryType: GlossaryType;
  /** Наименования на разных языках */
  name: I18n;
  /** Значение */
  value: string;
  readonly createdAt: Date;
  updatedAt: Date;
};
