import { CustomerEntity } from '../customers';
import { PartnerEntity } from '../partners';
import { VehicleEntity } from '../vehicles';

export type OwnershipAttributes = {
  licence?: string;
  sts?: string;
  stsPhotos?: string[];
};

export enum AccessType {
  Owner = 'OWNER',
  Driver = 'DRIVER',
}

export type OwnershipAccess = {
  customerId: string;
  type: AccessType;
  startDate: Date;
  endDate: Date | null;
  nickname?: string;
};

export enum OwnershipStatus {
  Active = 'ACTIVE',
  Finished = 'FINISHED',
}

export enum OwnershipFinishReasonEnum {
  Sale = 'SALE',
  Replacement = 'REPLACEMENT',
  DeletePersonalData = 'DELETE_PERSONAL_DATA',
  Other = 'OTHER',
}

export type OwnershipFinishReason = {
  reason: OwnershipFinishReasonEnum;
  comment?: string;
};

export type OwnershipEntity = {
  readonly id: string;
  readonly vehicleVin: string;
  readonly ownerId: string;
  readonly partnerId: string;
  status: OwnershipStatus;
  ownershipStartDate: Date;
  ownershipEndDate: Date | null;
  attributes: OwnershipAttributes;
  accesses: OwnershipAccess[];
  isAutoPaymentEnable: boolean;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  finishReason: OwnershipFinishReason | null;
  vehicle?: VehicleEntity;
  owner?: CustomerEntity;
  partner?: PartnerEntity;
};

export type OwnershipEntityRelationKey = keyof Pick<OwnershipEntity, 'vehicle' | 'owner' | 'partner'>;
export type OwnershipEntityRelations = OwnershipEntityRelationKey[] | 'all';
