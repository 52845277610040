import { EFacilityType, FacilityOrderEntity } from '../../../services';
import { BaseOutboxEntity } from '../../base-outbox.entity';
import { FacilitiesFacilityOrderOutboxEventType } from './facilities-facility-order-outbox-event-type.enum';

export type FacilitiesFacilityOrderCanceledOutboxEntity = BaseOutboxEntity<
  FacilitiesFacilityOrderOutboxEventType.FacilityOrderCanceled,
  {
    order: FacilityOrderEntity;
    type: EFacilityType;
  }
>;
