import { I18n } from '@store/backend-common';

export type MaintenanceTypeEntity = {
  id: string;
  code: string;
  active: boolean;
  name: I18n;
  allDealer: boolean;
  updatedAt: Date;
  createdAt: Date;
};
