import { FacilityOrderEntity } from './facility-order.entity';

export enum ExternalPaymentStatus {
  Created = 0, //создан
  New = 1, //новый - зарегистрирован
  InProgress = 2, //в процессе (для множественной оплаты через кубышку)
  Failed = 3, //неуспешный
  Authorized = 4, //авторизован
  Completed = 5, //завершен
  Reversed = 6, //возвращен
}

export enum FacilityOrderPaymentType {
  Advance = 'ADVANCE', // авансовый платёж (холдирование средств)
  AdvanceAccept = 'ADVANCE_ACCEPT', // списание авансового платежа
  AdvanceReverse = 'ADVANCE_REVERSE', // возврат авансового платежа
  Payment = 'PAYMENT', // оплата без холдирования средств
  Refund = 'REFUND', // возврат средств
}

export enum FacilityOrderPaymentStatus {
  New = 'NEW',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Expired = 'EXPIRED',
  Canceled = 'CANCELED',
}

export interface FacilityOrderPaymentEntity {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  externalPaymentId: string | null;
  facilityOrderId: string;
  customerId: string;
  url: string | null;
  successUrl: string | null;
  failedUrl: string | null;
  type: FacilityOrderPaymentType;
  status: FacilityOrderPaymentStatus;
  cardId: string | null;
  isAutoPayment: boolean;

  facilityOrder?: FacilityOrderEntity;
}

export type FacilityOrderPaymentEntityRelationKey = keyof Pick<FacilityOrderPaymentEntity, 'facilityOrder'>;
export type FacilityOrderPaymentEntityRelations = FacilityOrderPaymentEntityRelationKey[] | 'all';

export const EXTERNAL_PAYMENT_STATUS_MAP = new Map<ExternalPaymentStatus, FacilityOrderPaymentStatus>([
  [ExternalPaymentStatus.Created, FacilityOrderPaymentStatus.New],
  [ExternalPaymentStatus.New, FacilityOrderPaymentStatus.New],
  [ExternalPaymentStatus.InProgress, FacilityOrderPaymentStatus.New],
  [ExternalPaymentStatus.Failed, FacilityOrderPaymentStatus.Failure],
  [ExternalPaymentStatus.Authorized, FacilityOrderPaymentStatus.Success],
  [ExternalPaymentStatus.Completed, FacilityOrderPaymentStatus.Success],
  [ExternalPaymentStatus.Reversed, FacilityOrderPaymentStatus.Success],
]);
