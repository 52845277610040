import { StoryEntity } from '../../../services';
import { BaseOutboxEntity } from '../../base-outbox.entity';
import { ContentsStoryOutboxEventType } from './contents-story-outbox-event-type.enum';
import { StoryPageCreateOrUpdateInput } from './interfaces';

export type ContentsStoryCreatedOutboxEntity = BaseOutboxEntity<
  ContentsStoryOutboxEventType.StoryCreated,
  Omit<StoryEntity, 'pages'> & {
    pages: StoryPageCreateOrUpdateInput[];
  }
>;
