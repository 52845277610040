import { ControlCenterDeviceActivatedOutboxEntity } from './control-center-device-activated-outbox.entity';
import { ControlCenterDeviceActivationFailedOutboxEntity } from './control-center-device-activation-failed-outbox.entity';
import { ControlCenterDeviceActivationStartedOutboxEntity } from './control-center-device-activation-started-outbox.entity';
import { ControlCenterDeviceCommandSentOutboxEntity } from './control-center-device-command-sent-outbox.entity';
import { ControlCenterDeviceCreatedOutboxEntity } from './control-center-device-created-outbox.entity';
import { ControlCenterDeviceCustomCommandSentOutboxEntity } from './control-center-device-custom-command-sent-outbox.entity';
import { ControlCenterDeviceDeactivatedOutboxEntity } from './control-center-device-deactivated-outbox.entity';
import { ControlCenterDeviceDeactivationFailedOutboxEntity } from './control-center-device-deactivation-failed-outbox.entity';
import { ControlCenterDeviceDeactivationStartedOutboxEntity } from './control-center-device-deactivation-started-outbox.entity';
import { ControlCenterDeviceDeletedOutboxEntity } from './control-center-device-deleted-outbox.entity';
import { ControlCenterDeviceSimInstalledOutboxEntity } from './control-center-device-sim-installed-outbox.entity';
import { ControlCenterDeviceSyncedOutboxEntity } from './control-center-device-synced-outbox.entity';
import { ControlCenterDeviceUpdatedOutboxEntity } from './control-center-device-updated-outbox.entity';
import { ControlCenterDeviceUpgradeOutboxEntity } from './control-center-device-upgrade-outbox.entity';
import { ControlCenterDeviceUpgradedOutboxEntity } from './control-center-device-upgraded-outbox.entity';

export type ControlCenterDeviceOutboxEntities =
  | ControlCenterDeviceCreatedOutboxEntity
  | ControlCenterDeviceDeletedOutboxEntity
  | ControlCenterDeviceUpdatedOutboxEntity
  | ControlCenterDeviceCommandSentOutboxEntity
  | ControlCenterDeviceCustomCommandSentOutboxEntity
  | ControlCenterDeviceActivationStartedOutboxEntity
  | ControlCenterDeviceDeactivationStartedOutboxEntity
  | ControlCenterDeviceActivatedOutboxEntity
  | ControlCenterDeviceDeactivatedOutboxEntity
  | ControlCenterDeviceActivationFailedOutboxEntity
  | ControlCenterDeviceDeactivationFailedOutboxEntity
  | ControlCenterDeviceSimInstalledOutboxEntity
  | ControlCenterDeviceUpgradeOutboxEntity
  | ControlCenterDeviceSyncedOutboxEntity
  | ControlCenterDeviceUpgradedOutboxEntity;

export * from './control-center-device-outbox-event-type.enum';
export * from './control-center-device-created-outbox.entity';
export * from './control-center-device-deleted-outbox.entity';
export * from './control-center-device-updated-outbox.entity';
export * from './control-center-device-activation-started-outbox.entity';
export * from './control-center-device-deactivation-started-outbox.entity';
export * from './control-center-device-activated-outbox.entity';
export * from './control-center-device-deactivated-outbox.entity';
export * from './control-center-device-activation-failed-outbox.entity';
export * from './control-center-device-deactivation-failed-outbox.entity';
export * from './control-center-device-command-sent-outbox.entity';
export * from './control-center-device-custom-command-sent-outbox.entity';
export * from './control-center-device-synced-outbox.entity';
export * from './control-center-device-sim-installed-outbox.entity';
export * from './control-center-device-upgrade-outbox.entity';
export * from './control-center-device-upgraded-outbox.entity';
