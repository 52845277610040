import { CommonModule } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { AuthModule } from '@app/core/auth/auth.module';
import { MediaObserverModule } from '@app/core/media-observer/media-observer.module';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { ReloadControlModule } from '@app/core/reload-control/reload-control.module';
import { SidenavModule } from '@app/core/sidenav/sidenav.module';
import { SnackBarModule } from '@app/core/snack-bar/snack-bar.module';
import { SvgIconModule } from '@app/core/svg-icon/svg-icon.module';
import { provideAppVersion } from '@app/core/tokens';
import { UpdaterModule } from '@app/core/updater/updater.module';
import { ru } from 'date-fns/locale';

import packageJson from '../../../package.json';

const MAT_FORM_FIELD_OPTIONS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
    AuthModule,
    UpdaterModule,
    ReloadControlModule,
    SnackBarModule,
    SidenavModule,
    NavigationModule,
    MediaObserverModule.forRoot({
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
      xxxl: 1600,
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'RUB',
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: ru,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: MAT_FORM_FIELD_OPTIONS,
    },
    provideAppVersion(packageJson.version),
  ],
})
export class CoreModule {}
