import { CardEntity } from '../../../services';
import { BaseOutboxEntity } from '../../base-outbox.entity';
import { PaymentCardOutboxEventType } from './payment-card-outbox-event-type.enum';

export type PaymentCardOutboxSavedEntity = BaseOutboxEntity<
  PaymentCardOutboxEventType.CardSaved,
  CardEntity & {
    clientId: string;
  }
>;
