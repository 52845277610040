export interface DeviceLog {
  result?: {
    /**
     * Первый элемент массива - названия столбцов
     * Последующие элементы - значения столбцов
     * [
     * ["device_id","timegps","week", ..., "gsm_jamming", "gps_jamming"],
     * ["860906047055337", 76666, 2184, ..., 0, 0],
     * ...
     * ["860906047055337", 76666, 2184, ..., 0, 0],
     * ]
     */
    result: string[][];
  };
  /** Ошибка */
  error?: string;
}
