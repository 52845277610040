import { DeviceRepairDeliveryEntity } from './device-repair-delivery.entity';

export enum DeviceRepairTicketStatusEnum {
  /** Новая заявка */
  New = 'NEW',
  /** В работе */
  Pending = 'PENDING',
  /** Отказ */
  Rejected = 'REJECTED',
  /** Повторная */
  Reopen = 'REOPEN',
  /** Подтвержденная */
  Confirmed = 'CONFIRMED',
  /** В дороге на завод */
  DeliveryToFactory = 'DELIVERY_TO_FACTORY',
  /** В ремонте */
  Repairing = 'REPAIRING',
  /** Ремонт завершен  */
  Repaired = 'REPAIRED',
  /** В дороге к дилеру */
  DeliveryToDealer = 'DELIVERY_TO_DEALER',
  /** Готовая */
  Done = 'DONE',
}

export type DeviceRepairTicketEntity = {
  /** ID заявки */
  id: string;
  /** Статус заявки */
  status: DeviceRepairTicketStatusEnum;
  /** VIN номер автомобиля */
  vin: string;
  /** Марка автомобиля */
  brand: string;
  /** Модель автомобиля */
  model: string;
  /** Год выпуска автомобиля */
  productionYear: number;
  /** ID ДЦ отправившего заявку */
  dealerOfficeId: string;
  /** НазванинalerOfficeEntity;
  /** Серийный номер устройства */
  deviceSerialNumber: string;
  /** Номер телефона менеджера-отправителя ДЦ */
  senderPhone: string;
  /** Добавочный номер телефона менеджера-отправителя ДЦ */
  senderPhoneAdditional: string | null;
  /** ФИО менеджера-отправителя ДЦ */
  senderName: string;
  /** ID доставки на завод */
  deliveryToFactoryId: string | null;
  /** Трек номер доставки на завод */
  deliveryToFactory: DeviceRepairDeliveryEntity | null;
  /** Получатель совпадает с отправителем */
  recipientIsSender: boolean;
  /** Номер телефона менеджера-получателя ДЦ */
  recipientPhone: string | null;
  /** Добавочный номер телефона менеджера-получателя ДЦ */
  recipientPhoneAdditional: string | null;
  /** ФИО менеджера-получателя ДЦ */
  recipientName: string | null;
  /** ID доставки в ДЦ */
  deliveryToDealerId: string | null;
  /** Трек номер доставки в ДЦ */
  deliveryToDealer: DeviceRepairDeliveryEntity | null;
  /** Адрес отправки */
  address: string;
  /** Комментарий ДЦ */
  dealerComment: string | null;
  /** Комментарий оператора Трёх Точек */
  operatorComment: string | null;
  /** Дата подтверждения заявки оператором Трёх Точек */
  confirmedAt: Date | null;
  /** Дата отказа */
  rejectedAt: Date | null;
  /** Дата отправки уведомления о нахождении заявки в статусе "Отказ"  */
  rejectExpiredNotificationSentAt: Date | null;
  /** Кол-во дней с начала заявки до завершения */
  resolvedInDays: number | null;
  /** Изображения */
  images: string[];
  /** sap code */
  sapCode: string;
  /** Дата создания заявки */
  createdAt: Date;
  /** Дата обновления заявки */
  updatedAt: Date;
};
