import { I18n } from '@store/backend-common';

import { ExternalIds } from './interfaces';

export type BrandEntity = {
  /** Идентификатор бренда */
  readonly id: string;
  /** Наименования на разных языках */
  name: I18n;
  /** Является ли популярным */
  isPopular: boolean;
  /** Страна производства */
  country: string | null;
  readonly createdAt: Date;
  updatedAt: Date;
  /** Внешние идентификаторы источников */
  externalIds: ExternalIds;
};
