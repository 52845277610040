export enum CommonRoute {
  Monitoring = 'monitoring',
  MainVehicle = 'main/{{vin}}',
  SubscriptionManagement = 'subs-management/{{vin}}',
  FinesWidget = 'fines/{{vin}}/all',
  CkadsWidget = 'tskad/{{vin}}/all',
  MaintenanceWidget = 'maintenance/{{vin}}/details',
  VehicleTrip = 'monitoring/{{vin}}/trips',
  WebView = 'webview/{{url}}',
  DigitalKey = 'main/{{vin}}/di_key',
}

export enum NotificationRoute {
  SubscriptionActivation = 'subs/{{vin}}/activation-success/{{deviceType}}/{{subscriptionId}}',
  TicketWidget = 'add-car/active',
  ProfileMain = 'profile/main',
  RumkOrder = 'rumk/{{vin}}/order/{{orderId}}',
}

export type NavigationRoute = CommonRoute | NotificationRoute;
