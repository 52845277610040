import { EntityName } from '@store/backend-entity';

import { DefaultAction, Models, UserRole } from '../../enums';

const Actions = {
  ...DefaultAction,
};

export const simCardPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof Actions>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [{ actions: [Actions.delete], subject: Models.SimCard }],

  [UserRole.Operator]: [{ actions: [Actions.delete], subject: Models.SimCard }],

  [UserRole.OperatorWithoutPBAccess]: [{ actions: [Actions.delete], subject: Models.SimCard }],

  [UserRole.DeviceOperator]: [{ actions: [Actions.delete], subject: Models.SimCard }],

  [UserRole.OperatorReadAccess]: [],
};
