import { ExternalIds, OptionalEquipment, TechnicalEquipment } from './interfaces';

import { ConfigurationEntity } from './configuration.entity';

export type EquipmentEntity = {
  /** Идентификатор комплектации */
  readonly id: string;
  /** Идентификатор конфигурации */
  readonly configurationId: string;
  /** Внешние идентификаторы источников */
  externalIds: ExternalIds;
  configuration?: ConfigurationEntity;
  /** Название группы */
  groupName: string | null;
  readonly createdAt: Date;
  updatedAt: Date;
} & TechnicalEquipment &
  OptionalEquipment;

export type EquipmentEntityRelationKey = keyof Pick<EquipmentEntity, 'configuration'>;
export type EquipmentEntityRelations = EquipmentEntityRelationKey[] | 'all';
