import { EntityName } from '@store/backend-entity';

import { Models, PartnerAction, UserRole } from '../../enums';

export const partnerPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof PartnerAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    {
      actions: [
        PartnerAction.read,
        PartnerAction.create,
        PartnerAction.update,
        PartnerAction.activatePartner,
        PartnerAction.deactivatePartner,
      ],
      subject: Models.Partner,
    },
    {
      actions: [PartnerAction.read],
      subject: Models.Partners,
    },
  ],

  [UserRole.Operator]: [
    { actions: [PartnerAction.read], subject: Models.Partner },
    { actions: [PartnerAction.read], subject: Models.Partners },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    { actions: [PartnerAction.read], subject: Models.Partner },
    { actions: [PartnerAction.read], subject: Models.Partners },
  ],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [
    { actions: [PartnerAction.read], subject: Models.Partner },
    { actions: [PartnerAction.read], subject: Models.Partners },
  ],
};
