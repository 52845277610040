import { WidgetConfigEntity } from './widget-config.entity';

import { WidgetData } from './interfaces';

/** Кэш виджета */
export type WidgetEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Идентификатор конфигурации виджета */
  readonly widgetConfigId: string;
  /** Идентификатор пользователя */
  readonly customerId: string;
  /** Идентификатор владения */
  readonly ownershipId: string;
  /** Признак доступности виджета */
  isAvailable: boolean;
  /** Данные виджета */
  data: WidgetData | null;
  /** Флаг важности виджета */
  isImportant: boolean;
  /** Флаг необходимости обновления */
  forceUpdate: boolean;
  /** Срок жизни виджета*/
  validUntil: Date | null;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;

  /** Сущность конфигурации виджета */
  widgetConfig?: WidgetConfigEntity;
};

export type WidgetEntityRelationKey = keyof Pick<WidgetEntity, 'widgetConfig'>;
export type WidgetEntityRelations = WidgetEntityRelationKey[] | 'all';
