import { FinesFineCreatedOutboxEntity } from './fines-fine-created-outbox.entity';
import { FinesFinePaidOutboxEntity } from './fines-fine-paid-outbox.entity';
import { FinesFineProcessedOutboxEntity } from './fines-fine-processed-outbox.entity';
import { FinesFineUpdatedOutboxEntity } from './fines-fine-updated-outbox.entity';

export type FinesFineOutboxEntities =
  | FinesFineCreatedOutboxEntity
  | FinesFineUpdatedOutboxEntity
  | FinesFinePaidOutboxEntity
  | FinesFineProcessedOutboxEntity;

export * from './fines-fine-updated-outbox.entity';
export * from './fines-fine-created-outbox.entity';
export * from './fines-fine-processed-outbox.entity';
export * from './fines-fine-paid-outbox.entity';
export * from './fines-fine-outbox-event-type.enum';
