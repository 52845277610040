import { ImmobiliserResponse } from '@store/amqp-contracts';

export interface SendImmobiliserCommandParams {
  initiator: string;
  algorithm: number;
}

export interface ImmobiliserCommandResponse {
  success: boolean;

  response?: ImmobiliserResponse;
}
