import {
  ApplicationRate,
  CustomerDeviceEntity,
  CustomerEntity,
  DeviceType,
  ExternalCustomerDeviceEntity,
} from '../control-center';
import { Project } from '../project.enum';

export enum TelegramNotification {
  Statistics = 'statistics',
  Rates = 'rates',
}

export type StatisticsNotificationContext = {
  notification: TelegramNotification.Statistics;
  activeCounter: number;
  inactiveCounter: number;
  activationGroup: {
    type: DeviceType;
    brandModel: string;
    count: number;
  }[];
  paymentGroup: {
    type: DeviceType;
    count: number;
    sum: number;
  }[];
  clientGroup: {
    type: DeviceType;
    brandModel: string;
    count: number;
  }[];
};

export type RatesNotificationContext = {
  notification: TelegramNotification.Rates;
  fullName: string;
  project: Project;
} & ApplicationRate &
  Pick<CustomerEntity, 'id' | 'phone'> &
  Pick<CustomerDeviceEntity, 'osName' | 'osVersion'> &
  Pick<ExternalCustomerDeviceEntity, 'appVersion'>;

export type TelegramNotificationContext = StatisticsNotificationContext | RatesNotificationContext;

export type TelegramUserEntity = {
  readonly id: string;
  readonly createdAt: Date;
  updatedAt: Date;
  telegramId: string | null;
  email: string;
  isAdmin: boolean;
  subscriptions: TelegramNotification[];
};
