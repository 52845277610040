import { I18n } from '@store/backend-common';

export type ColorEntity = {
  /** Идентификатор цвета */
  readonly id: string;
  /** Наименования на разных языках */
  name: I18n;
  /** Hex-код цвета */
  hexCode: string | null;
  code: string | null;
  readonly createdAt: Date;
  updatedAt: Date;
};
