export enum ControlCenterCustomerOutboxEventType {
  CustomerCreated = 'ControlCenterCustomerCreated',
  CustomerUpdated = 'ControlCenterCustomerUpdated',
  CustomerBlocked = 'ControlCenterCustomerBlocked',
  CustomerAddCard = 'ControlCenterCustomerAddCard',
  CustomerSetMainCard = 'ControlCenterCustomerSetMainCard',
  CustomerDeletePersonalData = 'ControlCenterCustomerDeletePersonalData',
  CustomerDeletePaymentCard = 'ControlCenterCustomerDeletePaymentCard',
  CustomerChangePhone = 'ControlCenterCustomerChangePhone',
  ExternalCustomerCreated = 'ControlCenterExternalCustomerCreated',
  ExternalCustomerUpdated = 'ControlCenterExternalCustomerUpdated',
  ExternalCustomerAcceptProcData = 'ControlCenterExternalCustomerAcceptProcData',
  ExternalCustomerDeviceCreated = 'ControlCenterExternalCustomerDeviceCreated',
  ExternalCustomerDeviceUpdated = 'ControlCenterExternalCustomerDeviceUpdated',
}
