export type PaymentOrderEntity = {
  readonly id: string;
  email: string | null;
  orderId: string | null;
  externalId: number | null;
  partnerIdent: string;
  service: string;
  successUrl: string | null;
  failedUrl: string | null;
  formUrl: string | null;
  status: PaymentOrderStatus;
  price: number;
  fee: number | null;
  cardId: string | null;
  freeze: boolean;
  extraParameters?: ExtraParameters;
  paidAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  currency: Currency;
  description: string;
  provider: Provider;
  merchantId: string;
  isSaveCard: boolean;
  clientId: string;
  fiscalData?: FiscalData;
  isProcessed: boolean;
  getToken: boolean;
  lifePeriod?: number;
  callbackUrl: string | null;
};

export enum PaymentOrderStatus {
  REGISTERED = 'REGISTERED', // Создан
  AUTHORIZED = 'AUTHORIZED', // Деньги захолдированы
  COMPLETED = 'COMPLETED', // Оплачен
  CANCELED = 'CANCELED', // Отменен
  EXPIRED = 'EXPIRED', // Истек
  BLOCKED = 'BLOCKED', // Заказ заблокирован
  REJECTED = 'REJECTED', // Ошибка
}

export enum Provider {
  B2P = 'B2P',
}

export enum Currency {
  Rub = 643,
  Eur = 978,
  Usd = 840,
}

export enum TaxEnum {
  Tax20 = 1, // Ставка НДС 20%
  Tax10 = 2, // Ставка НДС 10%
  Tax20120 = 3, // Ставка НДС расч. 20/120
  Tax10110 = 4, // Ставка НДС расч. 10/110
  Tax0 = 5, // Ставка НДС 0%
  TaxFree = 6, // НДС не облагается
}

export enum PaymentSubjectTypeEnum {
  Product = 1, // Товар(значение по умолчанию)
  ExcisableProduct = 2, // Подакцизный товар
  Job = 3, // Работа
  Service = 4, // Услуга
  GamblingBet = 5, // Ставка азартной игры
  GamblingWinning = 6, // Выигрыш азартной игры
  LotteryTicket = 7, // Лотерейный билет
  LotteryWinning = 8, // Выигрыш лотереи
  ProvisionOfRIA = 9, // Предоставление РИД
  Payment = 10, // Платеж
  AgentsCommision = 11, // Агентское вознаграждение
  CompositeSubjectOfCalculation = 12, // Составной предмет расчета
  OtherItemOfCalculation = 13, // Иной предмет расчета
  PropertyLaw = 14, // Имущественное право
  NonOperatingIncome = 15, // Внереализационный доход
  InsurancePayment = 16, // Страховые взносы
  TradeFee = 17, // Торговый сбор
  ResortFee = 18, // Курортный сбор
  Pledge = 19, // Залог
}

export enum TaxationSystem {
  GeneralOSN = 0, // Общая ОСН
  SimplifiedTaxIncome = 1, // Упрощенная доход, УСН доход
  SimplifiedTaxIncomeMinusExpense = 2, // Упрощенная доход минус расход, УСН доход -расход
  UTII = 3, // Единый налог на вмененный доход, ЕНВД
  UST = 4, // Единый сельскохозяйственный налог, ЕСН
  Patent = 5, // Патентная система налогооблажения, Патент
}

export enum TypePayment {
  CashCheckAmount = 1, // сумма по чеку наличными
  NonCashCheckAmount = 2, // сумма по чеку безналичными
  PrepaidCheckAmount = 14, // сумма по чеку предоплатой (зачетом аванса или предыдущих платежей)
  PostPaidCheckAmount = 15, // сумма по чеку постоплатой (в кредит)
  CheckAmountByCounterProvision = 16, // сумма по чеку (БСО) встречным предоставлением
}

export enum PaymentMethodType {
  PrePayment = 1, // Предоплата 100%
  PartialPrepayment = 2, // Частичная предоплата
  PrepaidExpense = 3, // Аванс
  FullPayment = 4, // Полный расчет (значение по умолчанию)
  PartialPrepaymentAndCredit = 5, // Частичный расчет и кредит
  TransferOnCredit = 6, // Передача в кредит
  LoanPayment = 7, // Оплата кредита
}

export enum MeasurementUnit {
  Count = 0, // шт. / ед.
}

export type FiscalItemData = {
  itemId: number;
  quantity: number;
  price: number;
  tax: TaxEnum;
  text: string;
  paymentSubjectType: PaymentSubjectTypeEnum;
  supplierInfo: {
    phones: string[];
    name: string;
  };
  supplierINN: string;
};

export type FiscalData = {
  items: FiscalItemData[];
  receiptClose: {
    taxationSystem: TaxationSystem;
  };
};

export type ExtraParameters = {
  'P008-1'?: string; //Наименование плательщика
  'P008-2'?: string; //Адрес плательщка
  P014?: string; //БИК получателя (9цифр)
  P016?: string; //Наименование Получателя.
  P017?: string; //Счет получателя (ровно 20 цифр).
  P020?: number; //Очередность платежа (ровно 1 цифра).
  P024?: string; //Назначение платежа.
  P060?: number; //ИНН Плательщика (10 или 12 цифр).
  P061?: number; //ИНН Получателя (10 или 12 цифр).
  P022?: string; //УИН (20 или 25 цифр).
  P101?: number; //Статус Плательщика.
  P102?: number; //КПП Плательщика (9 цифр).
  P103?: number; //Если отсутствует —проставьте 0.
  P104?: string; //КБК Получателя.
  P105?: number; //ОКТМО (8 или 11 цифр).
  P106?: string; //Основание платежа.
  P107?: string; //Налоговый период.
  P108?: string | 0; //Номер документа, который является основанием платежа
  P109?: string | 0; //Дата документа(формат yyyy.MM.dd)
  P110?: string; //Тип документа
};

export enum OrderOperationStatus {
  REGISTERED = 'REGISTERED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  TIMEOUT = 'TIMEOUT',
  ERROR = 'ERROR',
}
