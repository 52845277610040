import { Project } from '../project.enum';

export interface NotificationEntity {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  /** id пользователя */
  customerId: string;
  /** Код уведомления */
  code: NotificationCode | string | null;
  /** Тип уведомления. email, push, sms */
  type: NotificationType;
  /** Статус доставки */
  status: NotificationStatus;
  /** Было ли уведомление просмотрено пользователем */
  viewed: boolean;
  /** Куда рассылалось уведомление */
  targets: string[];
  /** Параметры уведомления (для использования в шаблоне) */
  payload: object | null;
  /** Дополнительные данные для отправки уведомления */
  additionalData: NotificationAdditionalData | null;
  /** Дополнительные параметры отправки уведомления */
  additionalOptions: object | null;
  /** Метаданные уведомления, переданные сервисом источником */
  metadata: Record<string, string> | null;
  /** Проект */
  project: Project;
  /** Можно ли перейти по нотификации */
  isClickable?: boolean;
  notificationTemplateId: string | null;
}

export enum NotificationType {
  Email = 'EMAIL',
  Push = 'PUSH',
  Sms = 'SMS',
}

export enum NotificationStatus {
  New = 'NEW',
  Sent = 'SENT',
  Error = 'ERROR',
}

export enum NotificationCode {
  Test = 'TEST',
  SuccessSubscriptionActivation = 'SUCCESS_SUBSCRIPTION_ACTIVATION',
  FirstAttemptFailedSubscriptionActivation = 'FIRST_ATTEMPT_FAILED_SUBSCRIPTION_ACTIVATION',
  LastAttemptFailedSubscriptionActivation = 'LAST_ATTEMPT_FAILED_SUBSCRIPTION_ACTIVATION',
  GiftedSubscriptionExpiring = 'GIFTED_SUBSCRIPTION_EXPIRING',
  SubscriptionExpiring = 'SUBSCRIPTION_EXPIRING',
  SubscriptionClosed = 'SUBSCRIPTION_CLOSED',
  SubscriptionPaymentFailed = 'SUBSCRIPTION_PAYMENT_FAILED',
  FineNewWithDiscount = 'FINE_NEW_WITH_DISCOUNT',
  FineNewWithDiscountExpiring = 'FINE_NEW_WITH_DISCOUNT_EXPIRING',
  FineNew = 'FINE_NEW',
  CkadNew = 'CKAD_NEW',
  CkadNewWithoutPayment = 'CKAD_NEW_WITHOUT_PAYMENT',
  DealerCrmDistribution = 'DEALER_CRM_DISTRIBUTION',
  MaintenanceConfirmed = 'MAINTENANCE_CONFIRMED',
  MaintenanceRejected = 'MAINTENANCE_REJECTED',
  MaintenanceBeforeReminder = 'MAINTENANCE_BEFORE_REMINDER',
  MaintenanceAfterRate = 'MAINTENANCE_AFTER_RATE',
  TicketReturned = 'TICKET_RETURNED',
  TicketRejected = 'TICKET_REJECTED',
  TicketDone = 'TICKET_DONE',
  VehicleRoadAccident = 'VEHICLE_ROAD_ACCIDENT',
  VehicleTowing = 'VEHICLE_TOWING',
  VehicleLowVoltage = 'VEHICLE_LOW_VOLTAGE',
  DeletePersonalDataTicketInProgress = 'DELETE_PERSONAL_DATE_TICKET_IN_PROGRESS',
  SupportChatNotification = 'SUPPORT_CHAT_NOTIFICATION',
  RumkOrderDone = 'RUMK_ORDER_DONE',
  AutoPaymentSubscriptionExpiring = 'AUTO_PAYMENT_SUBSCRIPTION_EXPIRING',
}

export interface PushNotificationAdditionalData {
  navigationRoute?: string;
  navigationRouteParams?: object;
}

export type NotificationAdditionalData = PushNotificationAdditionalData;

export enum NotificationRoute {
  Monitoring = 'monitoring',
  MainVehicle = 'main/{{vin}}',
  SubscriptionManagement = 'subs-management/{{vin}}',
  SubscriptionActivation = 'subs/{{vin}}/activation-success/{{deviceType}}/{{subscriptionId}}',
  FinesWidget = 'fines/{{vin}}/all',
  CkadsWidget = 'tskad/{{vin}}/all',
  MaintenanceWidget = 'maintenance/{{vin}}/details',
  TicketWidget = 'add-car/active',
  VehicleTrip = 'monitoring/{{vin}}/trips',
  ProfileMain = 'profile/main',
  WebView = 'webview/{{url}}',
  RumkOrder = 'rumk/{{vin}}/order/{{orderId}}',
  DigitalKey = 'main/{{vin}}/di_key',
}

export const PUSH_NAVIGATION_ROUTE_MAP: Record<NotificationCode, NotificationRoute> = {
  [NotificationCode.Test]: NotificationRoute.Monitoring,
  [NotificationCode.SuccessSubscriptionActivation]: NotificationRoute.SubscriptionActivation,
  [NotificationCode.FirstAttemptFailedSubscriptionActivation]: NotificationRoute.MainVehicle,
  [NotificationCode.LastAttemptFailedSubscriptionActivation]: NotificationRoute.MainVehicle,
  [NotificationCode.GiftedSubscriptionExpiring]: NotificationRoute.SubscriptionManagement,
  [NotificationCode.SubscriptionExpiring]: NotificationRoute.SubscriptionManagement,
  [NotificationCode.AutoPaymentSubscriptionExpiring]: NotificationRoute.SubscriptionManagement,
  [NotificationCode.SubscriptionClosed]: NotificationRoute.MainVehicle,
  [NotificationCode.SubscriptionPaymentFailed]: NotificationRoute.SubscriptionManagement,
  [NotificationCode.FineNew]: NotificationRoute.FinesWidget,
  [NotificationCode.FineNewWithDiscountExpiring]: NotificationRoute.FinesWidget,
  [NotificationCode.FineNewWithDiscount]: NotificationRoute.FinesWidget,
  [NotificationCode.CkadNew]: NotificationRoute.CkadsWidget,
  [NotificationCode.CkadNewWithoutPayment]: NotificationRoute.CkadsWidget,
  [NotificationCode.DealerCrmDistribution]: NotificationRoute.MainVehicle,
  [NotificationCode.MaintenanceConfirmed]: NotificationRoute.MaintenanceWidget,
  [NotificationCode.MaintenanceRejected]: NotificationRoute.MaintenanceWidget,
  [NotificationCode.MaintenanceBeforeReminder]: NotificationRoute.MaintenanceWidget,
  [NotificationCode.MaintenanceAfterRate]: NotificationRoute.MaintenanceWidget,
  [NotificationCode.TicketReturned]: NotificationRoute.TicketWidget,
  [NotificationCode.TicketDone]: NotificationRoute.TicketWidget,
  [NotificationCode.TicketRejected]: NotificationRoute.TicketWidget,
  [NotificationCode.VehicleRoadAccident]: NotificationRoute.VehicleTrip,
  [NotificationCode.VehicleTowing]: NotificationRoute.VehicleTrip,
  [NotificationCode.VehicleLowVoltage]: NotificationRoute.DigitalKey,
  [NotificationCode.DeletePersonalDataTicketInProgress]: NotificationRoute.ProfileMain,
  [NotificationCode.SupportChatNotification]: NotificationRoute.WebView,
  [NotificationCode.RumkOrderDone]: NotificationRoute.RumkOrder,
};
