export enum EActivationEventType {
  /**
   * Устройство не активировалось/деактивировалось по прошествии определенного количества времени
   */
  Timeout = 'TIMEOUT',
  /**
   * Устройство не активировалось/деактивировалось после нескольких попыток
   */
  Failed = 'FAILED',
  /**
   * Запрос на активацию/деактивацию был отменен
   */
  Canceled = 'CANCELED',
  /**
   * Успех
   */
  Success = 'SUCCESS',
}
