export enum DealersMaintenanceOutboxEventType {
  MaintenanceCreated = 'MaintenanceCreated',
  MaintenanceUpdated = 'MaintenanceUpdated',
  MaintenanceCanceled = 'MaintenanceCanceled',
  MaintenanceFinished = 'MaintenanceFinished',
  MaintenanceConfirmed = 'MaintenanceConfirmed',
  MaintenanceRejected = 'MaintenanceRejected',
  MaintenanceTypeCreated = 'MaintenanceTypeCreated',
  MaintenanceTypeUpdated = 'MaintenanceTypeUpdated',
}
