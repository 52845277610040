import { DefaultAction } from './default-action.enum';

export enum UniqueDeviceActions {
  installSim = 'installSim',
  updateSim = 'updateSim',
  getLastPacket = 'getLastPacket',
  getCommandLog = 'getCommandLog',
  getStatistics = 'getStatistics',
  getTrips = 'getTrips',
  getTripsDaily = 'getTripsDaily',
  sendCommand = 'sendCommand',
  activate = 'activate',
  deactivate = 'deactivate',
  upgrade = 'upgrade',
  upload = 'upload',
  start = 'start',
}

export const DeviceAction = {
  ...DefaultAction,
  ...UniqueDeviceActions,
};
