import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthDeviceService {
  protected storageKey = 'deviceId';

  public getOrGenerate(): string {
    let deviceId = this.get();

    if (!deviceId) {
      deviceId = this.generateDeviceId();
      this.set(deviceId);
    }

    return deviceId;
  }

  public get(): string | null {
    return localStorage.getItem(this.storageKey) || null;
  }

  public set(deviceId: string): void {
    localStorage.setItem(this.storageKey, deviceId);
  }

  protected generateDeviceId(): string {
    const length = 40;
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    let text = '';

    for (let i = 0; i < length; i++) {
      text += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return text;
  }
}
