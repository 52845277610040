import { InjectionToken, Provider } from '@angular/core';

export const APP_VERSION = new InjectionToken<string | null>('App Version');

export function provideAppVersion(version?: string | null): Provider {
  return {
    provide: APP_VERSION,
    useValue: version || null,
  };
}
