import { DefaultAction } from './default-action.enum';

export enum UniquePartnerActions {
  activatePartner = 'activatePartner',
  deactivatePartner = 'deactivatePartner',
}

export const PartnerAction = {
  ...DefaultAction,
  ...UniquePartnerActions,
};
