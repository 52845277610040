import { DeviceTestVehicleModelEntity } from '../device-test-vehicle-model.entity';
import { MisosBeepMode } from '../../../control-center';

export interface DeviceTestReadModelInterface {
  currentSettings: number | null;

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  currentBeepMode: MisosBeepMode | null;

  vehicleModel: DeviceTestVehicleModelEntity | null;

  currentVehicleModel: DeviceTestVehicleModelEntity | null;

  equipmentCode: string | null;
}
