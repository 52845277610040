import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PureAbility } from '@casl/ability';
import { AbilityModule, AbilityService, AblePurePipe } from '@casl/angular';

import { AppAbility } from './core/auth/states/auth-profile.state';

@NgModule({
  imports: [CommonModule, AbilityModule],
  exports: [AblePurePipe, AbilityModule],
  providers: [
    { provide: AppAbility, useValue: AppAbility },
    { provide: PureAbility, useExisting: AppAbility },
    AbilityService,
  ],
})
export class CaslModule {}
