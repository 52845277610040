import { UserEntity } from './user.entity';

export type UserDeviceEntity = {
  readonly id: string;
  readonly userId: UserEntity['id'];
  loginIp: string;
  deviceId: string;
  deviceName: string;
  userAgent: string;
  platform: string;
  platformVersion: string;
  appVersion: string;
  lastTokenRefreshIp: string | null;
  lastTokenRefreshTime: Date | null;
  isActive: boolean;
};
