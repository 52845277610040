import { DealersServiceBookOutboxCreatedEntity } from './service-book-outbox-created.entity';
import { DealersServiceBookOutboxDeletedEntity } from './service-book-outbox-deleted.entity';
import { DealersServiceBookOutboxUpdatedEntity } from './service-book-outbox-updated.entity';

export type DealersServiceBookOutboxEntities =
  | DealersServiceBookOutboxDeletedEntity
  | DealersServiceBookOutboxCreatedEntity
  | DealersServiceBookOutboxUpdatedEntity;

export * from './service-book-outbox-deleted.entity';
export * from './service-book-outbox-created.entity';
export * from './service-book-outbox-updated.entity';
export * from './service-book-event-type.enum';
