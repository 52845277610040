export enum ControlCenterSubscriptionOutboxEventType {
  SubscriptionCreated = 'ControlCenterSubscriptionCreated',
  SubscriptionUpdated = 'ControlCenterSubscriptionUpdated',
  SubscriptionExpiring = 'ControlCenterSubscriptionExpiring',
  SubscriptionActivationStarted = 'ControlCenterSubscriptionActivationStarted',
  SubscriptionActivationProblem = 'ControlCenterSubscriptionActivationProblem',
  SubscriptionActivated = 'ControlCenterSubscriptionActivated',
  SubscriptionDeactivated = 'ControlCenterSubscriptionDeactivated',
  SubscriptionClosed = 'ControlCenterSubscriptionClosed',
}
