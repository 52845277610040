export type DealerCoordinates = {
  /** Широта */
  lat: number;
  /** Долгота */
  lng: number;
};

export type DealerEntity = {
  /** Название */
  name: string;
  /** Код ДЦ */
  sapCode: string;
  /** Родительский код ДЦ */
  parentSapCode: string;
  /** Город */
  city: string | null;
  /** Адрес */
  address: string | null;
  /** Ссылка на ДЦ */
  url: string | null;
  /** Координаты */
  coordinates: DealerCoordinates | null;
  /** Ссылка на лого */
  logoUrl: string | null;
  /** Номер телефона */
  phone: string | null;
  /** Признак доступности чата с ДЦ */
  hasChat: boolean;
  /** Бренд */
  brand: string | null;
  readonly createdAt: Date;
  updatedAt: Date;
};

export type DealerAdapterEntity = Omit<DealerEntity, 'createdAt' | 'updatedAt'>;
