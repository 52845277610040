import { I18n } from '@store/backend-common';

import { BrandEntity } from './brand.entity';

import { ExternalIds } from './interfaces';

export type ModelEntity = {
  /** Идентификатор модели */
  readonly id: string;
  /** Идентификатор бренда */
  readonly brandId: string;
  /** Внешние идентификаторы источников */
  externalIds: ExternalIds;
  /** Наименования на разных языках */
  name: I18n;
  /** Класс модели */
  class: string | null;
  /** Год начала производства модели */
  yearStart: number | null;
  /** Год окончания производства модели */
  yearStop: number | null;
  brand?: BrandEntity;
  readonly createdAt: Date;
  updatedAt: Date;
};

export type ModelEntityRelationKey = keyof Pick<ModelEntity, 'brand'>;
export type ModelEntityRelations = ModelEntityRelationKey[] | 'all';
