import { I18n } from '@store/backend-common';

import { StoryViewEntity } from './story-view.entity';

export enum StoryPageType {
  Common = 'Common',
  CommonWithButton = 'CommonWithButton',
  Quiz = 'Quiz',
  Vote = 'Vote',
}

export type StoryPagePayload = StoryPageCommon | StoryPageCommonWithButton | StoryPageQuiz | StoryPageVote;

export type StoryPageCommon = {
  type: StoryPageType;
};

export type StoryPageCommonWithButton = {
  type: StoryPageType;
  /** Текст кнопки */
  buttonMessage: I18n;
  /** Url кнопки */
  buttonUrl: string | null;
  /** Url кнопки */
  buttonPath: string | null;
};

export type StoryPageQuiz = {
  type: StoryPageType;
  /** Варианты квиза */
  variants: string[];
  /** Цвет вариантов квиза */
  quizVariantsColor: string | null;
};

export type StoryPageVote = {
  type: StoryPageType;
  /** Цвет вариантов ответа */
  voteVariantsColor: string | null;
};

export type StoryPageEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Идентификатор истории*/
  readonly storyId: string;
  /** Заголовок */
  title: I18n;
  /** Текстовый блок */
  message: I18n;
  /** Цвет заголовка hex */
  titleColor: string | null;
  /** Цвет текста hex */
  messageColor: string | null;
  /** Цвет фона hex */
  backgroundColor: string | null;
  /** Путь фонового изображения */
  backgroundImagePath: string | null;
  /** Тип страницы */
  type: StoryPageType;
  /** Позиция истории в списке */
  position: number;

  /** Данные контента */
  payload: StoryPagePayload;

  /** Данные по просмотрам */
  views?: StoryViewEntity[];
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
};

export type StoryPageEntityRelations = [] | 'all';
