import { FinePaidStatus } from './interfaces';

export type FineEntity = {
  readonly id: string;
  readonly resolutionNumber: string;
  readonly vin: string;
  readonly externalId: string;
  billDate: Date;
  offenseTime: Date | null;
  discountSize: number;
  discountDate: Date | null;
  location: string | null;
  koapText: string | null;
  koapCode: string | null;
  paymentDue: Date;
  paymentAmount: number;
  paymentAmountWithDiscount: number | null;
  paymentIssuerName: string;
  paymentInn: string;
  paymentKpp: string;
  paymentCorAccount: string;
  paymentBankAccount: string;
  paymentBankName: string;
  paymentBik: string;
  paymentReceiverName: string;
  paymentKbk: string;
  paymentOktmo: string;
  longitude: number | null;
  latitude: number | null;
  photos: string[] | null;
  evacuation: boolean;
  paidRoad: boolean;
  cashlessAmount: number;
  isAppealed: boolean;
  inProcessing: boolean;
  isCkad: boolean;
  paidStatus: FinePaidStatus;
  payedDate: Date | null;
  facilityOrderId: string | null;
  paidAt: Date | null;
  description: string | null;
  descriptionWithCode: string | null;
  geocodedAt: Date | null;
  archived: boolean;
  readonly createdAt: Date;
  updatedAt: Date;
};
