import { Injectable } from '@angular/core';
import { AppAbility } from '@app/core/auth/states/auth-profile.state';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Actions } from '@store/casl';

import { SidenavItem } from '../sidenav-item.model';
import { SIDENAV_MENU_ITEMS } from '../sidenav-menu-items.const';
import {
  AddSidenavItemAction,
  RemoveSidenavItemAction,
  SetCurrentlyOpenByRouteAction,
  ToggleOpenSidenavItemAction,
  UpdateSidenavItemAction,
} from './sidenav.actions';

export interface SidenavStateModel {
  sidenavItems: SidenavItem[];
  currentlyOpen: SidenavItem[];
  itemsUpdated: boolean;
}

const SIDENAV_STATE_EMPTY: SidenavStateModel = {
  sidenavItems: [...SIDENAV_MENU_ITEMS],
  currentlyOpen: [],
  itemsUpdated: false,
};

function getAllParentItems(item: SidenavItem, currentlyOpenTemp: SidenavItem[] = []): SidenavItem[] {
  currentlyOpenTemp.unshift(item);

  if (item.hasParent()) {
    return getAllParentItems(item.parent as SidenavItem, currentlyOpenTemp);
  } else {
    return currentlyOpenTemp;
  }
}

function filterSidenavItems(items: SidenavItem[], ability: AppAbility): SidenavItem[] {
  return items.reduce((filteredItems, item) => {
    if (
      item.subject[0] === 'all' ||
      item.subject.some((model) => ability.can(Actions.read, model) || ability.can(Actions.start, model))
    ) {
      const filteredSubItems = item.subItems ? filterSidenavItems(item.subItems, ability) : [];

      const newItem = new SidenavItem({
        ...item,
        subItems: filteredSubItems.length > 0 ? filteredSubItems : undefined,
      });

      filteredItems.push(newItem);
    }

    return filteredItems;
  }, [] as SidenavItem[]);
}

function findByRouteRecursive(route: string, collection: SidenavItem[]): SidenavItem | null {
  let result = collection.find((item) => item.route === route);

  if (!result) {
    collection.forEach((item) => {
      if (item.hasSubItems()) {
        const found = findByRouteRecursive(route, item.subItems || []);

        if (found) {
          result = found;
        }
      }
    });
  }

  return result ?? null;
}

@State<SidenavStateModel>({
  name: 'sidenav',
  defaults: { ...SIDENAV_STATE_EMPTY },
})
@Injectable()
export class SidenavState {
  @Selector()
  // public static getSidenavItems(): (ability: AppAbility) => (state: SidenavStateModel) => SidenavItem[] {
  //   return (ability: AppAbility) =>
  //     createSelector([SidenavState], (state: SidenavStateModel): SidenavItem[] => {
  //       return state.sidenavItems.filter((item) =>
  //         item.subject[0] === 'all' ? true : item.subject.some((model) => ability.can(Actions.read, model)),
  //       );
  //     });
  // }
  public static getSidenavItems(state: SidenavStateModel): SidenavItem[] {
    const ability = AppAbility;

    if (state.itemsUpdated) {
      return state.sidenavItems;
    }

    return state.sidenavItems.filter((item) =>
      item.subject[0] === 'all' ? true : item.subject.some((model) => ability.can(Actions.read, model)),
    );
  }

  @Selector()
  public static getSidenavCurrentlyOpen(state: SidenavStateModel): SidenavItem[] {
    return state.currentlyOpen;
  }

  @Action(AddSidenavItemAction)
  public addSidenavItemAction(ctx: StateContext<SidenavStateModel>, { payload }: AddSidenavItemAction): void {
    const item = payload;
    const sidenavItems = ctx.getState().sidenavItems;

    if (sidenavItems.indexOf(item) === -1) {
      ctx.patchState({
        sidenavItems: [...sidenavItems, item],
      });
    }
  }

  @Action(RemoveSidenavItemAction)
  public removeSidenavItemAction(ctx: StateContext<SidenavStateModel>, { payload }: RemoveSidenavItemAction): void {
    const item = payload;
    const sidenavItems = ctx.getState().sidenavItems;
    ctx.patchState({
      sidenavItems: sidenavItems.filter((stateItem) => stateItem !== item),
    });
  }

  @Action(ToggleOpenSidenavItemAction)
  public toggleOpenSidenavItemAction(
    ctx: StateContext<SidenavStateModel>,
    { payload }: ToggleOpenSidenavItemAction,
  ): void {
    const item = payload;
    let currentlyOpen = ctx.getState().currentlyOpen;

    if (currentlyOpen.indexOf(item) > -1) {
      if (currentlyOpen.length > 1) {
        currentlyOpen = currentlyOpen.slice(0, currentlyOpen.indexOf(item));
      } else {
        currentlyOpen = [];
      }
    } else {
      currentlyOpen = getAllParentItems(item);
    }

    ctx.patchState({
      currentlyOpen,
    });
  }

  @Action(SetCurrentlyOpenByRouteAction)
  public setCurrentlyOpenByRouteAction(
    ctx: StateContext<SidenavStateModel>,
    { payload }: SetCurrentlyOpenByRouteAction,
  ): void {
    const route = payload;
    let currentlyOpen: SidenavItem[] = [];

    const sidenavItems = ctx.getState().sidenavItems;
    const item = findByRouteRecursive(route, sidenavItems);

    if (item && item.hasParent()) {
      currentlyOpen = getAllParentItems(item.parent as SidenavItem);
    } else if (item) {
      currentlyOpen = [item];
    }

    ctx.patchState({
      currentlyOpen,
    });
  }

  @Action(UpdateSidenavItemAction)
  public updateSidenavItemAction(ctx: StateContext<SidenavStateModel>): void {
    const sidenavItems = ctx.getState().sidenavItems;
    const ability = AppAbility;

    if (ability.rules.length) {
      const updatedSidenavItems = filterSidenavItems(sidenavItems, ability);

      ctx.patchState({
        sidenavItems: updatedSidenavItems,
      });
    }
  }
}
