import { EGeoJsonType, GeoJsonEvent, Stats, Track } from './trip.interface';

export interface TripsDailyInfo {
  totalStats: Stats;
  /** Поездки */
  rows: DeviceTripDayInfo[];
  /** Пагинация */
  total: number;
  page: number;
  onPage: number;
}

// export interface TripDailyStats {
//   /** Средняя скорость за выбранный период, км/ч */
//   avgSpeed: number;
//   /** Максимальная скорость за выбранный период, км/ч */
//   maxSpeed: number;
//   /** Потраченное топливо скорость за выбранный период, л */
//   fuel: number;
//   /** Пробег в городе за выбранный период, км */
//   mileageCity: number;
//   /** Пробег вне города за выбранный период, км */
//   mileageTrack: number;
//   /** Пробег за выбранный период, км */
//   mileage: number;
//   /** Время поездок за выбранный период, сек. */
//   time: number;
// }

export interface DeviceTripDayInfo {
  /** Дата начала дня */
  time: Date;
  /** Треки за день */
  tracks: (DeviceTripDailyInfo | DeviceEventDailyInfo)[];
  /** Время поездок за день */
  driveTime: number;
  /** Средняя скорость за день */
  avgSpeed: number;
  /** Максимальная скорость за день */
  maxSpeed: number;
  /** Потраченное топливо скорость за день */
  fuel: number;
  /** Пробег в городе за день */
  cityMileage: number;
  /** Пробег вне города за день */
  countryMileage: number;
  /** Стиль вождения на конец дня */
  drivingStyle: number;
  /** Эвакуация */
  towing: boolean;
  /** Было ли дтп во время поездки */
  accident: boolean;
  /** Url на картинку маршрута за день */
  map: string | null;
}

export interface DeviceEventDailyInfo {
  type: EGeoJsonType.Event;
  geojson: GeoJsonEvent;
}

export interface DeviceTripDailyInfo {
  type: EGeoJsonType.Trip;
  /** url карты с отображением поездки */
  mapImage: string | null;
  /** Время в ISO начала движения */
  timeStart: Date;
  /** Время в ISO остановки */
  timeStop: Date;
  /** Адрес откуда началось движение */
  addressStartLocation: string | null;
  /** Адрес где закончилось движение */
  addressStopLocation: string | null;
  /** Статистика */
  stats: Stats;
  /** Стиль вождения */
  drivingStyle: number;
  geojson: Track;
  towing: boolean;
  /** Было ли дтп во время поездки */
  accident: boolean;
}
