import { EntityName } from '@store/backend-entity';

import { CustomerAction, Models, UserRole } from '../../enums';

export const customerPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof CustomerAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    {
      actions: [
        CustomerAction.read,
        CustomerAction.update,
        CustomerAction.delete,
        CustomerAction.logout,
        CustomerAction.sendPush,
      ],
      subject: Models.Customer,
    },
    { actions: [CustomerAction.read], subject: Models.Customers },
  ],

  [UserRole.Operator]: [
    {
      actions: [
        CustomerAction.read,
        CustomerAction.update,
        CustomerAction.delete,
        CustomerAction.logout,
        CustomerAction.sendPush,
      ],
      subject: Models.Customer,
    },
    { actions: [CustomerAction.read], subject: Models.Customers },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    { actions: [CustomerAction.delete], subject: Models.Customer },
    { actions: [CustomerAction.read], subject: Models.Customers },
  ],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [
    { actions: [CustomerAction.read], subject: Models.Customer },
    { actions: [CustomerAction.read], subject: Models.Customers },
  ],
};
