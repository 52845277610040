export namespace AuthTokenStateActions {
  export class Login {
    static readonly type = '[AuthToken] Login';

    constructor(
      public readonly token: string,
      public readonly refreshToken: string,
    ) {}
  }

  export class Refresh {
    static readonly type = '[AuthToken] Refresh';
  }

  export class Logout {
    static readonly type = '[AuthToken] Logout';
  }
}
