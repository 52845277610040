export enum DeviceRepairDeliveryStatusEnum {
  /** Новое */
  New = 'NEW',
  /** В пути */
  Pending = 'PENDING',
  /** Доставка завершена */
  Done = 'DONE',
}

export type DeviceRepairDeliveryEntity = {
  /** ID доставки */
  id: string;
  /** Трек номер */
  trackNumber: string;
  /** Статус доставки */
  status: DeviceRepairDeliveryStatusEnum;
  /** GUID текущего статуса во внешней системе */
  externalStateGuid: string | null;
  /** Название текущего статуса во внешней системе */
  externalStateName: string | null;
  /** Название текущего статуса во внешней системе */
  externalStateDate: Date | null;
  /** Дата забора */
  takeDate: Date | null;
  /** Дата доставки */
  deliveryDate: Date | null;
  /** Регион отправления */
  senderGeographyName: string | null;
  /** Регион получения */
  recipientGeographyName: string | null;
  /** Дата создания */
  createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
};
