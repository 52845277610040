import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  templateUrl: './updater.component.html',
  styleUrls: ['./updater.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdaterComponent {
  constructor(private _snackBar: MatSnackBar) {}

  public update(): void {
    document.location.reload();
  }

  public close(): void {
    this._snackBar._openedSnackBarRef?.dismiss();
  }
}
