export enum UserRole {
  Admin = 'ADMIN',
  Operator = 'OPERATOR',
  OperatorWithoutPBAccess = 'OPERATOR_WITHOUT_PB_ACCESS',
  DeviceOperator = 'DEVICE_OPERATOR',
  OperatorReadAccess = 'OPERATOR_READ_ACCESS',
}

export enum UserStatus {
  New = 'NEW',
  Active = 'ACTIVE',
  PasswordReset = 'PASSWORD_RESET',
  Deactivated = 'DEACTIVATED',
}

export const USER_STATUS_MAP = new Map<UserStatus, UserStatus[]>([
  [UserStatus.New, [UserStatus.Active, UserStatus.Deactivated]],
  [UserStatus.Active, [UserStatus.PasswordReset, UserStatus.Deactivated]],
  [UserStatus.PasswordReset, [UserStatus.Active, UserStatus.Deactivated]],
  [UserStatus.Deactivated, [UserStatus.Active]],
]);

export type UserEntity = {
  readonly id: string;
  status: UserStatus;
  email: string;
  phone: string | null;
  passwordHash: string | null;
  firstName: string | null;
  lastName: string | null;
  patronymic: string | null;
  readonly registrationDate: Date;
  loginDate: Date | null;
  lastPasswordDate: Date | null;
  role: UserRole;
  lastRequestDate: Date | null;
  updateDate: Date | null;
  blockDate: Date | null;
};
