import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [CommonModule, MatIconModule],
  exports: [MatIconModule],
})
export class SvgIconModule {
  icons: string[] = [
    'activated',
    'download',
    'partners',
    'activation',
    'draft-orders',
    'pause-outline',
    'add',
    'drop-down-arrow',
    'payments',
    'apps',
    'edit',
    'person',
    'arrow-drop-down',
    'email',
    'phone',
    'arrow-drop-up',
    'emergency',
    'play',
    'arrow-forward',
    'expand-less',
    'point',
    'arrow',
    'expand-more',
    'power-on',
    'audio',
    'external-link',
    'print',
    'back',
    'external',
    'product-subscriptions',
    'bookmark',
    'favorite',
    'refresh',
    'box-1-fill',
    'filter',
    'ring_medium',
    'box-down',
    'fullscreen-exit',
    'search-1',
    'burger',
    'fullscreen',
    'search',
    'cancel',
    'help-circle',
    'settings',
    'car-multiple',
    'heroicons_view-columns-solid',
    'share',
    'car',
    'home',
    'shevron',
    'check',
    'horn',
    'sort',
    'chevron-left',
    'i-documents-accepted',
    'star',
    'chevron-right',
    'icon-park_filter-one',
    'start-marker',
    'clear',
    'image-media',
    'start',
    'close',
    'info-16-filled',
    'stop',
    'copy',
    'info',
    'supplier',
    'create',
    'kan',
    'system-ok',
    'date',
    'loading',
    'tariffs',
    'deactivated',
    'location',
    'timeline-arrow',
    'deactivation',
    'lock',
    'truck-delivery',
    'delete',
    'menu',
    'trunk-close',
    'directions',
    'mic',
    'trunk-open',
    'documents-done',
    'mobile-solid',
    'trunk',
    'documents',
    'monitoring',
    'unlock',
    'done',
    'more-(12px)',
    'visibility-off',
    'double-arrow-up',
    'more',
    'visibility',
    'wheel',
    'photoPlaceholder',
    'drag',
    'devices',
    'ring-medium',
    'snowflake',
    'minus',
    'dampers1',
    'dampers2',
    'dampers3',
    'dampers4',
    'pdf-icon',
  ];

  constructor(registry: MatIconRegistry, ds: DomSanitizer) {
    this.icons.forEach((icon) =>
      registry.addSvgIcon(icon, ds.bypassSecurityTrustResourceUrl(`/assets/icons/svg/${icon}.svg`)),
    );
  }
}
