import { ColorEntity } from './color.entity';
import { GenerationEntity } from './generation.entity';

export type GenerationColorEntity = {
  /** Идентификатор поколения */
  readonly id: string;
  /** Идентификатор поколения */
  readonly generationId: string;
  readonly generation?: GenerationEntity;
  /** Идентификатор цвета */
  readonly colorId: string;
  readonly color?: ColorEntity;
  readonly createdAt: Date;
  updatedAt: Date;
  /** Массив путей к рендерам */
  render: string[];
};
export type GenerationColorEntityRelationKey = keyof Pick<GenerationColorEntity, 'generation' | 'color'>;
export type GenerationColorEntityRelations = GenerationColorEntityRelationKey[] | 'all';
