import { FinesCarCreatedOutboxEntity } from './fines-car-created-outbox.entity';
import { FinesCarDeletedOutboxEntity } from './fines-car-deleted-outbox.entity';
import { FinesCarUpdatedOutboxEntity } from './fines-car-updated-outbox.entity';

export type FinesCarOutboxEntities =
  | FinesCarCreatedOutboxEntity
  | FinesCarUpdatedOutboxEntity
  | FinesCarDeletedOutboxEntity;

export * from './fines-car-created-outbox.entity';
export * from './fines-car-updated-outbox.entity';
export * from './fines-car-deleted-outbox.entity';
export * from './fines-car-outbox-event-type.enum';
