import { ControlCenterVehicleArchivedOutboxEntity } from './control-center-vehicle-archived-outbox.entity';
import { ControlCenterVehicleCreatedOutboxEntity } from './control-center-vehicle-created-outbox.entity';
import { ControlCenterVehicleDeletedOutboxEntity } from './control-center-vehicle-deleted-outbox.entity';
import { ControlCenterVehicleDeviceActivatedOutboxEntity } from './control-center-vehicle-device-activated-outbox.entity';
import { ControlCenterVehicleDeviceDeactivatedOutboxEntity } from './control-center-vehicle-device-deactivated-outbox.entity';
import { ControlCenterVehicleDeviceEventProcessedOutboxEntity } from './control-center-vehicle-device-event-processed-outbox.entity';
import { ControlCenterVehicleDeviceInstalledEntity } from './control-center-vehicle-device-installed.entity';
import { ControlCenterVehicleDeviceReplacedOutboxEntity } from './control-center-vehicle-device-replaced-outbox.entity';
import { ControlCenterVehicleDeviceUninstalledEntity } from './control-center-vehicle-device-uninstalled.entity';
import { ControlCenterVehicleNewPrioritizedDeviceOutboxEntity } from './control-center-vehicle-new-prioritized-device-outbox.entity';
import { ControlCenterVehiclePartnerUpdatedEntity } from './control-center-vehicle-partner-updated.entity';
import { ControlCenterVehicleUpdatedOutboxEntity } from './control-center-vehicle-updated-outbox.entity';
import { ControlCenterVehicleVinChangedOutboxEntity } from './control-center-vehicle-vin-changed-outbox.entity';

export type ControlCenterVehicleOutboxEntities =
  | ControlCenterVehicleCreatedOutboxEntity
  | ControlCenterVehicleDeletedOutboxEntity
  | ControlCenterVehicleUpdatedOutboxEntity
  | ControlCenterVehicleDeviceActivatedOutboxEntity
  | ControlCenterVehicleDeviceDeactivatedOutboxEntity
  | ControlCenterVehicleVinChangedOutboxEntity
  | ControlCenterVehicleNewPrioritizedDeviceOutboxEntity
  | ControlCenterVehicleArchivedOutboxEntity
  | ControlCenterVehicleDeviceInstalledEntity
  | ControlCenterVehicleDeviceReplacedOutboxEntity
  | ControlCenterVehicleDeviceUninstalledEntity
  | ControlCenterVehicleDeviceEventProcessedOutboxEntity
  | ControlCenterVehiclePartnerUpdatedEntity;

export * from './control-center-vehicle-outbox-event-type.enum';
export * from './control-center-vehicle-created-outbox.entity';
export * from './control-center-vehicle-deleted-outbox.entity';
export * from './control-center-vehicle-updated-outbox.entity';
export * from './control-center-vehicle-device-activated-outbox.entity';
export * from './control-center-vehicle-device-deactivated-outbox.entity';
export * from './control-center-vehicle-vin-changed-outbox.entity';
export * from './control-center-vehicle-new-prioritized-device-outbox.entity';
export * from './control-center-vehicle-archived-outbox.entity';
export * from './control-center-vehicle-device-installed.entity';
export * from './control-center-vehicle-device-replaced-outbox.entity';
export * from './control-center-vehicle-device-uninstalled.entity';
export * from './control-center-vehicle-device-event-processed-outbox.entity';
export * from './control-center-vehicle-partner-updated.entity';
