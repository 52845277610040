import { RequireAtLeastOne } from '@store/backend-common';

import { TariffEntity } from '../../subscriptions';
import { OwnershipEntity } from '../ownerships';
import { VehicleEntity } from '../vehicles';
import { InvoiceEntity } from './invoice.entity';
import { OfferEntity } from './offer.entity';

export enum SubscriptionActorType {
  Customer = 'customer',
  Operator = 'operator',
  Partner = 'partner',
  System = 'system',
}

export enum SubscriptionActivationStatus {
  InProgress = 'IN_PROGRESS',
  FirstAttemptFailed = 'FIRST_ATTEMPT_FAILED',
  AnotherAttemptFailed = 'ANOTHER_ATTEMPT_FAILED',
  NoAttemptsRemaining = 'NO_ATTEMPTS_REMAINING',
}

export interface SubscriptionActivationProcess {
  isActivationStarted: boolean;
  lastActivationStartedAt: Date | null;
  activationCounter: number;
  isReactivationNeeded: boolean;
  isActivationAttemptsRemaining: boolean;
  status: SubscriptionActivationStatus | null;
  isSubscriptionActivationScreenShown: boolean;
}

export type SubscriptionCreator = {
  type: SubscriptionActorType;
} & RequireAtLeastOne<{
  id: string;
  name: string;
}>;

export type SubscriptionActivator = {
  type: Exclude<SubscriptionActorType, SubscriptionActorType.Partner>;
} & RequireAtLeastOne<{
  id: string;
  name: string;
}>;

export type SubscriptionActivation = {
  activator: SubscriptionActivator;
  actionDate: Date;
  isActivate: boolean;
  ownershipId: string;
};

export enum SubscriptionStatus {
  Completed = 'completed',
  Active = 'active',
  Paid = 'paid',
  NotActive = 'not_active',
}

export type SubscriptionEntity = {
  readonly id: string;
  //TODO: Нужно ограничить изменение тарифа
  tariffId: string;
  offerId: string | null;
  ownershipId: string | null;
  vin: string;
  readonly invoiceId: string | null;
  startDate: Date | null;
  endDate: Date | null;
  actualEndDate: Date | null;
  activateUntil: Date | null;
  isActive: boolean;
  isGifted: boolean;
  activationProcess: SubscriptionActivationProcess;
  readonly status: SubscriptionStatus;
  activations: SubscriptionActivation[];
  createdBy: SubscriptionCreator;
  readonly createdAt: Date;
  updatedAt: Date;
  tariff?: TariffEntity;
  offer?: OfferEntity;
  ownership?: OwnershipEntity;
  invoice?: InvoiceEntity;
  vehicle?: VehicleEntity;
};

export type SubscriptionEntityRelationKey = keyof Pick<
  SubscriptionEntity,
  'invoice' | 'tariff' | 'offer' | 'ownership' | 'vehicle'
>;
export type SubscriptionEntityRelations = SubscriptionEntityRelationKey[] | 'all';
