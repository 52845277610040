import { FindParams } from '@store/backend-common';

import { EClimateFunction } from '../devices';

export interface AutostartTimerFields {
  /**
   * Дни недели, от 0 до 7, 0 и 7 - воскресенье
   */
  days: number[];
  /**
   * Час запуска, указывается в локальном времени
   */
  hour: number;
  /**
   * Минута запуска
   */
  minute: number;
  /**
   * Таймзона
   */
  timezone: number;
}

export type AutostartTimerEntity = {
  /** id таймера */
  id: string;
  /** vin авто */
  vin: string;
  /** id клиента */
  clientId: string;
  /** активирован ли таймер */
  active: boolean;
  /** Cron выражение */
  cronExpression: string;
  /** Дата следующей активации */
  nextActivation: Date;
  /** Дата создания команды */
  readonly createdAt: Date;
  /** Дата обновления команды */
  updatedAt: Date;
};

export type AutostartSettingsEntity = {
  /** vin авто */
  vin: string;
  /** id клиента */
  clientId: string;
  /** Активны ли настройки */
  active: boolean;
  /** Настройки климата */
  climate: AutostartTimerClimate[];
  /** Дата создания команды */
  readonly createdAt: Date;
  /** Дата обновления команды */
  updatedAt: Date;
};

export type AutostartSettingsUpdateInput = {
  /** Настройки климата */
  climate: AutostartTimerClimate[];
  vin: string;
};

export type AutostartSettingsWithClientUpdateInput = AutostartSettingsUpdateInput & {
  clientId: string;
};

export interface AutostartTimerClimate {
  climateFunction: EClimateFunction;
  value: number;
}

export type AutostartLogEntity = {
  /** id таймера */
  id: string;
  /** vin авто */
  vin: string;
  /** id клиента */
  clientId: string;
  /** активирован ли таймер */
  fromTimer: boolean;
  /** Cron выражение */
  success: boolean;
  /** Дата создания команды */
  readonly createdAt: Date;
};

export type AutostartTimerCreateInput = AutostartTimerFields & {
  vin: string;
  active: boolean;
};

export type AutostartTimerWithClientCreateInput = AutostartTimerCreateInput & {
  clientId: string;
};

export type AutostartTimerUpdateInput = AutostartTimerFields & {
  active: boolean;
  timerId: string;
};

export interface AutostartLogFilters {
  vin: string;
}

export type AutostartLogFindParams = FindParams<AutostartLogFilters>;

export type AutostartTimerExtendedEntity = AutostartTimerEntity & AutostartTimerFields;
