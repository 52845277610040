import { DefaultAction } from './default-action.enum';

export enum UniqueCkadActions {
  synchronizeCarCkads = 'synchronizeCarCkads',
}

export const CkadAction = {
  ...DefaultAction,
  ...UniqueCkadActions,
};
