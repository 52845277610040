import { ECommandType } from '../devices';

export type PartnerEntity = {
  readonly id: string;
  readonly name: string;
  readonly login: string;
  email: string;
  apiKey: string;
  readonly createdAt: Date;
  updatedAt: Date;
  active: boolean;
  availablePartners: Pick<PartnerEntity, 'id' | 'name'>[];
  restrictedDeviceCommands: ECommandType[];
};
