import { FacilitiesFacilityActivatedOutboxEntity } from './facilities-facility-activated-outbox.entity';
import { FacilitiesFacilityCreatedOutboxEntity } from './facilities-facility-created-outbox.entity';
import { FacilitiesFacilityDeactivatedOutboxEntity } from './facilities-facility-deactivated-outbox.entity';
import { FacilitiesFacilityUpdatedOutboxEntity } from './facilities-facility-updated-outbox.entity';

export type FacilitiesFacilityOutboxEntities =
  | FacilitiesFacilityCreatedOutboxEntity
  | FacilitiesFacilityUpdatedOutboxEntity
  | FacilitiesFacilityActivatedOutboxEntity
  | FacilitiesFacilityDeactivatedOutboxEntity;

export * from './facilities-facility-outbox-event-type.enum';
export * from './facilities-facility-created-outbox.entity';
export * from './facilities-facility-updated-outbox.entity';
export * from './facilities-facility-activated-outbox.entity';
export * from './facilities-facility-deactivated-outbox.entity';
