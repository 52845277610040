import { I18n } from '@store/backend-common';

import { DealerEntity } from './dealer.entity';

export enum ToLeadStatusEnum {
  /** Новая */
  New = 'NEW',
  /** Ожидает подтверждения */
  AwaitingConfirmation = 'AWAITING_CONFIRMATION',
  /** Отменена */
  Canceled = 'CANCELED',
  /** Подтверждена */
  Confirmed = 'CONFIRMED',
  /** Выполнена */
  Done = 'DONE',
  /** Отклоненная */
  Rejected = 'REJECTED',
  /** Ожидает оценки клиентом */
  WaitFeedback = 'WAIT_FEEDBACK',
  /** Завершенная(Успешно)*/
  Finished = 'FINISHED',
  /** Завершенная(отмененная) */
  Closed = 'CLOSED',
  /** Внутренняя ошибка */
  Failed = 'FAILED',
}

export enum TypeKppEnum {
  MECHANICAL = 'MECHANICAL',
  AUTOMATIC = 'AUTOMATIC',
  MACHINE = 'MACHINE', // старая версия обозначения автоматической КПП для обратной совместимости
}

/** Статусы заявок на сервис для действующих лиц (клиент, техподдержка, дилер) */
export enum ToLeadActorStatusEnum {
  /* Общие статусы (доступны нескольким участникам) */
  /** Новая */
  New = 'NEW',
  /** Отменена */
  Canceled = 'CANCELED',
  /** В работе */
  InProgress = 'IN_PROGRESS',
  /** Перезвонить */
  CallBack = 'CALL_BACK',
  /** Подтвержденная */
  Confirmed = 'CONFIRMED',
  /** Выполнена/готовая */
  Done = 'DONE',
  /** Отклоненная */
  Rejected = 'REJECTED',
  /** Отменена клиентом */
  CanceledByClient = 'CANCELED_BY_CLIENT',

  /* Статусы, доступные только клиенту в мобильном приложении */
  /** Заявка отправлена */
  Sent = 'SENT',
  /** Отменена оператором */
  CanceledBySupport = 'CANCELED_BY_SUPPORT',
  /** Ждет подтвержднения */
  AwaitingConfirmation = 'AWAITING_CONFIRMATION',
  /** Оценка клиентом */
  Rating = 'RATING',

  /* Статусы, доступные только для Техподдержки */
  /** Передана дилеру */
  PassedToDealer = 'PASSED_TO_DEALER',
  /** В работе у дилера */
  InProgressDealer = 'IN_PROGRESS_DEALER',
  /** Перезвонить (дилер клиенту) */
  CallBackByDealer = 'CALL_BACK_BY_DEALER',
  /** Отклоненная дилером */
  RejectedByDealer = 'REJECTED_BY_DEALER',
  /** На оценке у клиента */
  RatingByClient = 'RATING_BY_CLIENT',
  /** Внутренняя ошибка */
  Failed = 'FAILED',
}

export type ToLeadTypesEntity = {
  id: string;
  code: string;
  active: boolean;
  name: I18n;
  allDealer: boolean;
  updatedAt?: Date;
  createdAt?: Date;
};

export enum ToLeadSourceEnum {
  /* Внутренние сервисы */
  HML = 'hml',
  TES = 'TES',
  LIVAN = 'LIVAN',

  /* Сторонние сервисы */
  POLIS_GARANTII = 'polis_garantii', // партнер "Полис гарантии"
  MY_LIVAN = 'my_livan',

  /* MIR Hyundai */
  MIR = 'mir',
  SITE_HYUNDAI = 'site_hyundai',
}

export enum VehicleDeviceType {
  Lite = 'Lite',
  Pro = 'Pro',
  Upgrade = 'Upgrade',
}

export type ToLeadReviewDto = {
  id: number;

  /** id заявки на сервис */
  toLeadId: string;

  /** id клиента в сервисах tes */
  clientId: string | null;

  /** Оценка в отзыве */
  rating: number;

  /** Произвольный комментарий клиента к отзыву */
  comment: string | null;
};

export type MaintenanceEntity = {
  id: string;
  /** SAP код дилера */
  sapCode: string | null;
  /** VIN авто клиента */
  vin: string | null;
  /** Номер телефона клиента */
  phone: string;
  /** Статус */
  status: ToLeadStatusEnum;
  /** Подтвержденная дата ТО */
  confirmedDate: Date | null;
  /** Дата ТО по желанию клиента */
  requestedDate: Date | null;
  toWorks: Record<string, unknown> | null;
  /** Комментарий клиента */
  comments: string | null;
  /** Дата создания заявки */
  createdAt: Date;
  /** Дата обновления заявки */
  updatedAt: Date;
  /** Тип работ */
  type: ToLeadTypesEntity[] | null;
  /** Тип устройства */
  device: VehicleDeviceType | null;
  /** Марка авто клиента */
  brand: string | null;
  /** Модель авто клиента */
  model: string | null;
  /** Пробег на момент заявки */
  mileage: number | null;
  /** Тип последнего пройденного ТО */
  lastMaintenanceName: string | null;
  /** Дата последнего пройденного ТО */
  lastMaintenanceDate: Date | null;
  /**Год выпуска */
  productionYear: number | null;
  /** Тип КПП.  */
  typeKpp: TypeKppEnum | null;
  /**Имя пользователя */
  actorName: string | null;
  /**Клиент идентификатор */
  clientId: string | null;
  /**Машина идентификатор*/
  carId: string | null;
  /** Комментарий дилера */
  dealerComment: string | null;
  /** Название источника из которого пришла заявка */
  source: ToLeadSourceEnum | null;
  /** Статус заявки у техподдержки */
  supportStatus: ToLeadActorStatusEnum | null;
  /** Статус заявки у дилера */
  dealerStatus: ToLeadActorStatusEnum | null;
  /** Статус заявки у клиента */
  clientStatus: ToLeadActorStatusEnum | null;
  /** Текстовый комментарий от техподдержки */
  supportComment: string | null;
  /** Имя клиента */
  clientFirstName: string | null;
  /** Фамилия клиента */
  clientLastName: string | null;
  /** Данные о местоположении клиента (например город) */
  location: string | null;
  /** Назначенное время повторного звонка клиенту */
  callBackAt: Date | null;
  /** Дата отправки уведомления о звонке клиенту */
  callBackNotificationSentAt: Date | null;
  /** Название назначенного дилера */
  dealerName: string | null;
  /** Отзыв клиента о заявке на сервис */
  review: ToLeadReviewDto | null;
  /** Причина отклонения заявки (произвольный комментарий) */
  rejectReason: string | null;
  facilityOrderId: string | null;
  dealer?: DealerEntity;
};
