import { VehicleModelResponse } from '@store/amqp-contracts';

export interface SendVehicleModelWriteCommandParams {
  initiator: string;
  model: number;
}

export interface VehicleModelWriteCommandResponse {
  success: boolean;

  response?: VehicleModelResponse;
}
