import { FinesCkadCreatedOutboxEntity } from './fines-ckad-created-outbox.entity';
import { FinesCkadPaidOutboxEntity } from './fines-ckad-paid-outbox.entity';
import { FinesCkadProcessedOutboxEntity } from './fines-ckad-processed-outbox.entity';
import { FinesCkadUpdatedOutboxEntity } from './fines-ckad-updated-outbox.entity';

export type FinesCkadOutboxEntities =
  | FinesCkadCreatedOutboxEntity
  | FinesCkadUpdatedOutboxEntity
  | FinesCkadPaidOutboxEntity
  | FinesCkadProcessedOutboxEntity;

export * from './fines-ckad-created-outbox.entity';
export * from './fines-ckad-updated-outbox.entity';
export * from './fines-ckad-paid-outbox.entity';
export * from './fines-ckad-processed-outbox.entity';
export * from './fines-ckad-outbox-event-type.enum';
