import { Actor } from '@store/amqp-contracts';

export type TicketCommentEntity = {
  readonly id: string;
  readonly ticketId: string;
  message: string;
  actor: Actor;
  readonly createdAt: Date;
  updatedAt: Date;
};

export type TicketCommentEntityRelations = [] | 'all';
