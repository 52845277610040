import { EntityName } from '@store/backend-entity';

import { Models, UserRole } from '../enums';
import {
  brandPermissions,
  catalogRecordPermissions,
  cityPermissions,
  ckadPermissions,
  colorPermissions,
  configurationPermissions,
  cronConfigPermissions,
  customerPermissions,
  dashboardUploadHistoryPermissions,
  devicePermissions,
  deviceTestPermissions,
  equipmentPermissions,
  eventsHistoryPermissions,
  facilityPermissions,
  finePermissions,
  generationColorPermissions,
  generationPermissions,
  glossaryPermissions,
  invoicePermissions,
  modelPermissions,
  notificationPermissions,
  notificationTemplatePermissions,
  ownershipPermissions,
  partnerPermissions,
  paymentPermissions,
  providerPermissions,
  reportPermissions,
  simCardPermissions,
  storyPermissions,
  subscriptionalPaymentPermissions,
  subscriptionPermissions,
  tariffPermissions,
  ticketPermissions,
  userPermissions,
  vehiclePermissions,
} from './models-permissions';

import { Actions } from './actions.config';

function getRolePermissions(
  role: UserRole,
): { actions: Array<keyof typeof Actions>; subject: Models; fields?: Array<EntityName> }[] {
  return [
    ...brandPermissions[role],
    ...catalogRecordPermissions[role],
    ...ckadPermissions[role],
    ...colorPermissions[role],
    ...configurationPermissions[role],
    ...cronConfigPermissions[role],
    ...customerPermissions[role],
    ...devicePermissions[role],
    ...deviceTestPermissions[role],
    ...equipmentPermissions[role],
    ...eventsHistoryPermissions[role],
    ...facilityPermissions[role],
    ...finePermissions[role],
    ...generationColorPermissions[role],
    ...generationPermissions[role],
    ...invoicePermissions[role],
    ...modelPermissions[role],
    ...ownershipPermissions[role],
    ...partnerPermissions[role],
    ...providerPermissions[role],
    ...simCardPermissions[role],
    ...storyPermissions[role],
    ...subscriptionPermissions[role],
    ...tariffPermissions[role],
    ...ticketPermissions[role],
    ...userPermissions[role],
    ...vehiclePermissions[role],
    ...notificationPermissions[role],
    ...subscriptionalPaymentPermissions[role],
    ...glossaryPermissions[role],
    ...notificationTemplatePermissions[role],
    ...reportPermissions[role],
    ...dashboardUploadHistoryPermissions[role],
    ...paymentPermissions[role],
    ...cityPermissions[role],
  ];
}

export const adminPermissions = [
  ...getRolePermissions(UserRole.Admin),
  { actions: [Actions.manage], subject: Models.All },
];
export const operatorPermissions = getRolePermissions(UserRole.Operator);
export const operatorWithoutPBAccessPermissions = getRolePermissions(UserRole.OperatorWithoutPBAccess);
export const deviceOperatorPermissions = getRolePermissions(UserRole.DeviceOperator);
export const readOperatorPermissions = getRolePermissions(UserRole.OperatorReadAccess);
