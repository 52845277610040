import { EntityName } from '@store/backend-entity';

import { FacilityAction, Models, UserRole } from '../../enums';

export const facilityPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof FacilityAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    {
      actions: [
        FacilityAction.read,
        FacilityAction.create,
        FacilityAction.update,
        FacilityAction.activate,
        FacilityAction.deactivate,
      ],
      subject: Models.Facility,
    },
    { actions: [FacilityAction.read], subject: Models.Facilities },
    { actions: [FacilityAction.read, FacilityAction.create, FacilityAction.update], subject: Models.FacilityType },
    { actions: [FacilityAction.read], subject: Models.FacilityTypes },
    { actions: [FacilityAction.read], subject: Models.FacilityOrder },
    { actions: [FacilityAction.read], subject: Models.FacilityOrders },
    { actions: [FacilityAction.read], subject: Models.FacilityOrderPayments },
  ],

  [UserRole.Operator]: [
    { actions: [FacilityAction.read], subject: Models.Facility },
    { actions: [FacilityAction.read], subject: Models.Facilities },
    { actions: [FacilityAction.read], subject: Models.FacilityType },
    { actions: [FacilityAction.read], subject: Models.FacilityTypes },
    { actions: [FacilityAction.read], subject: Models.FacilityOrder },
    { actions: [FacilityAction.read], subject: Models.FacilityOrders },
    { actions: [FacilityAction.read], subject: Models.FacilityOrderPayments },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    { actions: [FacilityAction.read], subject: Models.Facility },
    { actions: [FacilityAction.read], subject: Models.Facilities },
    { actions: [FacilityAction.read], subject: Models.FacilityType },
    { actions: [FacilityAction.read], subject: Models.FacilityTypes },
    { actions: [FacilityAction.read], subject: Models.FacilityOrder },
    { actions: [FacilityAction.read], subject: Models.FacilityOrders },
    { actions: [FacilityAction.read], subject: Models.FacilityOrderPayments },
  ],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [
    { actions: [FacilityAction.read], subject: Models.Facility },
    { actions: [FacilityAction.read], subject: Models.Facilities },
    { actions: [FacilityAction.read], subject: Models.FacilityType },
    { actions: [FacilityAction.read], subject: Models.FacilityTypes },
    { actions: [FacilityAction.read], subject: Models.FacilityOrder },
    { actions: [FacilityAction.read], subject: Models.FacilityOrders },
    { actions: [FacilityAction.read], subject: Models.FacilityOrderPayments },
  ],
};
