import { DealerMaintenanceOutboxFinishedEntity } from './dealer-maintenance-outbox-finished.entity';
import { DealersMaintenanceOutboxDeletedEntity } from './dealers-maintenance-outbox-canceled.entity';
import { DealersMaintenanceOutboxConfirmedEntity } from './dealers-maintenance-outbox-confirmed.entity';
import { DealersMaintenanceOutboxCreatedEntity } from './dealers-maintenance-outbox-created.entity';
import { DealersMaintenanceOutboxRejectedEntity } from './dealers-maintenance-outbox-rejected.entity';
import { DealersMaintenanceOutboxUpdatedEntity } from './dealers-maintenance-outbox-updated.entity';
import { DealersMaintenanceTypeOutboxCreatedEntity } from './dealers-maintenance-type-outbox-created.entity';
import { DealersMaintenanceTypeOutboxUpdatedEntity } from './dealers-maintenance-type-outbox-updated.entity';

export type DealersMaintenanceOutboxEntities =
  | DealersMaintenanceOutboxDeletedEntity
  | DealersMaintenanceOutboxCreatedEntity
  | DealersMaintenanceTypeOutboxUpdatedEntity
  | DealersMaintenanceTypeOutboxCreatedEntity
  | DealersMaintenanceOutboxUpdatedEntity
  | DealerMaintenanceOutboxFinishedEntity
  | DealersMaintenanceOutboxConfirmedEntity
  | DealersMaintenanceOutboxRejectedEntity;

export * from './dealers-maintenance-outbox-event-type.enum';
export * from './dealers-maintenance-outbox-updated.entity';
export * from './dealers-maintenance-outbox-created.entity';
export * from './dealers-maintenance-outbox-canceled.entity';
export * from './dealers-maintenance-type-outbox-updated.entity';
export * from './dealers-maintenance-type-outbox-created.entity';
export * from './dealer-maintenance-outbox-finished.entity';
export * from './dealers-maintenance-outbox-confirmed.entity';
export * from './dealers-maintenance-outbox-rejected.entity';
