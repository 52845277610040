import { WidgetDataCkads } from './widget-data-ckads.interface';
import { WidgetDataDealer } from './widget-data-dealer.interface';
import { WidgetDataDrivers } from './widget-data-drivers.interface';
import { WidgetDataFines } from './widget-data-fines.interface';
import { WidgetDataGasStation } from './widget-data-gas-station.interface';
import { WidgetDataKasko } from './widget-data-kasko.interface';
import { WidgetDataMaintenance } from './widget-data-maintenance.interface';
import { WidgetDataMonitoring } from './widget-data-monitoring.interface';
import { WidgetDataOsago } from './widget-data-osago.interface';
import { WidgetDataRoadHelp } from './widget-data-road-help.interface';
import { WidgetDataStatistics } from './widget-data-statistics.interface';
import { WidgetDataSubscription } from './widget-data-subscription.interface';
import { WidgetDataTireService } from './widget-data-tire-service.interface';
import { WidgetDataVehicleInfo } from './widget-data-vehicle-info.interface';
import { WidgetDataVehicleSale } from './widget-data-vehicle-sale.interface';

export type WidgetData =
  | WidgetDataMonitoring
  | WidgetDataStatistics
  | WidgetDataDrivers
  | WidgetDataDealer
  | WidgetDataMaintenance
  | WidgetDataSubscription
  | WidgetDataFines
  | WidgetDataCkads
  | WidgetDataVehicleInfo
  | WidgetDataGasStation
  | WidgetDataKasko
  | WidgetDataOsago
  | WidgetDataRoadHelp
  | WidgetDataTireService
  | WidgetDataVehicleSale;

export * from './widget-data-monitoring.interface';
export * from './widget-data-statistics.interface';
export * from './widget-data-drivers.interface';
export * from './widget-data-dealer.interface';
export * from './widget-data-maintenance.interface';
export * from './widget-data-subscription.interface';
export * from './widget-data-ckads.interface';
export * from './widget-data-fines.interface';
export * from './widget-data-vehicle-info.interface';
export * from './counter-data.interface';
export * from './widget-data-gas-station.interface';
export * from './widget-data-kasko.interface';
export * from './widget-data-osago.interface';
export * from './widget-data-road-help.interface';
export * from './widget-data-tire-service.interface';
export * from './widget-data-vehicle-sale.interface';
