import { DeviceRepairTicketEntity } from './device-repair-ticket.entity';

export interface HistoryChange<K extends string = string, T = unknown> {
  /** Ключ поля */
  key: K;
  /** Старое значение */
  old: T;
  /** Новое значение */
  new: T;
}

export interface DeviceRepairTicketHistoryEntity {
  /** ID заявки */
  ticketId: string;
  /** Тип изменения */
  type: string;
  /** Название изменения */
  title: string;
  /** Изменения */
  changes: HistoryChange<keyof DeviceRepairTicketEntity | string>[];
  /** Дата изменения */
  date: Date;
  /** Тип актора */
  actorType: string;
  /** Идентификатор актора */
  actorIdent?: string;
}
