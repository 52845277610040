{
  "name": "crm-fe",
  "version": "1.7.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:dev": "ng serve --configuration local",
    "build": "NODE_ENV=prod node ./set-env.js && ng build",
    "build:dev": "NODE_ENV=dev node ./set-env.js && ng build --configuration development",
    "build:stage": "NODE_ENV=stage node ./set-env.js && ng build --configuration stage",
    "watch": "ng build --watch --configuration development",
    "lint": "ng lint",
    "prepare": "cd ../../ && husky install",
    "generate": "graphql-codegen --config graphql-codegen.ts"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.3",
    "@angular/cdk": "19.2.6",
    "@angular/common": "^19.2.3",
    "@angular/compiler": "^19.2.3",
    "@angular/core": "^19.2.3",
    "@angular/forms": "^19.2.3",
    "@angular/google-maps": "^19.2.6",
    "@angular/material": "19.2.6",
    "@angular/material-date-fns-adapter": "19.2.6",
    "@angular/platform-browser": "^19.2.3",
    "@angular/platform-browser-dynamic": "^19.2.3",
    "@angular/router": "^19.2.3",
    "@angular/service-worker": "^19.2.3",
    "@apollo/client": "^3.0.0",
    "@casl/ability": "~6.5.0",
    "@casl/angular": "~8.2.3",
    "@googlemaps/js-api-loader": "^1.16.2",
    "@ngrx/component-store": "16.3.0",
    "@ngxs/storage-plugin": "3.8.1",
    "@ngxs/store": "^3.8.1",
    "@store/backend-entity": "1.0.0",
    "@store/casl": "1.0.0",
    "apollo-angular": "^5.0.2",
    "cropperjs": "^1.6.2",
    "crypto-js": "^4.2.0",
    "date-fns": "^2.30.0",
    "extract-files": "^13.0.0",
    "graphql": "^16.8.1",
    "jsencrypt": "^3.3.2",
    "ngx-color-picker": "^16.0.0",
    "tslib": "^2.3.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.4",
    "@angular-eslint/builder": "19.3.0",
    "@angular-eslint/eslint-plugin": "19.3.0",
    "@angular-eslint/eslint-plugin-template": "19.3.0",
    "@angular-eslint/schematics": "19.3.0",
    "@angular-eslint/template-parser": "19.3.0",
    "@angular/cli": "^19.2.4",
    "@angular/compiler-cli": "^19.2.3",
    "@graphql-codegen/cli": "^5.0.0",
    "@graphql-codegen/near-operation-file-preset": "^3.0.0",
    "@graphql-codegen/typescript": "^4.0.1",
    "@graphql-codegen/typescript-apollo-angular": "^4.0.0",
    "@graphql-codegen/typescript-operations": "^4.0.1",
    "@types/crypto-js": "^4",
    "@types/extract-files": "^13",
    "@types/jasmine": "~4.3.0",
    "@typescript-eslint/types": "^8.28.0",
    "@typescript-eslint/utils": "^8.28.0",
    "graphql-sock": "^1.0.1",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.8.2"
  },
  "peerDependencies": {
    "eslint": ">= 8.52.0",
    "rxjs": ">= 7.8.2"
  },
  "lint-staged": {
    "*.ts": [
      "prettier --write",
      "eslint --fix"
    ]
  }
}
