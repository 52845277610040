import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthTokenStateActions } from '@app/core/auth/states/auth-token.state.actions';
import { Store } from '@ngxs/store';
import { concatMap, map, Observable, of, tap } from 'rxjs';
import { take } from 'rxjs/operators';

import { SKIP_TOKEN_INTERCEPTOR_HEADER } from '../consts/skip-token-interceptor-header.const';
import { AuthTokenState } from '../states/auth-token.state';

const SERVER_STATUS = 200;

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  public intercept(originalReq: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (originalReq.headers.has(SKIP_TOKEN_INTERCEPTOR_HEADER)) {
      const headers = originalReq.headers.delete(SKIP_TOKEN_INTERCEPTOR_HEADER);

      return next.handle(originalReq.clone({ headers }));
    }

    return this.store.select(AuthTokenState.accessToken).pipe(
      take(1),
      map((accessToken) => this.addToken(originalReq, accessToken)),
      concatMap((request) => next.handle(request)),
      concatMap((event) => {
        let needToAuthenticate = false;
        let isBlocked = false;

        if (
          event.type === HttpEventType.Response &&
          event.status === SERVER_STATUS &&
          event.body &&
          Array.isArray(event.body.errors)
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errors = event.body.errors as any[];
          needToAuthenticate = !!errors.find((e) => e.extensions && e.extensions.code === 'UNAUTHENTICATED');

          isBlocked = !!errors.find((e) => e.extensions && e.extensions.code === 'FORBIDDEN');
        }

        if (needToAuthenticate) {
          return this.store.dispatch(new AuthTokenStateActions.Refresh()).pipe(
            concatMap(() => this.store.select(AuthTokenState.accessToken).pipe(take(1))),
            tap((token) => {
              if (!token) {
                this.store.dispatch(new AuthTokenStateActions.Logout());
              }
            }),
            map((accessToken) => this.addToken(originalReq, accessToken)),
            concatMap((req) => next.handle(req)),
          );
        }

        if (isBlocked) {
          this.store.dispatch(new AuthTokenStateActions.Logout());
        }

        return of(event);
      }),
    );
  }

  protected addToken(req: HttpRequest<unknown>, token: string | null): HttpRequest<unknown> {
    const alreadyHasToken = !!req.headers.get('authorization');

    return !token || alreadyHasToken ? req : req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
}
