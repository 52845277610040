export interface StatisticsInfo {
  /** Доступность статистики */
  availability: StatisticsInfoAvailability;
  /** Информация по стилю вождения */
  drivingStyleData: StatisticsDrivingStyleData;
  /** Информация по пробегу */
  mileageData: StatisticsMileageData;
  /** Информация по расходу топлива */
  fuel: StatisticsFuelData;
  /** Информация по двигателю */
  engineData: StatisticsEngineData;
  /** Информация для графика по среднему времени в пути по дням */
  driveTimeAvg: StatisticsDriveTimeAverage;
  /** Информация по скорости max/avg по дням */
  driveTimeSpeed: StatisticsDriveTimeSpeed;
}

export interface StatisticsInfoAvailability {
  /** Доступно или нет */
  available: boolean;
  /** Текст в случае недоступности */
  text: string | null;
}

export interface StatisticsFuelData {
  /** Общее количество затраченного топлива */
  totalFuel: number;
  /** Массив элементов затраченного топлива */
  fuelList: {
    /** Дата в ISO */
    date: Date;
    /** Количество потраченного топлива, л */
    fuel: number;
  }[];
}

export interface StatisticsDrivingStyleData {
  /** Количество баллов */
  score: number | null;
}

export interface StatisticsMileageData {
  /** Общий пробег */
  mileageTotal: number;
  /** Пробег в городе */
  mileageCity: number;
  /** Пробег на трассе */
  mileageTrack: number;
  mileageList?: {
    /** Дата в ISO */
    date: Date;
    /** Пробег в городе */
    mileageCity: number;
    /** Пробег на трассе */
    mileageTrack: number;
  }[];
}

export interface StatisticsEngineData {
  /** Общее время работы двигателя */
  runTimeTotal: number;
  /** Время работы двигателя в движении */
  runTimeMoving: number;
  /** Время работы двигателя без движения */
  runTimeNoMoving: number;
  runTimeList?: {
    /** Дата в ISO */
    date: Date;
    /** Общее время работы двигателя */
    runTimeTotal: number;
    /** Время работы двигателя в движении */
    runTimeMoving: number;
    /** Время работы двигателя без движения */
    runTimeNoMoving: number;
  }[];
}

export interface StatisticsSpeedData {
  /** Средняя скорость */
  speedAvg: number;
  /** Максимальная скорость */
  speedMax: number;
}

/** Информация по поездке за день */
export interface StatisticsTripTime {
  /** Дата поездки в ISO */
  date: Date;
  /** Время поездки в миллисекундах */
  duration: number;
  /** Количество поездок */
  tripCount: number;
}

export interface StatisticsDriveTimeAverage {
  /** Среднее время в пути за период */
  driveTimeAverage: number;
  /** Массив элементов времени в пути по дням */
  tripTimeList: StatisticsTripTime[];
}

/** Информация по поездке за день */
export interface StatisticsTripSpeed {
  /** Дата поездки в ISO */
  date: Date;
  /** Средняя скорость */
  speedAvg: number;
  /** Максимальная скорость */
  speedMax: number;
}

export interface StatisticsDriveTimeSpeed {
  /** max/avg скорости за период */
  speed: StatisticsSpeedData;
  /** Массив элементов max/avg скорости по дням */
  tripSpeedList: StatisticsTripSpeed[];
}
