// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentInterface } from './environment.interface';

export const environment: EnvironmentInterface = {
  production: false,
  showDevMessage: true,
  graphql: 'https://api.dev.tes.store/store-crm-bff/graphql',
  googleMapsApiKey: 'AIzaSyAerLzDqTIFBUmSR0GeyEoD1TVfEEn1V7U',
  isLocal: false,
  ednaChatPublicKey: `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDC+GQVJf+cTnJRx5qWrUxbL2FhIJpc8ppsdegy+qlsSEG3w7X/xdDM86CyWCJnCbwlUJrj7qfYhIgt26zdJTR5UTO878oZoCHJ7nIFrmREg7xQ4SuDVOpMgCeulK1J1EZHrzG94MkrbP8lQPYT5HQJHu8JS2xHdUW+aI4ZBF85KQIDAQAB
-----END PUBLIC KEY-----`,
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
