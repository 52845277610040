import { PaymentOrderEntity } from './payment-order.entity';

export type MerchantEntity = {
  readonly id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string | null;
  description: string | null;
  provider: PaymentOrderEntity['provider'];
  sector: string;
  password: string;
};
