import { ControlCenterTariffCreatedOutboxEntity } from './control-center-tariff-created-outbox.entity';
import { ControlCenterTariffDeletedOutboxEntity } from './control-center-tariff-deleted-outbox.entity';
import { ControlCenterTariffUpdatedOutboxEntity } from './control-center-tariff-updated-outbox.entity';

export type ControlCenterTariffOutboxEntities =
  | ControlCenterTariffCreatedOutboxEntity
  | ControlCenterTariffDeletedOutboxEntity
  | ControlCenterTariffUpdatedOutboxEntity;

export * from './control-center-tariff-outbox-event-type.enum';
export * from './control-center-tariff-created-outbox.entity';
export * from './control-center-tariff-deleted-outbox.entity';
export * from './control-center-tariff-updated-outbox.entity';
