import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

export type FlatButtonColor = 'primary' | 'secondary' | 'transparent';
export type FlatButtonSize = 'small' | 'medium' | 'large';
export type FlatButtonAlign = 'start' | 'center' | 'end';

@Component({
  selector: 'button[app-flat-button]',
  templateUrl: './flat-button.component.html',
  styleUrls: ['./flat-button.component.scss'],
  exportAs: 'appFlatButton',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlatButtonComponent {
  @HostBinding('class.app-flat-button') baseClass = true;

  @Input() color: FlatButtonColor = 'primary';

  @Input() size: FlatButtonSize = 'medium';

  @Input() noPadding = false;

  @HostBinding('class')
  public get classes(): string[] {
    return [`_${this.color}`, `_${this.size}`, this.noPadding ? '_no-padding' : ''];
  }
}

@Component({
  selector: 'a[app-flat-button]',
  templateUrl: './flat-button.component.html',
  styleUrls: ['./flat-button.component.scss'],
  exportAs: 'appFlatButton',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlatButtonAnchorComponent {
  @HostBinding('class.app-flat-button') baseClass = true;

  @Input() color: FlatButtonColor = 'primary';

  @Input() size: FlatButtonSize = 'medium';

  @Input() noPadding = false;

  @HostBinding('class')
  public get classes(): string[] {
    return [`_${this.color}`, `_${this.size}`, this.noPadding ? '_no-padding' : ''];
  }
}
