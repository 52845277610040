import { I18n } from '@store/backend-common';

import { AccessType, DeviceType } from '../control-center';

import { ConditionField } from './interfaces';
import { StoryPageEntity } from './story-page.entity';

export enum TitleAlign {
  Auto = 'auto',
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Justify = 'justify',
}

export enum StoryStatus {
  Draft = 'Draft',
  Published = 'Published',
  Inactive = 'Inactive',
}

export interface StoryConditions {
  partnerId?: ConditionField<string>;
  vehicleBrand?: ConditionField<string>;
  vehicleModel?: ConditionField<string>;
  activeSubscription?: ConditionField<boolean>;
  ownershipAccessType?: ConditionField<AccessType>;
  vehicleDeviceType?: ConditionField<DeviceType>;
}

export type StoryEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Статус истории */
  status: StoryStatus;
  /** Заголовок */
  title: I18n;
  /** Цвет заголовка hex */
  titleColor: string | null;
  /** Цвет фона hex */
  backgroundColor: string | null;
  /** Путь фонового изображения */
  backgroundImagePath: string | null;
  /** Позиция истории в списке */
  position: number;
  /** Условия доступности истории
   * [ {AND} OR {AND} OR ... ]
   */
  conditions: StoryConditions[];
  /** Открытие истории при запуске приложения  */
  isOpeningOnStart: boolean;
  /** Дата публикации */
  publicationDate: Date | null;
  /** Дата завершения публикации */
  finishDate: Date | null;

  /** Идентификатор оператора */
  operatorId: string;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
  /** Страницы истории */
  pages?: StoryPageEntity[];
};

export type StoryEntityRelations = [] | 'all';
