import { EntityName } from '@store/backend-entity';

import { Models, UserAction, UserRole } from '../../enums';

export const userPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof UserAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    {
      actions: [
        UserAction.read,
        UserAction.create,
        UserAction.update,
        UserAction.resetPassword,
        UserAction.block,
        UserAction.activate,
      ],
      subject: Models.User,
    },
    {
      actions: [UserAction.read],
      subject: Models.Users,
    },
  ],

  [UserRole.Operator]: [],

  [UserRole.OperatorWithoutPBAccess]: [],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [],
};
