import { EntityName } from '@store/backend-entity';

import { Models, TicketAction, UserRole } from '../../enums';

export const ticketPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof TicketAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    {
      actions: [TicketAction.read, TicketAction.changeStatus, TicketAction.createComment, TicketAction.update],
      subject: Models.Ticket,
    },
    { actions: [TicketAction.read], subject: Models.Tickets },
  ],

  [UserRole.Operator]: [
    {
      actions: [TicketAction.read, TicketAction.changeStatus, TicketAction.createComment, TicketAction.update],
      subject: Models.Ticket,
    },
    { actions: [TicketAction.read], subject: Models.Tickets },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    {
      actions: [TicketAction.read, TicketAction.changeStatus, TicketAction.createComment, TicketAction.update],
      subject: Models.Ticket,
    },
    { actions: [TicketAction.read], subject: Models.Tickets },
  ],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [
    { actions: [TicketAction.read], subject: Models.Ticket },
    { actions: [TicketAction.read], subject: Models.Tickets },
  ],
};
