import { FacilitiesProviderCreatedOutboxEntity } from './facilities-provider-created-outbox.entity';
import { FacilitiesProviderUpdatedOutboxEntity } from './facilities-provider-updated-outbox.entity';

export type FacilitiesProviderOutboxEntities =
  | FacilitiesProviderCreatedOutboxEntity
  | FacilitiesProviderUpdatedOutboxEntity;

export * from './facilities-provider-outbox-event-type.enum';
export * from './facilities-provider-created-outbox.entity';
export * from './facilities-provider-updated-outbox.entity';
