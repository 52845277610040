import { DefaultAction } from './default-action.enum';

export enum UniqueTicketActions {
  changeStatus = 'changeStatus',
  createComment = 'createComment',
}

export const TicketAction = {
  ...DefaultAction,
  ...UniqueTicketActions,
};
