export interface TechnicalEquipment {
  /** Тип задних тормозов */
  backBrake: string | null;
  /** Тип питания (имеется ввиду есть ли в двигателе турбонаддув - турбина) */
  feeding: string | null;
  /** Лошадиные силы */
  horsePower: number | null;
  /** Мощность в киловаттах */
  kwtPower: number | null;
  /** Обороты в минуту мощности */
  rpmPower: number | null;
  /** Тип двигателя */
  engineType: string | null;
  /** Трансмиссия */
  transmission: string | null;
  /** Привод */
  drive: string | null;
  /** Объем двигателя */
  volume: number | null;
  /** Время разгона до 100 км/ч */
  timeTo100: number | null;
  /** Порядок цилиндров */
  cylindersOrder: string | null;
  /** Максимальная скорость */
  maxSpeed: number | null;
  /** Сжатие (компрессия на цилиндр) */
  compression: number | null;
  /** Количество цилиндров */
  cylindersValue: number | null;
  /** Диаметр поршня */
  diameter: number | null;
  /** Ход поршня */
  pistonStroke: number | null;
  /** Питание двигателя (тип подачи топлива -  например непосредственный впрыск) */
  engineFeeding: string | null;
  /** Расположение двигателя */
  engineOrder: string | null;
  /** Количество передач в КПП */
  gearValue: number | null;
  /** Крутящий момент */
  moment: number | null;
  /** Тип топлива */
  fuelType: string | null;
  /** Клапаны */
  valves: number | null;
  /** Вес */
  weight: number | null;
  /** Размер колеса */
  wheelSize: string | null;
  /** Колесная база */
  wheelBase: number | null;
  /** Передняя колесная база */
  frontWheelBase: number | null;
  /** Задняя колесная база */
  backWheelBase: number | null;
  /** Тип передних тормозов */
  frontBrake: string | null;
  /** Тип передней подвески */
  frontSuspension: string | null;
  /** Тип задней подвески */
  backSuspension: string | null;
  /** Высота */
  height: number | null;
  /** Ширина */
  width: number | null;
  /** Объем топливного бака */
  fuelTankCapacity: number | null;
  /** Количество сидений */
  seats: number | null;
  /** Длина */
  length: number | null;
  /** Класс выбросов по евростандарту */
  emissionEuroClass: string | null;
  /** Объем в литрах (двигателя) */
  volumeLitres: number | null;
  /** Смешанный расход топлива */
  consumptionMixed: number | null;
  /** Дорожный просвет */
  clearance: number | null;
  /** Минимальная вместимость багажника */
  trunksMinCapacity: number | null;
  /** Максимальная вместимость багажника */
  trunksMaxCapacity: number | null;
  /** Расход топлива на трассе */
  consumptionHighway: number | null;
  /** Расход топлива в городе */
  consumptionCity: number | null;
  /** Обороты в минуту момента */
  momentRpm: number | null;
  /** Полный вес */
  fullWeight: number | null;
  /** Запас хода */
  rangeDistance: number | null;
  /** Емкость аккумулятора */
  batteryCapacity: number | null;
  /** Выбросы топлива */
  fuelEmission: number | null;
  /** Запас хода на электричестве */
  electricRange: number | null;
  /** Время зарядки */
  chargeTime: number | null;
  /** Расположение руля */
  steeringWheelLocation: string | null;
}
