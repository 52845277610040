export type HttpLogEntity = {
  readonly id: string;
  orderId: string | null;
  url: string;
  method: string;
  createdAt: Date;
  requestBody: Record<string, string> | null;
  responseBody: Record<string, string> | null;
  header: Record<string, string> | null;
  httpStatus: number | null;
};
