import { I18n } from '@store/backend-common';

import { EClimateFunction } from '../enums';

export interface SendClimateCommandParams {
  initiator: string;
  climateFunction: EClimateFunction;
  value: number;
}

export interface ClimateCommandResponse {
  success: boolean;
  /** Локализация успеха */
  successLocalization: I18n | null;
  /** Локализация статуса */
  errorLocalization: I18n | null;
}
