import { DefaultAction } from './default-action.enum';

export enum UniqueSubscriptionActions {
  addGiftedSubscription = 'addGiftedSubscription',
}

export const SubscriptionAction = {
  ...DefaultAction,
  ...UniqueSubscriptionActions,
};
