import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { StateClass } from '@ngxs/store/internals';

import { AuthProfileState } from './auth-profile.state';
import { AuthTokenState } from './auth-token.state';

@State({
  name: 'auth',
  children: [AuthProfileState, AuthTokenState] as StateClass[],
})
@Injectable()
export class AuthState {}
