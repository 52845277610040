import { I18n } from '@store/backend-common';

import { ModelEntity } from './model.entity';

import { ExternalIds } from './interfaces';

export type GenerationEntity = {
  /** Идентификатор поколения */
  readonly id: string;
  /** Идентификатор модели */
  readonly modelId: string;
  /** Внешние идентификаторы источников */
  externalIds: ExternalIds;
  /** Наименования на разных языках */
  name: I18n;
  /** Год начала производства поколения */
  yearStart: number | null;
  /** Год окончания производства поколения */
  yearStop: number | null;
  /** Является ли поколение рестайлингом */
  isRestyle: boolean;
  model?: ModelEntity;
  readonly createdAt: Date;
  updatedAt: Date;
  /** Массив путей к рендерам */
  render: string[];
};

export type GenerationEntityRelationKey = keyof Pick<GenerationEntity, 'model'>;
export type GenerationEntityRelations = GenerationEntityRelationKey[] | 'all';
