import { BrandEntity } from './brand.entity';
import { EquipmentEntity } from './equipment.entity';
import { GenerationEntity } from './generation.entity';
import { ModelEntity } from './model.entity';

import { ConfigurationEntity } from './configuration.entity';

export type CatalogRecordEntity = {
  /** Идентификатор записи */
  readonly id: string;
  /** Идентификатор бренда */
  readonly brandId: string;
  /** Идентификатор модели */
  readonly modelId: string;
  /** Идентификатор поколения */
  readonly generationId: string;
  /** Сгенерированный код каталога для данной записи */
  readonly code: string;
  /** Идентификатор конфигурации */
  configurationId: string | null;
  /** Идентификатор комплектации */
  equipmentId: string | null;
  /** Массив путей к рендерам */
  render: string[];
  readonly createdAt: Date;
  updatedAt: Date;
  brand?: BrandEntity;
  model?: ModelEntity;
  generation?: GenerationEntity;
  configuration?: ConfigurationEntity;
  equipment?: EquipmentEntity;
};

export type CatalogRecordEntityRelationKey = keyof Pick<
  CatalogRecordEntity,
  'brand' | 'model' | 'generation' | 'configuration' | 'equipment'
>;
export type CatalogRecordEntityRelations = CatalogRecordEntityRelationKey[] | 'all';
