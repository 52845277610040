import { EntityName } from '@store/backend-entity';

import { DefaultAction, Models, UserRole } from '../../enums';

const Actions = {
  ...DefaultAction,
};

export const subscriptionalPaymentPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof Actions>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    { actions: [Actions.read], subject: Models.SubscriptionPayment },
    { actions: [Actions.read], subject: Models.SubscriptionPayments },
  ],

  [UserRole.Operator]: [
    { actions: [Actions.read], subject: Models.SubscriptionPayment },
    { actions: [Actions.read], subject: Models.SubscriptionPayments },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    { actions: [Actions.read], subject: Models.SubscriptionPayment },
    { actions: [Actions.read], subject: Models.SubscriptionPayments },
  ],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [
    { actions: [Actions.read], subject: Models.SubscriptionPayment },
    { actions: [Actions.read], subject: Models.SubscriptionPayments },
  ],
};
