import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

const MAX_HISTORY_LENGTH = 2;

@Injectable({ providedIn: 'root' })
export class NavigationHistoryService {
  private history: string[] = [];

  constructor(private readonly router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.pushHistory(event.urlAfterRedirects);
      }
    });
  }

  public getPrevHistoryUrl(): string | null {
    return this.history[this.history.length - 2] || null;
  }

  protected pushHistory(value: string): void {
    this.history.push(value);

    if (this.history.length > MAX_HISTORY_LENGTH) {
      this.history = this.history.slice(-MAX_HISTORY_LENGTH);
    }
  }
}
