export enum CardType {
  Mir = 'Mir',
  Visa = 'Visa',
  MasterCard = 'MasterCard',
  Unknown = 'Unknown',
}

export type CardEntity = {
  readonly id: string;
  readonly customerId: string;
  createdAt: Date;
  updatedAt: Date;
  cardNumber: string | null;
  cardExpiredDate: Date | null;
  token: string | null;
  activationOrderId: string | null;
  type: CardType;
  originator: string | null;
};
