import { I18n, TimeUnit } from '@store/backend-common';

import { FacilityCode } from '../facilities';

export interface WidgetConfigSortOrder {
  importantLevelOrder?: number;
  facilityTypeLevelOrder?: number;
}

export interface WidgetConfigTtl {
  amount: number;
  unit: TimeUnit;
}

/** Конфигурация виджета */
export type WidgetConfigEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Код услуги */
  readonly code: FacilityCode;
  /** Заголовок виджета */
  title: I18n | null;
  /** Путь к изображению виджета */
  imagePath: string | null;
  /** Флаг возможности попадания в важное */
  isImportantly: boolean;
  /** Объект порядка при сортировке */
  sortOrder: WidgetConfigSortOrder;
  /** Флаг доступности конфигурации */
  isAvailable: boolean;
  /** Время жизни данных в готовом виджете */
  ttl: WidgetConfigTtl | null;
  /** Идентификатор родительского виджета */
  parentId: string | null;
  /** Идентификатор партнёра */
  partnerId: string | null;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
  /** Флаг доступности только владельцу */
  isOwnerOnly: boolean;
  /** Всегда ли виджет должен висеть в важном */
  isAlwaysImportant: boolean;
  /** Флаг скрытия виджета */
  hide: boolean;

  /** Сущность родительского виджета */
  parent?: WidgetConfigEntity;
};

export type WidgetConfigEntityRelationKey = keyof Pick<WidgetConfigEntity, 'parent'>;
export type WidgetConfigEntityRelations = WidgetConfigEntityRelationKey[] | 'all';
