export enum MaintenanceServiceTypeEnum {
  MAINTENANCE = 'Maintenance',
  GENERAL_REPAIR = 'General_Repair',
  TIRE_SERVICE = 'Tire_Service',
  OTHER = 'Other',
}

export enum MaintenanceSourceEnum {
  WA = 'Workshop_Automation',
  TES = 'Tes',
  MOBIKEY = 'Mobikey',
  DEALER_CRM = 'Dealer_CRM',
  LIVAN = 'LIVAN',
}

export type ServiceBookEntity = {
  readonly id: string;
  readonly externalId: number;
  /** VIN автомобиля */
  vin: string;

  /** Дата прохождения ТО */
  date: Date;

  /** Тип ТО */
  name: string | null;

  /** Комментарий из внешних источников */
  externalComment: string | null;

  /** Пробег на дату проведения ТО */
  maintenanceMileage: number | null;

  /** SAP код дилера, у которого проводился ТО */
  sapCode: string | null;

  /** Полная стоимость проведенного ТО */
  totalPrice: number | null;

  /** Вид проведённых работ */
  serviceType: MaintenanceServiceTypeEnum;

  /** Название организации, проводившей работу (неофициальные сервисные центры) */
  dealerName: string | null;

  /** Адрес организации, проводившей работу (неофициальные сервисные центры) */
  dealerAddress: string | null;

  /** Источник добавления записи */
  source: MaintenanceSourceEnum | null;

  createdAt: Date;
  updatedAt: Date;
};
