export type CronConfigEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Уникальный код Cron */
  readonly ident: string;
  /** Описание */
  description: string;
  /** Признак активности  */
  active: boolean;
  /** Cron паттерн */
  cronPattern: string;
  /** Дата последнего запуска */
  lastRunAt: Date | null;
  /** Дата следующего запуска */
  nextRunAt: Date | null;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
};
