import { FacilitiesFacilityTypeActivatedOutboxEntity } from './facilities-facility-type-activated-outbox.entity';
import { FacilitiesFacilityTypeCreatedOutboxEntity } from './facilities-facility-type-created-outbox.entity';
import { FacilitiesFacilityTypeDeactivatedOutboxEntity } from './facilities-facility-type-deactivated-outbox.entity';
import { FacilitiesFacilityTypeUpdatedOutboxEntity } from './facilities-facility-type-updated-outbox.entity';

export type FacilitiesFacilityTypeOutboxEntities =
  | FacilitiesFacilityTypeCreatedOutboxEntity
  | FacilitiesFacilityTypeUpdatedOutboxEntity
  | FacilitiesFacilityTypeActivatedOutboxEntity
  | FacilitiesFacilityTypeDeactivatedOutboxEntity;

export * from './facilities-facility-type-outbox-event-type.enum';
export * from './facilities-facility-type-created-outbox.entity';
export * from './facilities-facility-type-updated-outbox.entity';
export * from './facilities-facility-type-activated-outbox.entity';
export * from './facilities-facility-type-deactivated-outbox.entity';
