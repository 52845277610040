import { ECommandType } from '../enums';

export interface LastPacketClimate {
  /** Управление климатом, -1 - нет информации */
  climateControl: number;
  /** Режим Auto, -1 - нет информации */
  autoMode: number;
  /** Статус кондиционер, -1 - нет информации */
  conditioner: number;
  /** Забор воздуха: рециркуляция (0), с улицы (1), -1 - нет информации */
  recirculate: number;
  /** Обогрев лобового, -1 - нет информации */
  frontWindowHeating: number;
  /** Обогрев заднего, -1 - нет информации */
  rearWindowHeating: number;
  /** Обдув стекол, -1 - нет информации */
  windowBlowing: number;
  /** PTC/Positive Temperature Coefficient/ Вспомогательный электрический отопитель, -1 - нет информации */
  ptc: number;
  /** Ионизация, -1 - нет информации */
  ionization: number;
  /** Температура воздуха 15.5 - LO, 16..32, 32.5 - HI, -1 - нет данных */
  temperatureControl: number;
  /** Скорость вентилятора 1..8, -1 - нет данных */
  fanSpeedControl: number;
  /** Направление обдува (0-в лицо, 1-в лицо и ноги, 2-в ноги, 3-в ноги и на стекло), -1 - нет данных */
  ventilationDirection: number;
  /** Обдув сидений, -1 - нет информации */
  seatAirFlow: number;
  /** Разморозка, -1 - нет информации */
  defroster: number;
  /** Обогрев водительского сиденья, -1 - нет информации */
  driverSeatHeating: number;
  /** Обогрев переднего пассажирского сиденья, -1 - нет информации */
  passengerSeatHeating: number;
  /** Обогрев заднего левого пассажирского сиденья, -1 - нет информации */
  rearLeftPassengerSeatHeating: number;
  /** Обогрев заднего правого пассажирского сиденья, -1 - нет информации */
  rearRightPassengerSeatHeating: number;
}

export interface LastPacket {
  /** Широта */
  latitude: number;
  /** Долгота */
  longitude: number;
  /** Высота, м */
  height: number;
  /**
   * Значение в условных единицах 1 у.е. = Градусы/180 * 128, 0 = восток, против часовой стрелки
   * Например значение 64 = 90 градусов против часовой, т.е. север
   */
  angle: number;
  /** Скорость, км/ч */
  speed: number;
  /** Дата создания пакета данных */
  packetTime: Date;
  /** Дата получения пакета данных сервером */
  packetReceiveTime: Date;
  /** Процент топлива, от 0 до 100  */
  fuelPercent: number;
  /** Количество топлива, л */
  fuelLiters: number;
  /** Пробег, км */
  mileage: number;
  /** Зажигание */
  ignition: boolean;
  /** Стиль вождения */
  drivingStyle: number;
  /** Подключено ли устройство к серверу */
  connected: boolean;
  /** Напряжение аккумулятора, В */
  batteryVoltage: number | null;
  /** @deprecated Открыта ли любая дверь */
  isDoorsOpened: boolean;
  /** Открыт ли Центральный Замок */
  isCentralLockOpen: boolean;
  /** Открыта ли водительская дверь */
  isDriverDoorOpen: boolean;
  /** Открыта ли передняя пассажирская дверь */
  isFrontPassengerDoorOpen: boolean;
  /** Открыта ли одна из задних пассажирских дверей */
  isAnyRearDoorOpen: boolean;
  /** Открыта ли задняя правая пассажирская дверь */
  isRearRightDoorOpen: boolean;
  /** Открыта ли задняя левая пассажирская дверь */
  isRearLeftDoorOpen: boolean;
  /** Включены ли фары ближнего света */
  areLowBeamHeadlightsOn: boolean;
  /** Включены ли фары дальнего света */
  areHighBeamHeadlightsOn: boolean;
  /** Включены ли ходовые огни */
  areParkingLightsOn: boolean;
  /** Открыт ли капот автомобиля */
  isHoodOpened: boolean;
  /** Открыт ли багажник */
  isTrunkOpened: boolean;
  /** Доступные команды для автомобиля */
  allowActions: ECommandType[];
  /** Моточасы, ч */
  motoHours: number;
  /** Заблокирован ли двигатель */
  engineBlock: boolean;

  /** Обороты двигателя */
  engineRpm: number;
  /** Ручной тормоз  */
  handBrake: boolean;
  /** Нажат ножной тормоз */
  pedalBrake: boolean;
  /** Нажато сцепление */
  pedalClutch: boolean;
  /** Включен круиз-контроль */
  cruiseControl: boolean;
  /** Статус связи 0-4 */
  gsmStatus: number;
  /** Уровень связи 0-31 */
  gsmLevel: number;
  /** Ключ находится в замке зажигания  */
  keyInIgnition: boolean;
  /** Включение динамического зажигание */
  dynamicIgnition: boolean;
  /** Штатная сигнализация поставлена под охрану */
  alarm: boolean;
  /** Количество доступных спутников gps */
  satellitesCount: number;

  /** Показания климата */
  climate: LastPacketClimate | null;
  /** Статус первой КАН-шины */
  can1Status: boolean;
  /** Статус второй КАН-шины */
  can2Status: boolean;
}
