export interface StartFirmwareInterface {
  /** Фильт по imei и simId */
  deviceId: string;
  /** id устройства*/
  folder: string;
  /** Данные по прошивке */
  firmwareName: string;
}

export interface StartFirmwareResponse {
  status: 'success' | 'error';
  message?: string;
}
