export type CarGibddEntity = {
  readonly id: string;
  readonly externalId: string;
  groupId: number;
  sts: string;
  number: string;
  region: string;
  name: string;
  validNumber: boolean;
  checkPlaton: boolean;
  ownerInn: string;
  vin: string;
  checkAuto: boolean;
  checkPass: boolean;
  isOnMonitoring: boolean;
  readonly createdAt: Date;
  updatedAt: Date;
};
