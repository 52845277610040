import { PaymentCardOutboxEntities } from './card';
import { PaymentMerchantOutboxEntities } from './merchant';
import { PaymentOrderOutboxEntities } from './order';

export type PaymentOutboxEntities =
  | PaymentCardOutboxEntities
  | PaymentMerchantOutboxEntities
  | PaymentOrderOutboxEntities;

export type PaymentOutboxType = PaymentOutboxEntities['type'];

export * from './merchant';
export * from './order';
export * from './card';
