import { I18n } from '@store/backend-common';

import { ECommandType } from '../enums';

export interface SendCommandParams {
  initiator: string;
  byUser: boolean;
  commandType: ECommandType;
  timeout?: number;
}

export interface CommandResponseInterface {
  commandType: ECommandType;
  success: boolean;
  /** Локализация успеха */
  successLocalization: I18n | null;
  /** Локализация статуса */
  errorLocalization: I18n | null;
}

export type MisosBeepMode = 0 | 1 | 2;

export type MisosCanIndex = 0 | 1;
