export interface TripsInfo {
  statistics: Stats;
  /** Поездки */
  rows: TripInfo[];
  /** Пагинация */
  total: number;
  page: number;
  onPage: number;
}

export interface TripInfo {
  /** url карты с отображением поездки */
  mapImage: string | null;
  /** Время в ISO начала движения */
  timeStart: Date;
  /** Время в ISO остановки */
  timeStop: Date;
  /** Адрес откуда началось движение */
  addressStartLocation: string | null;
  /** Адрес где закончилось движение */
  addressStopLocation: string | null;
  /** Статистика */
  stats: Stats;
  /** Стиль вождения */
  drivingStyle: number;
  geojson: Track;
  towing: boolean;
  accident: boolean;
}

export interface Stats {
  /** Средняя скорость за выбранный период, км/ч */
  avgSpeed: number;
  /** Максимальная скорость за выбранный период, км/ч */
  maxSpeed: number;
  /** Потраченное топливо скорость за выбранный период, л */
  fuel: number;
  /** Пробег в городе за выбранный период, км */
  mileageCity: number;
  /** Пробег вне города за выбранный период, км */
  mileageTrack: number;
  /** Пробег за выбранный период, км */
  mileage: number;
  /** Время поездок за выбранный период, сек. */
  time: number;
}

export interface Track {
  type: 'FeatureCollection';
  features: (GeoJsonTrack | GeoJsonEvent)[];
}

export interface GeoJsonTrack {
  type: 'Feature';
  properties: {
    type: EFeatureType.Track;
  };
  geometry: {
    type: 'LineString';
    /** [[lon, lat],[lon, lat]] */
    coordinates: number[][];
  };
}

export interface GeoJsonEvent {
  type: 'Feature';
  properties: {
    type: EFeatureType.Event;
    data: {
      /** Тип события */
      type: EEventType[];
      /** Дата в ISO */
      date: Date;
    };
  };
  geometry: {
    /** GeoJson Type */
    type: 'Point';
    /** Координаты [lon, lat] */
    coordinates: number[];
  };
}

export enum EFeatureType {
  Track = 'Track',
  Event = 'Event',
}

export enum EEventType {
  Start = 'Start',
  SharpAcceleration = 'SharpAcceleration',
  SharpBraking = 'SharpBraking',
  SharpTurnLeft = 'SharpTurnLeft',
  SharpTurnRight = 'SharpTurnRight',
  OffRoad = 'OffRoad',
  Stop = 'Stop',
  Accident = 'Accident',
}

export enum EGeoJsonType {
  Trip = 'Trip',
  Event = 'Event',
}
