import { DeviceTestLastPacket } from './device-test-last-packet.interface';
import { DeviceTestTelematicChangesInterface } from './device-test-telematic-changes.interface';

export interface DeviceTestStatusInterface {
  lastPacket: DeviceTestLastPacket | null;
  telematicChanges: DeviceTestTelematicChangesInterface | null;
  serial: string | null;
  deviceId: string | null;
  vin: string;
  firmwareVersion: number | null;
  settingsSet: boolean;
  transmission: string;
}
