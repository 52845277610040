import { EntityName } from '@store/backend-entity';

import { Models, SubscriptionAction, UserRole } from '../../enums';

export const subscriptionPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof SubscriptionAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    {
      actions: [SubscriptionAction.read, SubscriptionAction.addGiftedSubscription, SubscriptionAction.update],
      subject: Models.Subscription,
    },
    { actions: [SubscriptionAction.read], subject: Models.Subscriptions },
  ],

  [UserRole.Operator]: [
    {
      actions: [SubscriptionAction.read, SubscriptionAction.addGiftedSubscription, SubscriptionAction.update],
      subject: Models.Subscription,
    },
    { actions: [SubscriptionAction.read], subject: Models.Subscriptions },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    {
      actions: [SubscriptionAction.read, SubscriptionAction.addGiftedSubscription, SubscriptionAction.update],
      subject: Models.Subscription,
    },
    { actions: [SubscriptionAction.read], subject: Models.Subscriptions },
  ],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [
    { actions: [SubscriptionAction.read], subject: Models.Subscription },
    { actions: [SubscriptionAction.read], subject: Models.Subscriptions },
  ],
};
