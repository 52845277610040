import { inject, NgModule, provideAppInitializer } from '@angular/core';

import { NavigationHistoryService } from './navigation-history.service';

@NgModule({
  providers: [
    NavigationHistoryService,
    provideAppInitializer(() => {
      const initializerFn = ((_) => () => {})(inject(NavigationHistoryService));
      return initializerFn();
    }),
  ],
})
export class NavigationModule {}
