import { ControlCenterCustomerAddCardOutboxEntity } from './control-center-customer-add-card-outbox.entity';
import { ControlCenterCustomerChangePhoneOutboxEntity } from './control-center-customer-change-phone-outbox.entity';
import { ControlCenterCustomerDeletePaymentCardOutboxEntity } from './control-center-customer-delete-payment-card-outbox.entity';
import { ControlCenterCustomerSetMainCardOutboxEntity } from './control-center-customer-set-main-card-outbox.entity';
import { ControlCenterCustomersBlockedOutboxEntity } from './control-center-customers-blocked-outbox.entity';
import { ControlCenterCustomersCreatedOutboxEntity } from './control-center-customers-created-outbox.entity';
import { ControlCenterCustomersDeletePersonalDataOutboxEntity } from './control-center-customers-delete-personal-data-outbox.entity';
import { ControlCenterCustomersUpdatedOutboxEntity } from './control-center-customers-updated-outbox.entity';
import { ControlCenterExternalCustomerDeviceCreatedOutboxEntity } from './control-center-external-customer-device-created-outbox.entity';
import { ControlCenterExternalCustomerDeviceUpdatedOutboxEntity } from './control-center-external-customer-device-updated-outbox.entity';
import { ControlCenterExternalCustomersAcceptProcDataOutboxEntity } from './control-center-external-customers-accept-proc-data-outbox.entity';
import { ControlCenterExternalCustomersCreatedOutboxEntity } from './control-center-external-customers-created-outbox.entity';
import { ControlCenterExternalCustomersUpdatedOutboxEntity } from './control-center-external-customers-updated-outbox.entity';

export type ControlCenterCustomerOutboxEntities =
  | ControlCenterCustomersCreatedOutboxEntity
  | ControlCenterCustomersUpdatedOutboxEntity
  | ControlCenterCustomersBlockedOutboxEntity
  | ControlCenterCustomerAddCardOutboxEntity
  | ControlCenterCustomerSetMainCardOutboxEntity
  | ControlCenterExternalCustomersCreatedOutboxEntity
  | ControlCenterExternalCustomersUpdatedOutboxEntity
  | ControlCenterExternalCustomersAcceptProcDataOutboxEntity
  | ControlCenterCustomersDeletePersonalDataOutboxEntity
  | ControlCenterCustomerDeletePaymentCardOutboxEntity
  | ControlCenterCustomerChangePhoneOutboxEntity
  | ControlCenterExternalCustomerDeviceCreatedOutboxEntity
  | ControlCenterExternalCustomerDeviceUpdatedOutboxEntity;

export * from './control-center-customer-outbox-event-type.enum';
export * from './control-center-customers-blocked-outbox.entity';
export * from './control-center-customers-created-outbox.entity';
export * from './control-center-customers-updated-outbox.entity';
export * from './control-center-customer-add-card-outbox.entity';
export * from './control-center-customer-set-main-card-outbox.entity';
export * from './control-center-external-customers-created-outbox.entity';
export * from './control-center-external-customers-updated-outbox.entity';
export * from './control-center-external-customers-accept-proc-data-outbox.entity';
export * from './control-center-customers-delete-personal-data-outbox.entity';
export * from './control-center-customer-delete-payment-card-outbox.entity';
export * from './control-center-customer-change-phone-outbox.entity';
export * from './control-center-external-customer-device-created-outbox.entity';
export * from './control-center-external-customer-device-updated-outbox.entity';
