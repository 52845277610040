import { DeviceEntity } from '../devices';

export interface VehicleColor {
  name: string;
  hexCode?: string;
}

export type VehicleAttributes = {
  issueYear?: number;
  tireSize?: string;
  transmission?: string;
  fuelCapacity?: number;
  fuelType?: string;
  color?: VehicleColor | null;
  render?: string[];
};

export type VehicleEntity = {
  /** VIN авто */
  vin: string;
  /** Альтернативный VIN авто */
  vin2: string | null;
  /** Идентификатор партнера */
  partnerId: string;
  /** Идентификатор записи каталога */
  generationId: string;
  /** Идентификатор цвета поколения */
  generationColorId: string | null;
  /** Авто заархивировано */
  archived: boolean;
  /** Марка */
  brand: string;
  /** Модель */
  model: string;
  /** Атрибуты авто */
  attributes: VehicleAttributes;
  /** Список идентификаторов блоков авто по приоритету */
  prioritizedDeviceIds: string[];
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
  render: string[];

  /** Блоки, установленные на авто */
  devices?: DeviceEntity[];
};

export type VehicleEntityRelationKey = keyof Pick<VehicleEntity, 'devices'>;
export type VehicleEntityRelations = VehicleEntityRelationKey[] | 'all';
