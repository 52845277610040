import { LocalesEnum } from '@store/backend-common';

import { CustomerDeviceEntity } from './customer-device.entity';
import { ExternalCustomerEntity } from './external-customer.entity';

export type ExternalCustomerDeviceEntity = {
  readonly id: string;
  readonly externalCustomerId: string;
  readonly customerDeviceId: string;
  appVersion: string;
  lastActivityDate: Date;
  firstLoginDate: Date;
  lastLoginDate: Date;
  lastLoginIp: string;
  logoutDate: Date | null;
  isActive: boolean;
  language: LocalesEnum | null;
  readonly createdAt: Date;
  updatedAt: Date;
  pushToken: string | null;
  externalCustomer?: ExternalCustomerEntity;
  customerDevice?: CustomerDeviceEntity;
};

export type ExternalCustomerDeviceEntityRelationKey = keyof Pick<
  ExternalCustomerDeviceEntity,
  'externalCustomer' | 'customerDevice'
>;
export type ExternalCustomerDeviceEntityRelations = ExternalCustomerDeviceEntityRelationKey[] | 'all';
