import { DefaultAction } from './default-action.enum';

export enum UniqueCustomerActions {
  logout = 'logout',
  sendPush = 'sendPush',
  sendBatchPushes = 'sendBatchPushes',
}

export const CustomerAction = {
  ...DefaultAction,
  ...UniqueCustomerActions,
};
