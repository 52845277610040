import { ModuleWithProviders, NgModule } from '@angular/core';

import { MediaShowDownDirective } from './directives/media-show-down.directive';
import { MediaShowUpDirective } from './directives/media-show-up.directive';
import { MEDIA_BREAKPOINTS_PROVIDER, MediaBreakpointsProvider } from './media-breakpoints.provider';

@NgModule({
  declarations: [MediaShowDownDirective, MediaShowUpDirective],
  exports: [MediaShowDownDirective, MediaShowUpDirective],
})
export class MediaObserverModule {
  public static forRoot(breakpoints: MediaBreakpointsProvider): ModuleWithProviders<MediaObserverModule> {
    return {
      ngModule: MediaObserverModule,
      providers: [
        {
          provide: MEDIA_BREAKPOINTS_PROVIDER,
          useValue: breakpoints,
        },
      ],
    };
  }
}
