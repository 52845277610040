export type CardRumkEntity = {
  readonly id: string;
  readonly ownerId: string;
  readonly vin: string;
  paidAt: Date;
  activationDate: Date;
  toDate: Date;
  facilityOrderId: string;
  isActive: boolean;
  readonly createdAt: Date;
  updatedAt: Date;
};
