import { FacilityOrderCkadsData } from './facility-order-ckads-data.interface';
import { FacilityOrderFinesData } from './facility-order-fines-data.interface';
import { FacilityOrderMaintenanceData } from './facility-order-maintenance-data.interface';
import { FacilityOrderRumkData } from './facility-order-rumk-data.interface';
import { FacilityOrderSubscriptionData } from './facility-order-subscription-data.interface';

export type FacilityOrderData =
  | FacilityOrderFinesData
  | FacilityOrderCkadsData
  | FacilityOrderMaintenanceData
  | FacilityOrderSubscriptionData
  | FacilityOrderRumkData;

export * from './facility-order-fines-data.interface';
export * from './facility-order-ckads-data.interface';
export * from './facility-order-maintenance-data.interface';
export * from './facility-order-subscription-data.interface';
export * from './facility-order-rumk-data.interface';
