import { TaxationSystem, TaxEnum } from '../payment';

/** Поставщик услуги */
export type ProviderEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Идентификатор мерчанта */
  merchantId: string | null;
  /** Название */
  name: string;
  /** Телефон */
  phone: string;
  /** ИНН */
  inn: string;
  /**  */
  tax: TaxEnum;
  /**  */
  taxationSystem: TaxationSystem;
  /** КПП */
  kpp: string | null;
  /** ФИО исполнительного директора */
  ceo: string | null;
  /** Номер счета */
  account: string | null;
  /** Номер корреспондентского счета */
  correspondentAccount: string | null;
  /** БИК */
  bic: string | null;
  /**  */
  bankName: string | null;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
};

export type ProviderEntityRelations = [] | 'all';
