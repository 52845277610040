/** Равно (с учётом регистра) */
export interface ConditionEqual<T = unknown> {
  $eq: T | string;
}

/** Неравно (с учётом регистра) */
export interface ConditionNot<T = unknown> {
  $not: T | string;
}

/** Содержит (с учётом регистра) */
export interface ConditionIn<T = unknown> {
  $in: T[];
}

/** Не содержит (с учётом регистра) */
export interface ConditionNotIn<T = unknown> {
  $notIn: T[];
}

/** Больше */
export interface ConditionGt<T = unknown> {
  $gt: T | string;
}

/** Больше или равно */
export interface ConditionGte<T = unknown> {
  $gte: T | string;
}

/** Меньше */
export interface ConditionLt<T = unknown> {
  $lt: T | string;
}

/** Меньше или равно */
export interface ConditionLte<T = unknown> {
  $lte: T | string;
}

/** Между [from, to] */
export interface ConditionBetween<T = unknown> {
  $from: T | string;
  $to: T | string;
}

export type ConditionField<T = unknown> =
  | ConditionEqual<T>
  | ConditionNot<T>
  | ConditionIn<T>
  | ConditionNotIn<T>
  | ConditionBetween<T>
  | ConditionGt<T>
  | ConditionGte<T>
  | ConditionLt<T>
  | ConditionLte<T>;
