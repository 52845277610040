import { I18n } from '@store/backend-common';

import { NotificationCode, NotificationRoute, NotificationType } from './notification.entity';

export type NotificationTemplateEntity = {
  readonly id: string;
  code: NotificationCode | string;
  title: I18n;
  message: I18n;
  description: string;
  partnerId: string | null;
  type: NotificationType;
  navigationRoute: NotificationRoute;
  readonly createdAt: Date;
  updatedAt: Date;
};
