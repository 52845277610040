import { ControlCenterAutostartSettingsCreatedOutboxEntity } from './control-center-autostart-settings-created-outbox.entity';
import { ControlCenterAutostartSettingsUpdatedOutboxEntity } from './control-center-autostart-settings-updated-outbox.entity';

export type ControlCenterAutostartSettingsOutboxEntities =
  | ControlCenterAutostartSettingsUpdatedOutboxEntity
  | ControlCenterAutostartSettingsCreatedOutboxEntity;

export * from './control-center-autostart-settings-created-outbox.entity';
export * from './control-center-autostart-settings-outbox-event-type.enum';
export * from './control-center-autostart-settings-updated-outbox.entity';
