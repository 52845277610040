import { DeviceEntity, EActivationEventType } from '../../../services';
import { BaseOutboxEntity } from '../../base-outbox.entity';
import { ControlCenterDeviceOutboxEventType } from './control-center-device-outbox-event-type.enum';

export type DeviceDeactivationFailedOutboxEntityData = {
  device: DeviceEntity;
  failedReason: EActivationEventType;
};

export type ControlCenterDeviceDeactivationFailedOutboxEntity = BaseOutboxEntity<
  ControlCenterDeviceOutboxEventType.DeviceDeactivationFailed,
  DeviceDeactivationFailedOutboxEntityData
>;
