import { DefaultAction } from './default-action.enum';

export enum UniqueUserActions {
  block = 'block',
  resetPassword = 'resetPassword',
  activate = 'activate',
}

export const UserAction = {
  ...DefaultAction,
  ...UniqueUserActions,
};
