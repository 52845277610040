export enum PaymentOutboxEventType {
  OrderCreated = 'PaymentOrderCreated',
  OrderUpdated = 'PaymentOrderUpdated',
  OrderAuthorized = 'PaymentOrderAuthorized',
  OrderCompleted = 'PaymentOrderCompleted',
  OrderReversed = 'PaymentOrderReversed',
  OrderExpired = 'PaymentOrderExpired',
  OrderCardEnroll = 'PaymentOrderCardEnroll',
  OrderFailed = 'PaymentOrderFailed',
}
