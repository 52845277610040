export enum ControlCenterVehicleOutboxEventType {
  VehicleCreated = 'ControlCenterVehicleCreated',
  VehicleUpdated = 'ControlCenterVehicleUpdated',
  VehicleDeleted = 'ControlCenterVehicleDeleted',
  VehicleDeviceActivated = 'ControlCenterVehicleDeviceActivated',
  VehicleDeviceDeactivated = 'ControlCenterVehicleDeviceDeactivated',
  VehicleVinChanged = 'ControlCenterVehicleVinChanged',
  VehicleNewPrioritizedDevice = 'ControlCenterVehicleNewPrioritizedDevice',
  VehicleArchived = 'ControlCenterVehicleArchived',
  VehicleDeviceInstalled = 'ControlCenterVehicleDeviceInstalled',
  VehicleDeviceUninstalled = 'ControlCenterVehicleDeviceUninstalled',
  VehicleDeviceReplaced = 'ControlCenterVehicleDeviceReplaced',
  VehicleDeviceEventProcessed = 'ControlCenterVehicleDeviceEventProcessed',
  VehiclePartnerUpdated = 'ControlCenterVehiclePartnerUpdated',
}
