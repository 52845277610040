import { I18n } from '@store/backend-common';

import { ECommandType } from '../enums';

export interface SendCommandParams {
  initiator: string;
  commandType: ECommandType;
}

export interface CommandResponseInterface {
  commandType: ECommandType;
  success: boolean;
  /** Локализация успеха */
  successLocalization: I18n | null;
  /** Локализация статуса */
  errorLocalization: I18n | null;
}
