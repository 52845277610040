import { DealersDealerOutboxEntities } from './dealer';
import { DealersMaintenanceOutboxEntities } from './maintenance';
import { DealersServiceBookOutboxEntities } from './service-book';

export type DealersOutboxEntities =
  | DealersDealerOutboxEntities
  | DealersMaintenanceOutboxEntities
  | DealersServiceBookOutboxEntities;

export type DealersOutboxType = DealersOutboxEntities['type'];

export * from './dealer';
export * from './maintenance';
export * from './service-book';
