import { ECommandType } from '../../../services';
import { BaseOutboxEntity } from '../../base-outbox.entity';
import { ControlCenterDeviceOutboxEventType } from './control-center-device-outbox-event-type.enum';

export interface CommandSendDto {
  commandType: ECommandType;
  initiator: string;
}

export type ControlCenterDeviceCommandSentOutboxEntity = BaseOutboxEntity<
  ControlCenterDeviceOutboxEventType.DeviceCommandSent,
  CommandSendDto
>;
