import { DealersDealerOutboxCreatedEntity } from './dealers-dealer-outbox-created.entity';
import { DealersDealerOutboxUpdatedEntity } from './dealers-dealer-outbox-updated.entity';
import { DealersFavoriteDealerOutboxCreatedEntity } from './dealers-favorite-dealer-outbox-created.entity';
import { DealersFavoriteDealerOutboxUpdatedEntity } from './dealers-favorite-dealer-outbox-updated.entity';

export type DealersDealerOutboxEntities =
  | DealersDealerOutboxCreatedEntity
  | DealersDealerOutboxUpdatedEntity
  | DealersFavoriteDealerOutboxUpdatedEntity
  | DealersFavoriteDealerOutboxCreatedEntity;

export * from './dealers-dealer-outbox-event-type.enum';
export * from './dealers-dealer-outbox-updated.entity';
export * from './dealers-dealer-outbox-created.entity';
export * from './dealers-favorite-dealer-outbox-created.entity';
export * from './dealers-favorite-dealer-outbox-updated.entity';
