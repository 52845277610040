import {
  CkadAction,
  CustomerAction,
  DeviceAction,
  FacilityAction,
  FineAction,
  OwnershipAction,
  PartnerAction,
  SubscriptionAction,
  TicketAction,
  UserAction,
  VehicleAction,
} from '../enums';

export const Actions = {
  ...FacilityAction,
  ...PartnerAction,
  ...UserAction,
  ...DeviceAction,
  ...VehicleAction,
  ...OwnershipAction,
  ...FineAction,
  ...CkadAction,
  ...TicketAction,
  ...SubscriptionAction,
  ...CustomerAction,
};
