export type DeviceQueuedUpgradeEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Id блока */
  readonly deviceId: string;
  /** Серийный номер */
  serial: string;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
};
