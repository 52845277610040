import { DefaultAction } from './default-action.enum';

export enum UniqueOwnershipActions {
  finish = 'finish',
  addDriver = 'addDriver',
  deleteDriver = 'deleteDriver',
  createByTicket = 'createByTicket',
  updatePartner = 'updatePartner',
}

export const OwnershipAction = {
  ...DefaultAction,
  ...UniqueOwnershipActions,
};
