import { CommonModule } from '@angular/common';
import { inject, NgModule, provideAppInitializer } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UpdaterComponent } from '@app/core/updater/updater.component';
import { UpdaterService } from '@app/core/updater/updater.service';
import { FlatButtonModule } from '@app/shared/components/flat-button/flat-button.module';

@NgModule({
  declarations: [UpdaterComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatSnackBarModule, FlatButtonModule],
  providers: [
    UpdaterService,
    provideAppInitializer(() => {
      const initializerFn = ((_) => () => {})(inject(UpdaterService));
      return initializerFn();
    }),
  ],
})
export class UpdaterModule {}
