import { DealersDealerOutboxEntities } from './dealer';
import { DealersDeviceRepairOutboxEntities } from './device-repair';
import { DealersMaintenanceOutboxEntities } from './maintenance';
import { DealersServiceBookOutboxEntities } from './service-book';

export type DealersOutboxEntities =
  | DealersDealerOutboxEntities
  | DealersMaintenanceOutboxEntities
  | DealersServiceBookOutboxEntities
  | DealersDeviceRepairOutboxEntities;

export type DealersOutboxType = DealersOutboxEntities['type'];

export * from './dealer';
export * from './maintenance';
export * from './service-book';
export * from './device-repair';
