import { FacilitiesFacilityOutboxEntities } from './facility';
import { FacilitiesFacilityOrderOutboxEntities } from './facility-order';
import { FacilitiesFacilityOrderPaymentOutboxEntities } from './facility-order-payment';
import { FacilitiesFacilityTypeOutboxEntities } from './facility-type';
import { FacilitiesProviderOutboxEntities } from './provider';

export type FacilitiesOutboxEntities =
  | FacilitiesFacilityOutboxEntities
  | FacilitiesFacilityOrderOutboxEntities
  | FacilitiesFacilityTypeOutboxEntities
  | FacilitiesProviderOutboxEntities
  | FacilitiesFacilityOrderPaymentOutboxEntities;

export type FacilitiesOutboxType = FacilitiesOutboxEntities['type'];

export * from './facility';
export * from './facility-order';
export * from './facility-type';
export * from './provider';
export * from './facility-order-payment';
