import { BrandOutboxEntities } from './brand';
import { CatalogRecordOutboxEntities } from './catalog-record';
import { ColorOutboxEntities } from './color';
import { EquipmentOutboxEntities } from './equipment';
import { GenerationOutboxEntities } from './generation';
import { GenerationColorOutboxEntities } from './generation-color';
import { GlossaryOutboxEntities } from './glossary';
import { ModelOutboxEntities } from './model';

import { ConfigurationOutboxEntities } from './configuration';

export type CatalogOutboxEntities =
  | BrandOutboxEntities
  | CatalogRecordOutboxEntities
  | ColorOutboxEntities
  | ConfigurationOutboxEntities
  | EquipmentOutboxEntities
  | GenerationOutboxEntities
  | ModelOutboxEntities
  | GenerationColorOutboxEntities
  | GlossaryOutboxEntities;

export type CatalogOutboxType = CatalogOutboxEntities['type'];

export * from './brand';
export * from './catalog-record';
export * from './color';
export * from './configuration';
export * from './equipment';
export * from './generation';
export * from './model';
export * from './generation-color';
export * from './glossary';
