import { Inject, Injectable } from '@angular/core';
import { RELOAD_CONTROL_PROVIDER, ReloadControlProvider } from '@app/core/reload-control/reload-control.provider';
import { BASE_DEBOUNCE_TIME } from '@app/shared/consts/base-debounce-time.const';
import { debounceTime, filter, merge, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReloadControlService {
  constructor(
    @Inject(RELOAD_CONTROL_PROVIDER)
    private readonly providers: ReloadControlProvider[],
  ) {}

  public shouldReload(): Observable<boolean> {
    return merge(...this.providers.map((p) => p.shouldReloadData())).pipe(
      debounceTime(BASE_DEBOUNCE_TIME as number),
      filter((value) => value),
    );
  }
}
