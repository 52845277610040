import { PickPartial } from '@store/backend-common';

export type DealerDataEntity = {
  readonly id: string;
  vin: string;
  sapCode: string;
  dealerName: string;
  retailDate: Date;
  retailManager: string | null;
  clientType: string | null;
  clientFullName: string | null;
  clientPhone: string | null;
  clientEmail: string | null;
  readonly createdAt: Date;
  updatedAt: Date;
};

type DealerDataKeyFieldsRequired = keyof Pick<DealerDataEntity, 'vin' | 'retailDate' | 'sapCode' | 'dealerName'>;

type DealerDataKeyFieldsOptional = keyof PickPartial<
  DealerDataEntity,
  'retailManager' | 'clientType' | 'clientFullName' | 'clientPhone' | 'clientEmail'
>;

export type DealerDataKeyFields = DealerDataKeyFieldsRequired & DealerDataKeyFieldsOptional;

export type DealerDataFields = {
  [K in DealerDataKeyFields]: number;
};
