import { I18n } from '@store/backend-common';

export type CityEntity = {
  readonly id: string;
  name: I18n;
  region: I18n;
  country: I18n;
  lat: number;
  lon: number;
  timezone: number;
  postalIndex: string;
  readonly createdAt: Date;
  updatedAt: Date;
};
