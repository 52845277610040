export enum EquipmentOption {
  Yes = 'yes',
  No = 'no',
  Optional = 'optional',
}

export interface OptionalEquipment {
  /** Рейтинг безопасности */
  safetyRating: string | null;
  /** Класс безопасности */
  safetyGrade: number | null;
  /** Алькантара */
  alcantara: EquipmentOption | null;
  /** Черная крыша */
  blackRoof: EquipmentOption | null;
  /** Комбинированный интерьер */
  comboInterior: EquipmentOption | null;
  /** Декоративное освещение салона */
  decorativeInteriorLighting: EquipmentOption | null;
  /** Пороги дверей */
  doorSillPanel: EquipmentOption | null;
  /** Электрорегулировка сиденья водителя */
  driverSeatElectric: EquipmentOption | null;
  /** Память настроек сиденья водителя */
  driverSeatMemory: EquipmentOption | null;
  /** Поддержка сиденья водителя */
  driverSeatSupport: EquipmentOption | null;
  /** Регулировка сиденья водителя по высоте */
  driverSeatUpdown: EquipmentOption | null;
  /** Эко-кожа */
  ecoLeather: EquipmentOption | null;
  /** Электрорегулировка заднего сиденья */
  electroRearSeat: EquipmentOption | null;
  /** Тканевые сиденья */
  fabricSeats: EquipmentOption | null;
  /** Складывающееся сиденье переднего пассажира */
  foldingFrontPassengerSeat: EquipmentOption | null;
  /** Складные столики для задних сидений */
  foldingTablesRear: EquipmentOption | null;
  /** Передний центральный подлокотник */
  frontCentreArmrest: EquipmentOption | null;
  /** Поддержка сиденья передних пассажиров */
  frontSeatSupport: EquipmentOption | null;
  /** Обогрев сидений передних пассажиров */
  frontSeatsHeat: EquipmentOption | null;
  /** Вентиляция сидений передних пассажиров */
  frontSeatsHeatVent: EquipmentOption | null;
  /** Люк */
  hatch: EquipmentOption | null;
  /** Кожа */
  leather: EquipmentOption | null;
  /** Кожаная ручка коробки передач */
  leatherGearStick: EquipmentOption | null;
  /** Массажные сиденья */
  massageSeats: EquipmentOption | null;
  /** Панорамная крыша */
  panoramaRoof: EquipmentOption | null;
  /** Электрорегулировка сиденья пассажира */
  passengerSeatElectric: EquipmentOption | null;
  /** Регулировка сиденья пассажира по высоте */
  passengerSeatUpdown: EquipmentOption | null;
  /** Вентиляция сидений задних пассажиров */
  rearSeatHeatVent: EquipmentOption | null;
  /** Память настроек заднего сиденья */
  rearSeatMemory: EquipmentOption | null;
  /** Обогрев сидений задних пассажиров */
  rearSeatsHeat: EquipmentOption | null;
  /** Роликовая шторка на заднее окно */
  rollerBlindForRearWindow: EquipmentOption | null;
  /** Роликовые шторки на задние боковые окна */
  rollerBlindsForRearSideWindows: EquipmentOption | null;
  /** Память настроек сиденья */
  seatMemory: EquipmentOption | null;
  /** Трансформация сиденья */
  seatTransformation: EquipmentOption | null;
  /** Спортивные педали */
  sportPedals: EquipmentOption | null;
  /** Спортивные сиденья */
  sportSeats: EquipmentOption | null;
  /** Подголовники третьего ряда сидений */
  thirdRearHeadrest: EquipmentOption | null;
  /** Сиденья третьего ряда */
  thirdRowSeats: EquipmentOption | null;
  /** Тонированные стекла */
  tintedGlass: EquipmentOption | null;
  /** Обогрев руля */
  wheelHeat: EquipmentOption | null;
  /** Кожаное рулевое колесо */
  wheelLeather: EquipmentOption | null;
  /** Камера 360 градусов */
  camera360: EquipmentOption | null;
  /** Регулируемые педали */
  adjPedals: EquipmentOption | null;
  /** Пепельница и зажигалка */
  ashtrayAndCigaretteLighter: EquipmentOption | null;
  /** Автоматический круиз-контроль */
  autoCruise: EquipmentOption | null;
  /** Автоматические зеркала */
  autoMirrors: EquipmentOption | null;
  /** Система автоматической парковки */
  autoPark: EquipmentOption | null;
  /** Климат-контроль 1 зонный */
  climateControl1: EquipmentOption | null;
  /** Климат-контроль 2 зонный */
  climateControl2: EquipmentOption | null;
  /** Компьютер */
  computer: EquipmentOption | null;
  /** Кондиционер */
  condition: EquipmentOption | null;
  /** Охлаждающий ящик */
  coolingBox: EquipmentOption | null;
  /** Круиз-контроль */
  cruiseControl: EquipmentOption | null;
  /** Система выбора режима езды */
  driveModeSys: EquipmentOption | null;
  /** Электрорегулировка руля */
  eAdjustmentWheel: EquipmentOption | null;
  /** Система открытия багажника без рук */
  easyTrunkOpening: EquipmentOption | null;
  /** Электрорегулировка зеркал */
  electroMirrors: EquipmentOption | null;
  /** Электропривод двери багажника */
  electroTrunk: EquipmentOption | null;
  /** Электростекла задних дверей */
  electroWindowBack: EquipmentOption | null;
  /** Электростекла передних дверей */
  electroWindowFront: EquipmentOption | null;
  /** Электронная панель приборов */
  electronicGagePanel: EquipmentOption | null;
  /** Передняя камера */
  frontCamera: EquipmentOption | null;
  /** Бесключевой доступ */
  keylessEntry: EquipmentOption | null;
  /** Многофункциональное рулевое колесо */
  multiWheel: EquipmentOption | null;
  /** Многозонный климат-контроль */
  multizoneClimateControl: EquipmentOption | null;
  /** Помощь при парковке спереди */
  parkAssistF: EquipmentOption | null;
  /** Помощь при парковке сзади */
  parkAssistR: EquipmentOption | null;
  /** Электронные двери с системой блокировки */
  powerLatchingDoors: EquipmentOption | null;
  /** Программируемый предпусковой-нагреватель */
  programmedBlockHeater: EquipmentOption | null;
  /** Проекционный дисплей */
  projectionDisplay: EquipmentOption | null;
  /** Задняя камера */
  rearCamera: EquipmentOption | null;
  /** Дистанционный запуск двигателя */
  remoteEngineStart: EquipmentOption | null;
  /** Усилитель руля */
  servo: EquipmentOption | null;
  /** Кнопка запуска */
  startButton: EquipmentOption | null;
  /** Система "Старт-Стоп" */
  startStopFunction: EquipmentOption | null;
  /** Рулевые лепестки переключения передач */
  steeringWheelGearShiftPaddles: EquipmentOption | null;
  /** Конфигурация колеса 1 */
  wheelConfiguration1: EquipmentOption | null;
  /** Конфигурация колеса 2 */
  wheelConfiguration2: EquipmentOption | null;
  /** Память руля */
  wheelMemory: EquipmentOption | null;
  /** Усилители рулевого управления */
  wheelPower: EquipmentOption | null;
  /** Адаптивный свет */
  adaptiveLight: EquipmentOption | null;
  /** Автоматическое управление освещением */
  automaticLightingControl: EquipmentOption | null;
  /** Дневные ходовые огни */
  drl: EquipmentOption | null;
  /** Система обогрева омывателей стекла */
  heatedWashSystem: EquipmentOption | null;
  /** Автоматический переключатель дальнего света */
  highBeamAssist: EquipmentOption | null;
  /** Лазерные фары */
  laserLights: EquipmentOption | null;
  /** Светодиодные фары */
  ledLights: EquipmentOption | null;
  /** Очиститель фар */
  lightCleaner: EquipmentOption | null;
  /** Датчик освещенности */
  lightSensor: EquipmentOption | null;
  /** Обогрев зеркал */
  mirrorsHeat: EquipmentOption | null;
  /** Противотуманные фары */
  ptf: EquipmentOption | null;
  /** Датчик дождя */
  rainSensor: EquipmentOption | null;
  /** Обогрев стеклоочистителя */
  windcleanerHeat: EquipmentOption | null;
  /** Обогрев лобового стекла */
  windscreenHeat: EquipmentOption | null;
  /** Ксеноновые фары */
  xenon: EquipmentOption | null;
  /** Антиблокировочная система */
  abs: EquipmentOption | null;
  /** Шторные подушки безопасности */
  airbagCurtain: EquipmentOption | null;
  /** Подушка безопасности водителя */
  airbagDriver: EquipmentOption | null;
  /** Подушка безопасности пассажира */
  airbagPassenger: EquipmentOption | null;
  /** Боковые подушки безопасности задних пассажиров */
  airbagRearSide: EquipmentOption | null;
  /** Боковые подушки безопасности */
  airbagSide: EquipmentOption | null;
  /** Антипробуксовочная система */
  asr: EquipmentOption | null;
  /** Система помощи при торможении */
  bas: EquipmentOption | null;
  /** Система помощи при слепой зоне */
  blindSpot: EquipmentOption | null;
  /** Система предотвращения столкновений */
  collisionPreventionAssist: EquipmentOption | null;
  /** Динамическая помощь при удержании полосы */
  dha: EquipmentOption | null;
  /** Система предупреждения о дремле водителя */
  drowsyDriverAlertSystem: EquipmentOption | null;
  /** Система электронной стабилизации */
  esp: EquipmentOption | null;
  /** Звуковая сигнализация */
  feedbackAlarm: EquipmentOption | null;
  /** ГЛОНАСС */
  glonass: EquipmentOption | null;
  /** Система помощи при удержании автомобиля на склоне */
  hcc: EquipmentOption | null;
  /** Isofix */
  isofix: EquipmentOption | null;
  /** Isofix спереди */
  isofixFront: EquipmentOption | null;
  /** Подушка безопасности для коленей */
  kneeAirbag: EquipmentOption | null;
  /** Ламинированное стекло безопасности */
  laminatedSafetyGlass: EquipmentOption | null;
  /** Система помощи в удержании полосы движения */
  laneKeepingAssist: EquipmentOption | null;
  /** Ночное видение */
  nightVision: EquipmentOption | null;
  /** Электронные детские замки задних дверей */
  powerChildLocksRearDoors: EquipmentOption | null;
  /** Распознавание дорожных знаков */
  trafficSignRecognition: EquipmentOption | null;
  /** Датчик давления в шинах */
  tyrePressure: EquipmentOption | null;
  /** Система устойчивости в среде движения */
  vsm: EquipmentOption | null;
  /** Сигнализация */
  alarm: EquipmentOption | null;
  /** Иммобилайзер */
  immo: EquipmentOption | null;
  /** Замок */
  lock: EquipmentOption | null;
  /** Датчик объема */
  volumeSensor: EquipmentOption | null;
  /** Розетка 12 В */
  socket12v: EquipmentOption | null;
  /** Розетка 220 В */
  socket220v: EquipmentOption | null;
  /** Android Auto */
  androidAuto: EquipmentOption | null;
  /** Apple CarPlay */
  appleCarplay: EquipmentOption | null;
  /** Подготовка аудиосистемы */
  audiopreparation: EquipmentOption | null;
  /** Аудиосистема с CD */
  audiosystemCd: EquipmentOption | null;
  /** Телевизионная аудиосистема */
  audiosystemTv: EquipmentOption | null;
  /** AUX */
  aux: EquipmentOption | null;
  /** Bluetooth */
  bluetooth: EquipmentOption | null;
  /** Развлекательная система для пассажиров задних сидений */
  entertainmentSystemForRearSeatPassengers: EquipmentOption | null;
  /** Супермузыка */
  musicSuper: EquipmentOption | null;
  /** Навигация */
  navigation: EquipmentOption | null;
  /** USB */
  usb: EquipmentOption | null;
  /** Голосовое распознавание */
  voiceRecognition: EquipmentOption | null;
  /** Беспроводное зарядное устройство */
  wirelessCharger: EquipmentOption | null;
  /** Яндекс.Авто */
  yaAuto: EquipmentOption | null;
  /** Активная подвеска */
  activSuspension: EquipmentOption | null;
  /** Пневматическая подвеска */
  airSuspension: EquipmentOption | null;
  /** Уменьшить запасное колесо */
  reduceSpareWheel: EquipmentOption | null;
  /** Запасное колесо */
  spareWheel: EquipmentOption | null;
  /** Спортивная подвеска */
  sportSuspension: EquipmentOption | null;
  /** 14-дюймовые колеса */
  wheels14Inch: EquipmentOption | null;
  /** 15-дюймовые колеса */
  wheels15Inch: EquipmentOption | null;
  /** 16-дюймовые колеса */
  wheels16Inch: EquipmentOption | null;
  /** 17-дюймовые колеса */
  wheels17Inch: EquipmentOption | null;
  /** 18-дюймовые колеса */
  wheels18Inch: EquipmentOption | null;
  /** 19-дюймовые колеса */
  wheels19Inch: EquipmentOption | null;
  /** 20-дюймовые колеса */
  wheels20Inch: EquipmentOption | null;
  /** 21-дюймовые колеса */
  wheels21Inch: EquipmentOption | null;
  /** 22-дюймовые колеса */
  wheels22Inch: EquipmentOption | null;
  /** Обвес */
  bodyKit: EquipmentOption | null;
  /** Подкрылки */
  bodyMouldings: EquipmentOption | null;
  /** Двухцветное окрашивание */
  duoBodyColor: EquipmentOption | null;
  /** Металлик */
  paintMetallic: EquipmentOption | null;
  /** Рейлинги на крыше */
  roofRails: EquipmentOption | null;
  /** Стальные штампованные диски */
  steelWheels: EquipmentOption | null;
}
