export enum CustomerActionEnum {
  DIGITAL_KEY = 'DIGITAL_KEY', // Переход в раздел Цифровой ключ
  MONITORING_WIDGET = 'MONITORING_WIDGET', // Нажатие на виджет Мониторинг
  STATISTICS_WIDGET = 'STATISTICS_WIDGET', // Нажатие на виджет Статистика
  DEALER_WIDGET = 'DEALER_WIDGET', // Нажатие на виджет Мой ДЦ
  MAINTENANCE_WIDGET = 'MAINTENANCE_WIDGET', // Нажатие на виджет Запись на сервис
  ROADSIDE_ASSISTANCE_WIDGET = 'ROADSIDE_ASSISTANCE_WIDGET', // Нажатие на виджет Помощь на дороге
  CAR_RATING_WIDGET = 'CAR_RATING_WIDGET', // Нажатие на виджет Оценка авто
  KASKO_WIDGET = 'KASKO_WIDGET', // Нажатие на виджет Каско
  OSAGO_WIDGET = 'OSAGO_WIDGET', // Нажатие на виджет Осаго
  TIRE_SERVICE_WIDGET = 'TIRE_SERVICE_WIDGET', // Нажатие на виджет Шиномонтаж
  AUTOSTART = 'AUTOSTART', // Нажатие кнопки Автозапуск в разделе Цифровой ключ
  BEEP = 'BEEP', // Нажатие кнопки Гудок в разделе Цифровой ключ
  OPENBAG = 'OPENBAG', // Нажатие кнопки Открытие багажника в разделе Цифровой ключ
  AVAR = 'AVAR', // Нажатие кнопки Аварийка в разделе Цифровой ключ
  OPEN_DOORS = 'OPEN_DOORS', // Нажатие кнопки Открытие авто в разделе Цифровой ключ
  CLOSE_DOORS = 'CLOSE_DOORS', // Нажатие кнопки Закрытие авто в разделе Цифровой ключ
  CLIMATE_CONTROL = 'CLIMATE_CONTROL', // Нажатие кнопки Климат-контроль в разделе Цифровой ключ
  SUPPORT_CHAT = 'SUPPORT_CHAT', // Обращение в чат тех поддержки
}
