import { inject, Injectable } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
class AuthGuard {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  public checkAuthenticated(): Observable<boolean> {
    return this.authService.authenticated$.pipe(
      map((authenticated) => {
        if (!authenticated) {
          this.router.navigate(['/login']);
          return false;
        }

        return authenticated;
      }),
    );
  }
}

export const canActivateAuthorized: CanActivateFn = () => inject(AuthGuard).checkAuthenticated();

export const canMatchAuthorized: CanMatchFn = () => inject(AuthGuard).checkAuthenticated();
