import { CounterData } from './interfaces';

/** Счётчик */
export type CounterEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Идентификатор пользователя */
  readonly customerId: string;
  /** Идентификатор партнера */
  readonly partnerId: string;
  /** Данные счётчиков */
  data: CounterData;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
};
