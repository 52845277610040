import { PaymentOrderAuthorizedOutboxEntity } from './payment-order-authorized-outbox.entity';
import { PaymentOrderCardEnrollOutboxEntity } from './payment-order-card-enroll-outbox.entity';
import { PaymentOrderCompletedOutboxEntity } from './payment-order-completed-outbox.entity';
import { PaymentOrderCreatedOutboxEntity } from './payment-order-created-outbox.entity';
import { PaymentOrderExpiredOutboxEntity } from './payment-order-expired-outbox.entity';
import { PaymentOrderFailedOutboxEntity } from './payment-order-failed-outbox.entity';
import { PaymentOrderReverseOutboxEntity } from './payment-order-reverse-outbox.entity';
import { PaymentOrderUpdatedOutboxEntity } from './payment-order-updated-outbox.entity';

export type PaymentOrderOutboxEntities =
  | PaymentOrderCreatedOutboxEntity
  | PaymentOrderUpdatedOutboxEntity
  | PaymentOrderCompletedOutboxEntity
  | PaymentOrderReverseOutboxEntity
  | PaymentOrderCardEnrollOutboxEntity
  | PaymentOrderExpiredOutboxEntity
  | PaymentOrderAuthorizedOutboxEntity
  | PaymentOrderFailedOutboxEntity;

export * from './payment-order-outbox-event-type.enum';
export * from './payment-order-created-outbox.entity';
export * from './payment-order-updated-outbox.entity';
export * from './payment-order-completed-outbox.entity';
export * from './payment-order-reverse-outbox.entity';
export * from './payment-order-card-enroll-outbox.entity';
export * from './payment-order-expired-outbox.entity';
export * from './payment-order-authorized-outbox.entity';
export * from './payment-order-failed-outbox.entity';
