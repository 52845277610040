import { EntityName } from '@store/backend-entity';

import { Models, UserRole, VehicleAction } from '../../enums';

export const vehiclePermissions: Record<
  UserRole,
  { actions: Array<keyof typeof VehicleAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    {
      actions: [
        VehicleAction.read,
        VehicleAction.create,
        VehicleAction.update,
        VehicleAction.delete,
        VehicleAction.activate,
        VehicleAction.deactivate,
        VehicleAction.installDeviceByTicket,
        VehicleAction.installDevice,
        VehicleAction.uninstallDevice,
        VehicleAction.changeDevicePriority,
        VehicleAction.updatePartner,
      ],
      subject: Models.Vehicle,
    },
    { actions: [VehicleAction.read], subject: Models.Vehicles },
    { actions: [VehicleAction.sendCommand], subject: Models.VehicleSendCommandResponse },
    { actions: [VehicleAction.getLastPacket], subject: Models.CommandLogs },
    { actions: [VehicleAction.getLastPacket], subject: Models.TripsInfo },
    { actions: [VehicleAction.getLastPacket], subject: Models.TripsDailyInfo },
    { actions: [VehicleAction.getLastPacket], subject: Models.LastPacket },
  ],

  [UserRole.Operator]: [
    {
      actions: [
        VehicleAction.read,
        VehicleAction.create,
        VehicleAction.update,
        VehicleAction.delete,
        VehicleAction.activate,
        VehicleAction.deactivate,
        VehicleAction.installDeviceByTicket,
        VehicleAction.installDevice,
        VehicleAction.uninstallDevice,
        VehicleAction.changeDevicePriority,
      ],
      subject: Models.Vehicle,
    },
    { actions: [VehicleAction.read], subject: Models.Vehicles },
    { actions: [VehicleAction.sendCommand], subject: Models.VehicleSendCommandResponse },
    { actions: [VehicleAction.getLastPacket], subject: Models.CommandLogs },
    { actions: [VehicleAction.getLastPacket], subject: Models.TripsInfo },
    { actions: [VehicleAction.getLastPacket], subject: Models.TripsDailyInfo },
    { actions: [VehicleAction.getLastPacket], subject: Models.LastPacket },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    {
      actions: [
        VehicleAction.read,
        VehicleAction.create,
        VehicleAction.update,
        VehicleAction.delete,
        VehicleAction.activate,
        VehicleAction.deactivate,
        VehicleAction.installDeviceByTicket,
        VehicleAction.installDevice,
        VehicleAction.uninstallDevice,
        VehicleAction.changeDevicePriority,
      ],
      subject: Models.Vehicle,
    },
    { actions: [VehicleAction.read], subject: Models.Vehicles },
    { actions: [VehicleAction.sendCommand], subject: Models.VehicleSendCommandResponse },
    { actions: [VehicleAction.getLastPacket], subject: Models.CommandLogs },
    { actions: [VehicleAction.getLastPacket], subject: Models.TripsInfo },
    { actions: [VehicleAction.getLastPacket], subject: Models.TripsDailyInfo },
    { actions: [VehicleAction.getLastPacket], subject: Models.LastPacket },
  ],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [
    { actions: [VehicleAction.read], subject: Models.Vehicle },
    { actions: [VehicleAction.read], subject: Models.Vehicles },
    { actions: [VehicleAction.getLastPacket], subject: Models.CommandLogs },
    { actions: [VehicleAction.getLastPacket], subject: Models.TripsInfo },
    { actions: [VehicleAction.getLastPacket], subject: Models.TripsDailyInfo },
    { actions: [VehicleAction.getLastPacket], subject: Models.LastPacket },
  ],
};
