import { EntityName } from '@store/backend-entity';

import { DeviceAction, Models, UserRole } from '../../enums';

export const devicePermissions: Record<
  UserRole,
  { actions: Array<keyof typeof DeviceAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    {
      actions: [
        DeviceAction.read,
        DeviceAction.create,
        DeviceAction.update,
        DeviceAction.upgrade,
        DeviceAction.delete,
        DeviceAction.installSim,
        DeviceAction.updateSim,
        DeviceAction.activate,
        DeviceAction.deactivate,
      ],
      subject: Models.Device,
    },
    { actions: [DeviceAction.read], subject: Models.Devices },
    { actions: [DeviceAction.read, DeviceAction.delete], subject: Models.DeviceInfo },
    { actions: [DeviceAction.upload, DeviceAction.start], subject: Models.Firmware },
    { actions: [DeviceAction.sendCommand], subject: Models.DeviceSendCommandResponse },
    { actions: [DeviceAction.getCommandLog], subject: Models.CommandLogs },
    { actions: [DeviceAction.getDeviceLog], subject: Models.DeviceLog },

    { actions: [DeviceAction.getLastPacket], subject: Models.LastPacket },
    { actions: [DeviceAction.getStatistics], subject: Models.StatisticsInfo },
    { actions: [DeviceAction.getTrips], subject: Models.TripsInfo },
    { actions: [DeviceAction.getTripsDaily], subject: Models.TripsDailyInfo },
  ],

  [UserRole.Operator]: [
    {
      actions: [
        DeviceAction.read,
        DeviceAction.create,
        DeviceAction.update,
        DeviceAction.upgrade,
        DeviceAction.delete,
        DeviceAction.installSim,
        DeviceAction.updateSim,
        DeviceAction.activate,
        DeviceAction.deactivate,
      ],
      subject: Models.Device,
    },
    { actions: [DeviceAction.read], subject: Models.Devices },
    { actions: [DeviceAction.read, DeviceAction.delete], subject: Models.DeviceInfo },
    { actions: [DeviceAction.upload, DeviceAction.start], subject: Models.Firmware },
    { actions: [DeviceAction.sendCommand], subject: Models.DeviceSendCommandResponse },
    { actions: [DeviceAction.getCommandLog], subject: Models.CommandLogs },
    { actions: [DeviceAction.getDeviceLog], subject: Models.DeviceLog },
    { actions: [DeviceAction.getLastPacket], subject: Models.LastPacket },
    { actions: [DeviceAction.getStatistics], subject: Models.StatisticsInfo },
    { actions: [DeviceAction.getTrips], subject: Models.TripsInfo },
    { actions: [DeviceAction.getTripsDaily], subject: Models.TripsDailyInfo },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    {
      actions: [
        DeviceAction.read,
        DeviceAction.create,
        DeviceAction.update,
        DeviceAction.upgrade,
        DeviceAction.delete,
        DeviceAction.installSim,
        DeviceAction.updateSim,
        DeviceAction.activate,
        DeviceAction.deactivate,
      ],
      subject: Models.Device,
    },
    { actions: [DeviceAction.read], subject: Models.Devices },
    { actions: [DeviceAction.read, DeviceAction.delete], subject: Models.DeviceInfo },
    { actions: [DeviceAction.upload, DeviceAction.start], subject: Models.Firmware },
    { actions: [DeviceAction.sendCommand], subject: Models.DeviceSendCommandResponse },
    { actions: [DeviceAction.getCommandLog], subject: Models.CommandLogs },
    { actions: [DeviceAction.getDeviceLog], subject: Models.DeviceLog },
    { actions: [DeviceAction.getLastPacket], subject: Models.LastPacket },
    { actions: [DeviceAction.getStatistics], subject: Models.StatisticsInfo },
    { actions: [DeviceAction.getTrips], subject: Models.TripsInfo },
    { actions: [DeviceAction.getTripsDaily], subject: Models.TripsDailyInfo },
  ],

  [UserRole.DeviceOperator]: [
    { actions: [DeviceAction.read, DeviceAction.delete], subject: Models.DeviceInfo },
    { actions: [DeviceAction.getLastPacket], subject: Models.LastPacket },
    { actions: [DeviceAction.upload, DeviceAction.start], subject: Models.Firmware },
    { actions: [DeviceAction.getCommandLog], subject: Models.CommandLogs },
    { actions: [DeviceAction.getDeviceLog], subject: Models.DeviceLog },
  ],

  [UserRole.OperatorReadAccess]: [
    { actions: [DeviceAction.read], subject: Models.Device },
    { actions: [DeviceAction.read], subject: Models.Devices },
    { actions: [DeviceAction.getLastPacket], subject: Models.LastPacket },
    { actions: [DeviceAction.getStatistics], subject: Models.StatisticsInfo },
    { actions: [DeviceAction.getTrips], subject: Models.TripsInfo },
    { actions: [DeviceAction.getTripsDaily], subject: Models.TripsDailyInfo },
    { actions: [DeviceAction.getCommandLog], subject: Models.CommandLogs },
    { actions: [DeviceAction.getDeviceLog], subject: Models.DeviceLog },
  ],
};
