import { ControlCenterPartnerActivatedOutboxEntity } from './control-center-partner-activated-outbox.entity';
import { ControlCenterPartnerCreatedOutboxEntity } from './control-center-partner-created-outbox.entity';
import { ControlCenterPartnerDeactivatedOutboxEntity } from './control-center-partner-deactivated-outbox.entity';
import { ControlCenterPartnerDeletedOutboxEntity } from './control-center-partner-deleted-outbox.entity';
import { ControlCenterPartnerUpdatedOutboxEntity } from './control-center-partner-updated-outbox.entity';

export type ControlCenterPartnerOutboxEntities =
  | ControlCenterPartnerCreatedOutboxEntity
  | ControlCenterPartnerUpdatedOutboxEntity
  | ControlCenterPartnerDeletedOutboxEntity
  | ControlCenterPartnerActivatedOutboxEntity
  | ControlCenterPartnerDeactivatedOutboxEntity;

export * from './control-center-partner-outbox-event-type.enum';
export * from './control-center-partner-created-outbox.entity';
export * from './control-center-partner-updated-outbox.entity';
export * from './control-center-partner-deleted-outbox.entity';
export * from './control-center-partner-activated-outbox.entity';
export * from './control-center-partner-deactivated-outbox.entity';
