import { CustomerEntity } from '../../../services';
import { BaseOutboxEntity } from '../../base-outbox.entity';
import { ControlCenterCustomerOutboxEventType } from './control-center-customer-outbox-event-type.enum';

export interface ExternalCustomerDto {
  externalId?: string;
  partnerId: string;
}

export type ControlCenterCustomersCreatedOutboxEntity = BaseOutboxEntity<
  ControlCenterCustomerOutboxEventType.CustomerCreated,
  CustomerEntity & ExternalCustomerDto
>;
