import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivateAuthorized, canMatchAuthorized } from '@app/core/auth/guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('@app/pages-core/login-page/login-page.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('@app/pages-core/registration-page/registration-page.module').then((m) => m.RegistrationPageModule),
  },
  {
    path: 'reset',
    loadChildren: () =>
      import('@app/pages-core/reset-password-page/reset-password-page.module').then((m) => m.ResetPasswordPageModule),
  },
  {
    path: 'support-chat',
    loadChildren: () => import('./pages-core/support-chat/support-chat.module').then((m) => m.SupportChatModule),
    data: { title: 'Чат' },
  },
  {
    path: '',
    loadChildren: () =>
      import('@app/pages-core/main-container-page/main-container-page.module').then((m) => m.MainContainerPageModule),
    canActivate: [canActivateAuthorized],
    canMatch: [canMatchAuthorized],
    data: {
      notRoles: ['technic'],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
