import { I18n } from '@store/backend-common';

import { StoryPageType } from './enums';
import { StoryViewEntity } from './story-view.entity';

export enum StoryPageTheme {
  Dark = 'dark',
  Light = 'light',
}

export type StoryPageCommonPayload = Record<string, never>;

export type StoryPageCommonWithButtonPayload = {
  /** Текст кнопки */
  buttonMessage: I18n;
  /** Url кнопки */
  buttonUrl: string | null;
  /** Url кнопки */
  buttonPath: string | null;
  /** Цвет кнопки */
  buttonColor: StoryPageTheme | null;
};

export type StoryPageQuizPayload = {
  /** Варианты квиза */
  variants: string[];
  /** Цвет вариантов квиза */
  quizVariantsColor: string | null;
};

export type StoryPageVotePayload = {
  /** Цвет вариантов ответа */
  voteVariantsColor: string | null;
};

export type StoryPageCommon = {
  type: StoryPageType.Common;
  payload: StoryPageCommonPayload;
};

export type StoryPageCommonWithButton = {
  type: StoryPageType.CommonWithButton;
  payload: StoryPageCommonWithButtonPayload;
};

export type StoryPageQuiz = {
  type: StoryPageType.Quiz;
  payload: StoryPageQuizPayload;
};

export type StoryPageVote = {
  type: StoryPageType.Vote;
  payload: StoryPageVotePayload;
};

export type StoryPagePayload =
  | StoryPageCommonPayload
  | StoryPageCommonWithButtonPayload
  | StoryPageQuizPayload
  | StoryPageVotePayload;
export type StoryPage = StoryPageCommon | StoryPageCommonWithButton | StoryPageQuiz | StoryPageVote;

export type StoryPageEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Идентификатор истории*/
  readonly storyId: string;
  /** Заголовок */
  title: I18n;
  /** Текстовый блок */
  message: I18n;
  /** Цвет заголовка hex */
  titleColor: string | null;
  /** Цвет текста hex */
  messageColor: string | null;
  /** Цвет фона hex */
  backgroundColor: string | null;
  /** Путь фонового изображения */
  backgroundImagePath: string | null;
  /** Позиция истории в списке */
  position: number;
  /** Тема страницы */
  theme: StoryPageTheme | null;
  /** Данные по просмотрам */
  views?: StoryViewEntity[];
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
} & StoryPage;

export type StoryPageEntityRelations = [] | 'all';
