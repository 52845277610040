import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { APP_VERSION } from '@app/core/tokens';
import { Observable } from 'rxjs';

import { AuthDeviceService } from './auth-device.service';

@Injectable({ providedIn: 'root' })
export class AuthDeviceInterceptor implements HttpInterceptor {
  constructor(
    private readonly authDeviceService: AuthDeviceService,
    @Optional() @Inject(APP_VERSION) private readonly appVersion: string | null,
  ) {}

  public intercept(req: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = req.headers
      .append('x-device-id', this.authDeviceService.getOrGenerate())
      .append('x-app-version', this.appVersion || 'unknown');

    return next.handle(req.clone({ headers }));
  }
}
