import { CatalogOutboxEntities, CatalogOutboxType } from './catalog';
import { ContentsOutboxEntities, ContentsOutboxType } from './contents';
import { ControlCenterOutboxEntities, ControlCenterOutboxType } from './control-center';
import { FacilitiesOutboxType } from './facilities';
import { PaymentOutboxEntities, PaymentOutboxType } from './payment';
import { RumkCardOutboxEntities, RumkCardOutboxEventType } from './rumk';
import { UsersOutboxEntities, UsersOutboxType } from './users';

export * from './base-outbox.entity';
export * from './users';
export * from './payment';
export * from './control-center';
export * from './facilities';
export * from './fines';
export * from './tickets';
export * from './contents';
export * from './dealers';
export * from './catalog';
export * from './rumk';

export type OutboxEntities =
  | ControlCenterOutboxEntities
  | PaymentOutboxEntities
  | UsersOutboxEntities
  | ContentsOutboxEntities
  | CatalogOutboxEntities
  | RumkCardOutboxEntities;

export type OutboxType =
  | ControlCenterOutboxType
  | PaymentOutboxType
  | UsersOutboxType
  | ContentsOutboxType
  | FacilitiesOutboxType
  | CatalogOutboxType
  | RumkCardOutboxEventType;
