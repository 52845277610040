import { EntityName } from '@store/backend-entity';

import { FineAction, Models, UserRole } from '../../enums';

export const finePermissions: Record<
  UserRole,
  { actions: Array<keyof typeof FineAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    { actions: [FineAction.synchronizeFinePhotos, FineAction.synchronizeCarFines], subject: Models.Fine },
    { actions: [FineAction.read], subject: Models.Fines },
  ],

  [UserRole.Operator]: [
    { actions: [FineAction.synchronizeFinePhotos, FineAction.synchronizeCarFines], subject: Models.Fine },
    { actions: [FineAction.read], subject: Models.Fines },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    { actions: [FineAction.synchronizeFinePhotos, FineAction.synchronizeCarFines], subject: Models.Fine },
    { actions: [FineAction.read], subject: Models.Fines },
  ],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [{ actions: [FineAction.read], subject: Models.Fines }],
};
