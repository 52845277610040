export interface DeviceInfoInterface {
  activated: boolean;
  activationCount: number;
  checkDevicePackage: number | null;
  dateCreate: number;
  dateInsert: number;
  deviceId: string | null;
  deviceType: number;
  firmwareVersion: string | null;
  imei: string;
  modelId: number | null;
  operation: 'update' | 'insert';
  serial: number;
  series: string;
  sim1: string;
  sim2: string;
  status: number;
  statusString: string | null;
}

export interface DeviceInfoResponse {
  rows: DeviceInfoInterface[];
  total: number;
}
