import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { inject, NgModule, provideAppInitializer } from '@angular/core';
import { AuthDeviceInterceptor } from '@app/core/auth/services/auth-device.interceptor';
import { AuthDeviceService } from '@app/core/auth/services/auth-device.service';
import { AuthReloadControlService } from '@app/core/auth/services/auth-reload-control.service';
import { RELOAD_CONTROL_PROVIDER } from '@app/core/reload-control/reload-control.provider';
import { NgxsModule } from '@ngxs/store';

import { AuthService } from './services/auth.service';
import { AUTH_INITIALIZER_FACTORY } from './services/auth-initializer.factory';
import { TokenInterceptor } from './services/token.interceptor';
import { AuthState } from './states/auth.state';
import { AuthProfileState } from './states/auth-profile.state';
import { AuthTokenState } from './states/auth-token.state';

@NgModule({
  imports: [NgxsModule.forFeature([AuthState, AuthTokenState, AuthProfileState])],
  providers: [
    AuthService,
    AuthDeviceService,
    provideAppInitializer(() => {
      const initializerFn = AUTH_INITIALIZER_FACTORY(inject(AuthService));
      return initializerFn();
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthDeviceInterceptor,
      multi: true,
    },
    {
      provide: RELOAD_CONTROL_PROVIDER,
      useClass: AuthReloadControlService,
      multi: true,
    },
  ],
})
export class AuthModule {}
