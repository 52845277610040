import { EFacilityType, FacilityOrderEntity } from '../../../services';
import { BaseOutboxEntity } from '../../base-outbox.entity';
import { FacilitiesFacilityOrderOutboxEventType } from './facilities-facility-order-outbox-event-type.enum';

export type FacilitiesFacilityOrderClosedOutboxEntity = BaseOutboxEntity<
  FacilitiesFacilityOrderOutboxEventType.FacilityOrderClosed,
  {
    order: FacilityOrderEntity;
    type: EFacilityType;
  }
>;
