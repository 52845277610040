export interface DeviceRepairDeliveryTrackingEntity {
  /** Трек номер доставки */
  trackNumber: string;
  /** Название статуса */
  name: string;
  /** Комментарий к статусу */
  comment?: string | null;
  /** GUID статуса во внешней системе */
  externalGuid: string;
  /** Дата события */
  date: Date;
  /** Дата создания */
  createdAt: Date;
}
