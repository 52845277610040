import { I18n } from '@store/backend-common';

export interface SetAutostartDurationResponseInterface {
  /** Была ли команда выполнена успешно */
  success: boolean;
  /** Локализация ошибки */
  errorLocalization: I18n | null;
  /** Локализация Успеха */
  successLocalization: I18n | null;
  /** Длительность автозапуска, от 5 до 45 минут, в минутах, с точностью до десятых, пример 25.5 = 25 с половиной минут */
  duration?: number;
}
