import { RumkOrderCreatedOutboxEntity } from './rumk-order-created-outbox.entity';
import { RumkOrderPaidOutboxEntity } from './rumk-order-paid-outbox.entity';
import { RumkOrderRatedOutboxEntity } from './rumk-order-rated-outbox.entity';
import { RumkOrderUpdatedOutboxEntity } from './rumk-order-updated-outbox.entity';

export type RumkOrderOutboxEntities =
  | RumkOrderCreatedOutboxEntity
  | RumkOrderUpdatedOutboxEntity
  | RumkOrderPaidOutboxEntity
  | RumkOrderRatedOutboxEntity;

export * from './rumk-order-updated-outbox.entity';
export * from './rumk-order-created-outbox.entity';
export * from './rumk-order-rated-outbox.entity';
export * from './rumk-order-paid-outbox.entity';
export * from './rumk-order-outbox-event-type.enum';
