import { DefaultAction } from './default-action.enum';

export enum UniqueVehicleActions {
  getLastPacket = 'getLastPacket',
  getStatistics = 'getStatistics',
  getTrips = 'getTrips',
  getTripsDaily = 'getTripsDaily',
  sendCommand = 'sendCommand',
  activate = 'activate',
  deactivate = 'deactivate',
  getCommandLogs = 'getCommandLogs',
  getBrands = 'getBrands',
  installDeviceByTicket = 'installDeviceByTicket',
  installDevice = 'installDevice',
  uninstallDevice = 'uninstallDevice',
  changeDevicePriority = 'changeDevicePriority',
  updatePartner = 'updatePartner',
}

export const VehicleAction = {
  ...DefaultAction,
  ...UniqueVehicleActions,
};
