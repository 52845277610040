import { DeviceEntity, EActivationEventType } from '../../../services';
import { BaseOutboxEntity } from '../../base-outbox.entity';
import { ControlCenterDeviceOutboxEventType } from './control-center-device-outbox-event-type.enum';

export type DeviceActivationFailedOutboxEntityData = {
  device: DeviceEntity;
  failedReason: EActivationEventType;
};

export type ControlCenterDeviceActivationFailedOutboxEntity = BaseOutboxEntity<
  ControlCenterDeviceOutboxEventType.DeviceActivationFailed,
  DeviceActivationFailedOutboxEntityData
>;
