export type DealerVehicleCountEntity = {
  readonly id: string;
  sapCode: string;
  dealerName: string;
  vehicleCount: number;
  countDate: Date;
  readonly createdAt: Date;
  updatedAt: Date;
};

export type DealerVehicleCountKeyFields = keyof Omit<DealerVehicleCountEntity, 'id' | 'createdAt' | 'updatedAt'>;

export type DealerVehicleCountFields = {
  [K in DealerVehicleCountKeyFields]: number;
};
