import { BaseOutboxEntity } from '../../base-outbox.entity';
import { ControlCenterDeviceOutboxEventType } from './control-center-device-outbox-event-type.enum';

export interface CustomCommandSendDto {
  commandText: string;
  initiator: string;
}

export type ControlCenterDeviceCustomCommandSentOutboxEntity = BaseOutboxEntity<
  ControlCenterDeviceOutboxEventType.DeviceCustomCommandSent,
  CustomCommandSendDto
>;
