import { I18n } from '@store/backend-common';

import { NavigationRoute } from '../navigation-route.enum';
import { NotificationCode, NotificationType } from './notification.entity';

export type NotificationTemplateEntity = {
  readonly id: string;
  code: NotificationCode | string;
  title: I18n;
  message: I18n;
  description: string;
  partnerId: string | null;
  type: NotificationType;
  navigationRoute: NavigationRoute;
  restrictedCustomerIds: string[] | null;
  readonly createdAt: Date;
  updatedAt: Date;
};
