import { ControlCenterCityCreatedOutboxEntity } from './control-center-city-created-outbox.entity';
import { ControlCenterCityUpdatedOutboxEntity } from './control-center-city-updated-outbox.entity';

export type ControlCenterCityOutboxEntities =
  | ControlCenterCityCreatedOutboxEntity
  | ControlCenterCityUpdatedOutboxEntity;

export * from './control-center-city-outbox-event-type.enum';
export * from './control-center-city-created-outbox.entity';
export * from './control-center-city-updated-outbox.entity';
